<template>
	<CommonTable :headers="headers" :items="items" :total="total" :sortBy="sortBy" v-model:expanded="expanded" :limit="limit" :offset="offset"
		show-expand @update:sortBy="sortBy = $event" @update:limit="$emit('update:limit', $event)" @update:offset="$emit('update:offset', $event)"
		:noDataText="$t('text.noPackagesAvailable')">
		<template #[`item.packageType.de`]="{ item }">
			<v-tooltip location="bottom">
				<template #activator="{ props }">
					<v-icon color="#787777" v-bind="props">mdi-layers</v-icon>
				</template>
				<span class="tooltip">{{ item.packageType.de.charAt(0).toUpperCase() + item.packageType.de.slice(1) }}</span>
			</v-tooltip>
		</template>

		<template #[`item.name.de`]="{ item }">
			<p>{{ item.serviceProviderName.de ?? '' }}</p>
			<div>{{ item.name.de ?? '' }}</div>
		</template>

		<!-- TODO: no categories ATM -->

		<!-- <template #[`item.mainCategory`]="{ item }">
			<div class="main-category">
				<div v-for="cat of item.mainCategory.de" :key="cat" class="category-chip">
					<p>{{ cat }}</p>
				</div>
			</div>
		</template> -->
		<!-- TODO: STATIC data, replace with real categories when ready -->
		<template #[`item.mainCategory`]>
			<div>
				<div class="main-category">
					<div class="category-chip" v-for="cat of ['STATIC-DATA', 'Category 1', 'Category 2']" :key="cat">
						<p>{{ cat }}</p>
					</div>
				</div>
			</div>
		</template>

		<template #[`item.category`]="{ item }">
			<!-- <div style="display: inline-block;" v-for="category of item.category.de" :key="category">
				<span v-if="category" class="clientAssignmentChip" :class="{ [mapPeakStatus(item.package_travel_active)]: true, }"
					:title="category ?? ''">
					{{ category }}
				</span>
			</div> -->
			<!-- TODO: STATIC data, replace with real categories when ready -->
			<div style="display: inline-block;" v-for="category of ['STATIC-DATA', 'Category 1', 'Category 2']" :key="category">
				<span v-if="category" class="clientAssignmentChip" :class="{ [mapPeakStatus(item.active.de)]: true, }"
					:title="category ?? ''">
					{{ category }}
				</span>
			</div>
		</template>

		<!-- TODO: no marketplace for now. Will this come from clientAssignment[0]? -->
		
		<!-- <template #[`item.marketplace`]="{ item }">
			<span>{{ item.marketplace.de.slice(0, 2).join(', ') }}</span>
			<span v-if="item.marketplace.de?.length > 2">, +{{ item.marketplace.de?.length
				- 2 }}</span>
		</template> -->
		<!-- TODO: static data: replace with real marketplaces -->
		<template #[`item.marketplace`]="{ item }">
			<span>
				{{ item.websites.de.map(website => website.name).slice(0, 2).join(', ') }}
				<span v-if="item.websites.de.length > 2">, +{{ item.websites.de.length - 2 }}</span>
			</span>
		</template>
		

		<template #[`item.active.de`]="{ item }">
			<ApprovalStatus :status="mapPeakStatus(item.active.de)" />
		</template>

		<!-- TODO: this status is actually regarding translations and we will not do that for phase 1 -->
		<!-- <template #[`item.status`]="{ item }">
			<Status :status="item.status" />
		</template> -->

		<template #[`item.actions`]="{ item }">
			<div class="payment-plan-actions">
				<v-btn class="my-2" variant="outlined" icon="mdi-pencil" rounded="lg"
					@click="$router.push('/packages/' + item.id.replace('PT_', ''))"
				/>
			</div>
		</template>
		<!-- Expanded Row -->
		<template #expanded-row="{ item }">
			<!-- General -->
			<p class="title">{{ $t('text.packageDesignerGeneral') }}:</p>
			<div class="expanded-row">
				<div class="general-row">
					<!-- Title -->
					<div class="row-item">
						<p>{{ $t('text.title') }}:</p>
						<p>{{ item.name.de }}</p>
					</div>
					<!-- Service Provider -->
					<div class="row-item">
						<p>{{ $t('text.serviceProvider') }}:</p>
						<p>{{ item.serviceProviderName.de }}</p>
					</div>
					<!-- TODO: no type ATM -->
					<!-- Type -->
					<div class="row-item">
						<p>{{ $t('text.type') }}:</p>
						<p>{{ item.packageType.de }}</p>
					</div>
				</div>

				<div class="general-row-second">
					<!-- TODO: no source in the response ATM -->
					<!-- Created/Imported -->
					<div class="row-item">
						<p>{{ $t('text.createdIn') }}/{{ $t('text.importedFrom') }}:</p>
						<p>STATIC-DATA MyServices</p>
						<!-- <p>{{ item.createdIn }}</p> -->
					</div>
					<!-- Created -->
					<div class="row-item">
						<p>{{ $t('text.created') }}:</p>
						<p>{{ formatDate(item.createdAt.de) }} {{ $t('text.by') }} {{ item.createdBy.de }}</p>
					</div>
					<!-- Last Updated -->
					<div class="row-item">
						<p>{{ $t('text.lastUpdated') }}:</p>
						<p>{{ formatDate(item.ptUpdatedAt.de) }} {{ $t('text.by') }} {{ item.updatedBy.de }}</p>
					</div>
				</div>
			</div>
			<!-- Marketplace Approval States -->
			<div class="expanded-row">
				<div class="marketplace-row mt-4">
					<p class="title mb-4">{{ $t('text.marketplaceApprovalStates') }}:</p>
					<div>
						<div class="sales-channels-headers pb-2 pl-2 pr-6">
							<p>{{ $t('text.marketplaceSalesChannels') }}</p>
							<p>{{ $t('text.approval') }}</p>
						</div>
						<div class="row-item pt-4 pl-2">
							<div>
								<p class="row-item-title">{{ $store.state.selectedServiceProvider.fields.title[selectedLocale] }}</p>
								<p class="row-item-subtitle">{{ item?.websites?.de?.map(({ name }) => name)?.join(', ') }}</p>
							</div>
							<ApprovalStatus class="pr-6" :status="mapPeakStatus(item.active.de)" />
						</div>
					</div>
				</div>
				<div class="product-row mt-4">
					<p class="title mb-4">{{ $t('text.includedProducts') }}:</p>
					<IncludedProductsTable :items="item.addedValues.de" :packageId="item.id" />
				</div>
			</div>
			<!-- TODO: no categories ATM in the response, replace staticCategoriesForTesting with the real categories if we find a way -->
			<!-- Categories -->
			<!-- <div class="expanded-row mt-6">
				<div class="categories-row">
					<p class="title mb-4">{{ $t('text.categories') }}:</p>
					<CategoriesTable :items="staticCategoriesForTesting" />
				</div>
				<v-spacer class="flex-2" />
			</div> -->
		</template>
	</CommonTable>
</template>

<script>
import Common from '@/mixins/Common.vue'
import CommonTable from '@/components/common/tables/Table.vue'
import ApprovalStatus from '@/components/common/ApprovalStatus.vue'
import Status from '@/components/common/tables/Status.vue'
import IncludedProductsTable from '@/views/applications/packageDesigner/tables/IncludedProductsTable.vue'
import CategoriesTable from '@/views/applications/packageDesigner/tables/CategoriesTable.vue'

export default {
	name: 'PackagesTable',
	components: { CommonTable, ApprovalStatus, Status, IncludedProductsTable, CategoriesTable },
	emits: ['update:limit', 'update:offset'],
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		total: Number,
		limit: Number,
		offset: Number,
	},
	mixins: [Common],
	data() {
		return {
			sortBy: [],
			expanded: [],
		}
	},
	computed: {
		headers() {
			return [
				{
					title: this.$t('text.type'),
					key: 'packageType.de',
					value: 'packageType.de',
				},
				{
					title: this.$t('text.service'),
					key: 'name.de',
					value: 'name.de',
				},
				// {
				// 	title: '',
				// 	key: 'mainCategory',
				// 	value: 'mainCategory',
				// 	sortable: false,
				// },
				// {
				// 	title: this.$t('text.productCategory'),
				// 	key: 'category',
				// 	value: 'category',
				// 	sortable: false,
				// },
				{
					title: this.$t('text.marketplace'),
					key: 'marketplace',
					value: 'marketplace',
					sortable: false,
				},
				{
					title: this.$t('text.approval'),
					key: 'active.de',
					value: 'active.de',
					width: '15%',
				},
				// TODO: no status for phase 1
				// {
				// 	title: this.$t('text.status'),
				// 	key: 'status',
				// 	value: 'status',
				// 	width: '50px',
				// 	align: 'center',
				// },
				{
					title: '',
					key: 'actions',
					value: 'actions',
					width: '50px',
					sortable: false,
				},
			]
		},
		categories() {
			const result = []

			this.items.forEach(({ id, mainCategory, secondaryCategory }) => {

				mainCategory?.de?.forEach(category => {
					result.push({
						id,
						mainCategory: category,
						subCategory: secondaryCategory?.[category]?.de,
					})
				})

			})

			return result
		},
		staticCategoriesForTesting() {
			return [
				{
					id: 1,
					mainCategory: 'Category 1',
					subCategory: ['Subcategory 1'],
				},
				{
					id: 2,
					mainCategory: 'Category 2',
					subCategory: ['Subcategory 2'],
				},
			]
		}
	},
	methods: {
		mapPeakStatus(status) {
			switch (status) {
				case true:
					return 'active'
				case false:
					return 'deactivated'
				default:
					return 'error'
			}
		},
	}
}
</script>

<style scoped lang="scss">
.main-category {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;

	.category-chip {
		background-color: #DADADA;
		padding: 2px 5px;
		border-radius: 5px;

		p {
			font-size: 13px;
			color: #000;
			white-space: nowrap;
		}
	}
}

.expanded-row {
	display: flex;
	gap: 100px;

	.general-row {
		display: flex;
		gap: 5px;
		flex-direction: column;
		flex: 1;
	}

	.general-row-second {
		display: flex;
		gap: 5px;
		flex-direction: column;
		flex: 1;
		width: 50%;
	}

	.marketplace-row {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.product-row {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.categories-row {
		flex: 1;
	}

	.sales-channels-headers {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid grey;
	}

	.row-item {
		display: flex;
		justify-content: space-between;

		.row-item-title {
			font-size: 15px;
		}

		.row-item-subtitle {
			font-size: 12px;
		}
	}

	.flex-2 {
		flex: 2;
	}
}
</style>