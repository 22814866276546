<template>
	<div class="AddedValueType">
		<v-select v-model="model" :items="types" :item-props="typeProps" :item-value="type => type.id" hide-details
			variant="outlined" density="compact" bg-color="#FFF" @click.stop>
			<template #item="{ item, props }">
				<v-list-item v-bind="props" :title="$t('text.' + item.raw.id)"
					:subtitle="$t('text.' + item.raw.id + '-subtitle')" style="cursor: pointer;">
					<template #prepend>
						<v-icon v-if="model == item.raw.id" color="primary">mdi-radiobox-marked</v-icon>
						<v-icon v-else>mdi-radiobox-blank</v-icon>
					</template>
				</v-list-item>
				<!-- TODO: remove once we are sure - i dont know yet if this might be better
				<div class="item">
					<v-icon v-if="model == item.raw.id">mdi-radiobox-marked</v-icon>
					<v-icon v-else>mdi-radiobox-blank</v-icon>
					<div>
						<div>{{ $t('text.' + item.raw.id) }}</div>
						<div class="subtitle">{{ $t('text.' + item.raw.id + '-subtitle') }}</div>
					</div>
				</div>
				-->
			</template>
			<template #append>
				<v-tooltip location="bottom">
					<template #activator="{ props }">
						<v-icon v-bind="props">mdi-information</v-icon>
					</template>
					<div class="tooltip">
						<p class="tooltip-title">{{ $t('text.addedOfferTooltipTitle') }}</p>
						<p>{{ $t('text.addedOfferTooltipText') }}</p>
					</div>
				</v-tooltip>
			</template>
			<template #append-item>
				<v-list-item style="cursor: pointer;" prepend-icon="mdi-cog" @click="$emit('customizeOptions')">
					{{ $t('text.customizeOptions') }}
				</v-list-item>
			</template>
		</v-select>
	</div>
</template>

<script>
export default {
	name: 'AddedValueType',
	props: {
		modelValue: Object,
	},
	data: () => ({
		model: 'ov',
		// TODO: filter by package kind
		types: [
			{ id: 'included', },
			{ id: 'optionalOffer' },
			{ id: 'fixed' },
			{ id: 'ov' },
		],
	}),
	methods: {
		typeProps(type) {
			return {
				title: this.$t('text.' + type.id),
				subtitle: this.$t('text.' + type.id + '-subtitle'),
			}
		},
	},
}
</script>

<style scoped lang="scss">
.item {
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 5px 10px;
	border-bottom: 1px solid #ddd;
}

.subtitle {
	font-size: smaller;
	color: gray;
}

.tooltip {
	max-width: 320px;

	p {
		color: #000;
		font-size: 15px;
	}

	.tooltip-title {
		font-weight: bold;
	}
}
</style>