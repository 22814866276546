<template>
	<div style="height: 0;">
		<!-- TODO: the initial binding to drawer = false seems to mess up the drawer.. -->
		<v-navigation-drawer :scrim="false" :permanent="isSideBarFixed" width="300" v-model="drawer" class="sidebar" style="z-index:3;position:fixed">
			<div style="margin-bottom: 100px;">
				<!-- Notifications -->
				<div v-if="notifications && notifications.length > 0" class="pa-4">
					<span v-for="notification of notifications" :key="notification.content">
						<!-- Warning -->
						<div v-if="notification.type === 'warn'" class="warn">
							<span class="d-flex align-items-center">
								<v-icon color="#ff9e21">mdi-alert</v-icon>&nbsp;&nbsp;
								<span class="warning-text">{{ $t('text.warning') }}</span>
							</span>
							{{ notification.content }}
						</div>

						<!-- Info -->
						<div v-if="notification.type === 'info'" class="information">
							<span class="d-flex align-items-center">
								<v-icon color="#088ec0">mdi-alert-circle</v-icon>&nbsp;&nbsp;
								<span class="info-text">{{ $t('text.information') }}</span>
							</span>
							{{ notification.content }}
						</div>
					</span>
				</div>

				<!-- Action Buttons--> 
				<div v-if="actions && actions.length > 0" class="pl-4 pt-2 pr-4">
<!--				<p style="font-weight: bold; font-size: 0.9375rem; padding-top: 0;">{{ $t('text.actions') }}</p>-->
					<v-btn v-for="action of actions" :key="action.event"
						block
						class="action"
						:class="{
							[ action.style ]: true,
							dark: action.dark,
						}"
						elevation="0"
						:theme="action.dark ? 'dark' : 'light'"
						:disabled="disabled || action.disabled"
						:data-cy="action.datacy"
						@click="$emit('action', { function: action.function, params: action.params })"
					>
						<v-icon>{{action.icon}}</v-icon>
						{{action.title}}
					</v-btn>
				</div>

				<!-- Translation Status -->
				<Translations v-if="translations" :title="$t('text.translations')" :translations="translations" :expanded="true" @switch-locale="switchLocale"
					@edit:translations="$emit('edit:translations')" :displayEditTranslations="displayEditTranslations" />

				<!-- Marketplace Status -->
				<MarketplaceStatus v-if="clientStatuses" :title="$t('text.marketplaceStatus')" :marketplaces="clientStatuses" :expanded="true"/>

				<!-- Navigation Links / Sections -->
				<SideBarNavigation v-if="navigation?.length > 0" :title="$t('text.sidebar')" :sections="navigation" @set-active="setActive" :expanded="true"/>

				<slot></slot>
			</div>
		</v-navigation-drawer>
		<v-btn size="large" icon class="nav-drawer__burger-icon" v-if="!isSideBarFixed" @click="drawer = !drawer">
			<v-icon>mdi-forwardburger</v-icon>
		</v-btn>
	</div>
</template>

<script>
import MarketplaceStatus from '@/components/common/MarketplaceStatus.vue'
import Translations from '@/components/common/Translations.vue'
import SideBarNavigation from '@/components/common/SideBarNavigation.vue'
import Common from '@/mixins/Common.vue'

// TODO: if !isSideBarFixed -> allow to hide the sidebar

export default {
	name: "SideBar",
	components: { MarketplaceStatus, Translations, SideBarNavigation },
	mixins: [Common],
	props: {
		actions: Array,
		disabled: Boolean,
		navigation: Array,
		clientStatuses: Array,
		translations: Array,
		notifications: Array,
		displayEditTranslations: Boolean,
	},
	data: () => ({
		drawer: true,
	}),
	watch: {
		isSideBarFixed(v) {
			this.drawer = !v
		},
		navigation(v) {
			if (v?.length > 0) {
				this.activeLink = v[0].id
			}
		},
	},
	methods: {
		setActive(key) {
			this.$emit('action', { function: 'setActive', params: key })
		},
		switchLocale(locale) {
			this.$emit('action', { function: 'setLocale', params: locale })
		},
		closeSidebar() {
			if (this.isSideBarFixed) return false
			this.drawer = false
		},
	},
	mounted() {
		if (!this.isSideBarFixed) this.drawer = false
	},
}
</script>

<style scoped lang="scss">
.sidebar {
  top: 64px !important;
  z-index: 100 !important;
  ::v-deep #navlist li {
    padding-left: 0;
  }
}

.v-btn.action { font-size: 15px !important; height: 41px !important; }
.v-btn.action.dark { color: white; }

.nav-drawer__burger-icon {
  position: fixed;
  z-index: 999;
  top: 50vh;
  //transform: translateY(-50%);
  left: -10px;
  background: lightgray;
}

#navlist li {
	list-style-type: none;
	width: 100%;
	padding: 10px 10px 10px 20px !important;
	align-items: middle;
	font-family: 'Inter', sans-serif;
	font-size: 17px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: -0.25px;
	font-weight: 500; 
}
#navlist a {
	color: #000000;
}

#navlist .active { 
	border-top: 1px solid #ededed;
	border-bottom: 1px solid #ededed; 
	background-color: #00aeef !important;
	color: #ffffff !important;
}

p {
  color: black;
  font-family: 'Inter', sans-serif;
  font-size: 14pt;
  padding-top: 20px;
}

//Button Style
%button-base {
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 14pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
  margin-top: 10px;
}

.blue {
  @extend %button-base;
  background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%) !important;
  border: thin #088ec0 solid !important;
}

.green {
  @extend %button-base;
  background: linear-gradient(0deg, #62c522 0%, #62c522 100%) !important;
  border: thin #58b01e solid !important;
}

.red {
  @extend %button-base;
  background: linear-gradient(0deg, #ff4848 0%, #f34545 100%) !important;
  border: thin #e74d4c solid !important;
}

.default {
  @extend %button-base;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
}

.outline {
  @extend %button-base;
  background: transparent !important;
  border: thin #000 solid !important;
}

.none {
  @extend %button-base;
  background: transparent !important;
  border: transparent !important;
}
</style>
