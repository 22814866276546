<template>
	<v-list class="language-list">
		<v-list-item v-for="item in languageNavigationItems" :key="item.code"
			:class="{ 'language-list-item': true, 'active': item.code === $store.state.activeTranslation }"
			@click="setActiveTranslation(item.code)">
			<template #prepend>
				<LanguageFlag v-model="item.code" />
			</template>
			<v-list-item-title class="pl-2">{{ getLocaleTitle(item) }}</v-list-item-title>
			<v-icon v-if="item.error" size="20px" icon="mdi-alert-octagon" />
			<v-icon v-else-if="displayMissingTranslationsIcon(item.code)" size="20px" icon="mdi-alert" />
			<v-icon v-else size="20px" icon="mdi-check" :color="item.code === $store.state.activeTranslation ? '#FFF' : 'success'" />
		</v-list-item>
	</v-list>
</template>

<script lang="ts">
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import LanguagesNavigation from '@/mixins/LanguagesNavigation.vue'

export default {
	name: 'LanguageSidebar',
	components: { LanguageFlag },
	props: {
		checkIfHasMissingTranslations: Function,
	},
	mixins: [LanguagesNavigation],
	methods: {
		displayMissingTranslationsIcon(code) {
			if (code === 'all') return this.languageNavigationItems.filter(({ code }) => code !== 'all').some(({ code }) => this.checkIfHasMissingTranslations(code))

			return this.checkIfHasMissingTranslations(code)
		},
		getLocaleTitle(locale) {
			let title = this.$t('text.' + locale.name)
			if (locale.default) title += ` (${this.$t("text.default")})`
			return title
		},
	},
}
</script>

<style lang="scss">
.language-list {
	background: transparent;
	padding: 0;
	font-size: 15px;

	.language-list-item {
		height: 48px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		color: #000;

		&.active {
			color: #ffff;
			background-color: #00aeef;

			.v-list-item-title {
				font-weight: 600;
			}
		}

		.v-list-item-title {
			font-size: 15px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: -0.25px;
		}

		.v-list-item__content {
			display: flex;
			flex-grow: 1;
			align-items: center;
			justify-content: space-between;
		}

		.v-list-item__prepend+.v-list-item__content {
			margin-left: 12px
		}
	}
}
</style>
