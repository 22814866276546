<template>
	<DialogV2 ref="dialogRef" :confirmLabel="confirmLabel" :cancelLabel="cancelLabel" :onConfirm="onConfirm"
		:onCancel="onCancel" :closeOnConfirm="currentStep === this.steps.length" :closeOnCancel="currentStep === 1"
		:disableConfirmButton="!selected.length" :currentStep="currentStep" :steps="steps" width="70vw" height="95vh">
		<template #title>
			<v-toolbar-title>
				<span>{{ $t("text.newPackage") }}</span>
				<p class="sub-title">{{ subTitle }}</p>
			</v-toolbar-title>
		</template>

		<!-- Search / Filter Header -->
		<template #header-utility v-if="currentStep === 1">
			<div style="border-bottom: 1px solid rgb(221, 221, 221);" class="px-8 py-3">
				<div class="d-flex align-center ga-8">
					<div class="d-flex ga-4 flex-grow-1">
						<v-text-field variant="outlined" density="compact" clearable hide-details
							prepend-inner-icon="mdi-magnify" :placeholder="$t('text.searchPeakSpPlaceholder')"
							v-model="filters.searchString" @keyup.enter="search"
							@update:modelValue="filters.searchString = $event" @click:clear="clear" data-cy="search" />
						<v-btn class="blueButton" theme="dark" elevation="0" @click="search" data-cy="searchButton">
							{{ $t('text.search') }}
						</v-btn>
					</div>
					<ProductFilters :onApplyFilter="applyFilters" />
				</div>
			</div>
		</template>

		<!-- Sidebar -->
		<template #sidebar v-if="currentStep > 2">
			<!-- Step 3 - Name -->
			<LanguageSidebar v-if="currentStep === 3" :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
		</template>

		<!-- Content -->
		<template #content>
			<div>
				<!-- Step 1 - Products -->
				<div v-if="currentStep === 1">
					<div class="content">
						<p class="title">{{ $t('text.chooseProducts') }}</p>
						<p>{{ $t('text.chooseProductsHelp') }}</p>
					</div>
					<ProductsTable v-model:items="products" :total="total" :limit="filters.limit"
						:offset="filters.offset" @update:limit="updateLimit" @update:offset="updateOffset"
						:loading="loading" @update:selected="updateSelected" />
				</div>
				<!-- Step 2 - Product Config -->
				<div v-if="currentStep === 2">
					<!-- <AddedValue2 v-model="model" :selected="selected" /> -->
					
					<FieldSet id="addedOfferDefinitions" class="step-2-content-item" />
					<DisclosureItems :modelValue="addedValues" ref="disclosureItems">
					<template #itemHead="{ item, expanded }">
						<div class="expansionPanelHeader">
							<div><v-icon :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" />{{ item.fields.name[serviceLocale] }}</div>
							<AddedValueType @customizeOptions="$refs.optionalSettingsDialog?.open?.()" />
						</div>
					</template>

					<template #itemBody="{ item }" >
						<div class="optional-items">
						<FieldSet id="generalSettings">
							TODO: where do we get the variants from?
							this is still an ongoing discussion in Product Management
							we may need some aggregation logic at the server
							OR: we may need to "group" the products into configurable products before starting this dialog
							<!-- <Field typeName="AddedValue" fieldName="productVariants" v-model="model.fields.productVariants" /> -->
						</FieldSet>

						<div>
							<Optional id="reductionSettings" v-model="optionalSettings" startEnabled
							:autoShow="item.fields.reductionPercent?.de > 0" >
								<FieldSet id="reductionSettings">
									<Field typeName="AddedValue" fieldName="reductionPercent" v-model="item.fields.reductionPercent" required />
								</FieldSet>
							</Optional>

							<Optional id="seasonalDiscounts" v-model="optionalSettings" startEnabled
							:autoShow="item.fields.seasonalDiscounts?.de?.length > 0" @disable="item.fields.seasonalDiscounts.de.length = 0;">
								<FieldSet id="seasonalDiscounts" class="pt-6">
									<Table
										class="mt-4"
										:columns="[ 'startDate', 'endDate', 'reductionPercent' ]"
										typeId="SeasonalDiscount"
										:editable="true"
										v-model="item.fields.seasonalDiscounts.de"
										@create="item.fields.seasonalDiscounts.de.push($event)"
									/>
								</FieldSet>
							</Optional>
						</div>
						<div class="todo">https://app.zeplin.io/project/604748c2bca41a3e66787ef4/screen/647daa0944b5cc2605fe228e</div>
							<Optional id="contingentSettings" v-model="optionalSettings" startEnabled>
								<FieldSet id="contingentSettings" >
									<Field typeName="AddedValue" fieldName="maximumRedemptionCount" v-model="item.fields.maximumRedemptionCount" />
								</FieldSet>
							</Optional>
							<!-- TODO: this should actually be a field -->
							<Optional id="validitySettings" v-model="optionalSettings" startEnabled>
								<FieldSet id="validitySettings" >
									<Field typeName="AddedValue" fieldName="validityType" v-model="item.fields.validityType" />
									<div style="display: flex; gap: 10px;" :class="{
										disabled: item.fields.validityType?.de != 'more',
									}">
										<Field typeName="AddedValue" fieldName="validDaysBefore" v-model="item.fields.validDaysBefore" style="flex: 1;" />
										<Field typeName="AddedValue" fieldName="validDaysAfter" v-model="item.fields.validDaysAfter" style="flex: 1;" />
									</div>
								</FieldSet>
							</Optional>
							<Optional id="showPrice" v-model="optionalSettings" startEnabled>
								<FieldSet id="additionalSettings" >
									<Field typeName="AddedValue" fieldName="showPrice" v-model="item.fields.showPrice" />
								</FieldSet>
							</Optional>
						</div>
					</template>
				</DisclosureItems>
				</div>
				<!-- Step 3 - Name -->
				<div v-if="currentStep === 3">
					<TranslateableField typeName="Package" fieldName="name" v-model="model.fields.name"
						:fieldLocale="$store.state.activeTranslation" :locales="displayedLocales" />
				</div>
				<DataDialog v-model="optionalSettings" :title="$t('text.customizeOptions')" ref="optionalSettingsDialog" :useWrap="true">
					<template #content="{ wrap }">
						<!-- TODO: the model is wrong here, we need a separate model per item.
									probably this whole dialog should actually move into the itemHead instead.
						-->
						<OptionalSettings v-model="wrap.model" @update:modelValue="wrap['model'] = $event" :items="optionalSettingsItems" />
					</template>
				</DataDialog>
			</div>
		</template>
	</DialogV2>
</template>

<script >
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import DialogV2 from "@/components/common/DialogV2.vue";
import DataDialog from "@/components/common/DataDialog.vue";
import LanguageSidebar from '@/components/common/LanguageSidebar.vue';
import Common from "@/mixins/Common.vue";
import AddedValue2 from '@/views/applications/packageDesigner/AddedValue2.vue';
import FieldSet from '@/views/applications/packageDesigner/FieldSet.vue';
import ProductFilters from '@/views/applications/packageDesigner/ProductFilters.vue';
import ProductsTable from '@/views/applications/packageDesigner/tables/ProductsTable.vue';
import TranslateableField from '@/components/fields/TranslateableField.vue';
import LanguagesNavigation from '@/mixins/LanguagesNavigation.vue'
import Optional from '@/views/applications/packageDesigner/Optional.vue'
import OptionalSettings from '@/views/applications/packageDesigner/OptionalSettings.vue'
import AddedValueType from '@/views/applications/packageDesigner/AddedValueType.vue'
import Table from '@/views/applications/packageDesigner/Table.vue'
import DisclosureItems from '@/views/applications/packageDesigner/added-values-PoC/DisclosureItems.vue'

export default {
	name: "NewPackageDialog",
	components: { DialogV2, ProductsTable, ProductFilters, LanguageSidebar, AddedValue2, FieldSet, TranslateableField, Optional, OptionalSettings, AddedValueType, Table, DataDialog, DisclosureItems },
	mixins: [Common, LanguagesNavigation],
	props: {
		modelValue: Object,
		linkedEntries: Object,
	},
	data() {
		return {
			products: [],
			currentStep: 1,
			loading: false,
			total: 0,
			selected: [],
			filters: { searchString: '', limit: 10, offset: 0 },
			model: null,
			addedValues: [],
			optionalSettings: {},
		};
	},
	watch: {
		model(n) { this.$emit('update:modelValue', n) },
		modelValue: {
			deep: true,
			handler(n) {
				console.log('PARENT MODEL CHANGED', this.modelValue)
				this.model = n
				this.hasSalesChannelMismatch()
				//this.validate()
			},
		},
		addedValues(n) {
			// TODO: check if we need to update smth here
		},
		linkedEntries: {
			deep: true,
			handler(n) {
				this.hasSalesChannelMismatch()
			},
		},
	},
	computed: {
		existingProductsSKU() {
			const result = []

			this.model?.fields?.addedValues?.de?.forEach(({ sys: { id } }) => {
				const item = this.linkedEntries[id]

				if (!item) return

				result.push(item.sku.de[0])
			})

			return result
		},
		hasNameStep() {
			return !this.modelValue?.fields?.name?.de?.length
		},
		steps() {
			const steps = [
				{
					stepNr: 1,
					title: this.$t("text.products"),
					icon: "mdi-shape",
				},
				{
					stepNr: 2,
					title: this.$t("text.productConfig"),
					icon: "mdi-cog",
				},
			]

			if (this.hasNameStep) steps.push({ stepNr: 3, title: this.$t("text.name"), icon: "mdi-translate" })

			return steps
		},
		subTitle() {
			switch (this.currentStep) {
				case 1:
					return this.$t("text.includedProducts")
				case 2:
					return this.$t("text.addedValueOfferConfiguration")
				case 3:
					return this.$t("text.packageName")
				default:
					return ''
			}
		},
		confirmLabel() {
			return this.currentStep === this.steps.length ? this.$t('text.confirm') : this.$t('text.next')
		},
		cancelLabel() {
			return this.currentStep === 1 ? this.$t('text.cancel') : this.$t('text.back')
		},
		displayedLocales() {
			return this.languageNavigationItems.reduce((locales, { code }) => {
				if (code !== "all" && (this.$store.state.activeTranslation === "all" || this.$store.state.activeTranslation === code)) {
					locales.push(code)
				}
				return locales
			}, [])
		},
		optionalSettingsItems() {
			return [
				{ id: 'reductionSettings' },
				{ id: 'seasonalDiscounts' },
				{ id: 'contingentSettings' },
				{ id: 'validitySettings' },
				{ id: 'additionalSettings', items: ['showPrice', 'mandatory'] },
			]
		},
		step2Model() {
			// TODO: revise this. Should we make a get call to the api to get added values based on the id-s in the selected array prop? Or should the selected array prop already contain the details of the added values?
			return this.model?.fields?.addedValues?.de ?? []
		},
	},
	methods: {
		open() {
			this.$refs.dialogRef.open();
		},
		close() {
			this.$refs.dialogRef.close();
		},
		onConfirm() {
			this.currentStep = this.currentStep + 1
			if (this.currentStep == 2) {
				// create an empty added value object for every selected product
				// TODO: the selected model should contain more details than just the id of the product such as the name, so we can display it in the sidebar for added values, potentially sku, etc. keep in mind the sku is an array of strings as multiple products can be associated with an added value
				console.log('Selected ---- ', this.selected)
				this.addedValues = this.selected.map((product, index) => {
					return {
						sys: { id: 'AV_' + product.id + index + Math.floor(Math.random() * 1000)},
						fields: {
							name: { de: product.product_name[0].value },
							reductionPercent: { de: 0 },
							seasonalDiscounts: { de: [] },
							maximumRedemptionCount: { de: 1 },
							validityType: { de: 'stay' },
							validDaysBefore: { de: 0 },
							validDaysAfter: { de: 0 },
							showPrice: { de: true },
							sku: { de: [product.product_sku] },
							websites: { de: [] },
						}
					}
				})

			}

			if (this.currentStep > this.steps.length) {
				for (const addedValue of this.addedValues) {
					this.linkedEntries[addedValue.sys.id] = addedValue
					this.model.fields.addedValues.de.push({ sys: { id: addedValue.sys.id } })
				}
				this.$emit('update:modelValue', this.model)
				console.log('Model', this.model)
				console.log('Linked Entries', this.linkedEntries)
				this.currentStep = 1
			}
		},
		onCancel() {
			const newStep = this.currentStep - 1
			this.currentStep = newStep < 1 ? 1 : newStep
		},
		showLoader(value = false) {
			this.loading = value
		},
		// TODO: we are getting the products here and sending them up to the parent component(s) - 2 levels and then down again to other components if needed. This can be improved by getting the products (without filtering) on packageDesigner as well.
		async getProducts({ total } = { total: false }) {
			try {
				if (this.currentStep !== 1) return

				this.showLoader(true)
				const productFilters = { ...omitBy(this.filters, value => isEmpty(value?.toString())), offset: Math.floor(this.filters.offset / this.filters.limit) }
				const clientId = this.$store.state.selectedClient.sys.id
				const promises = [this.$httpPost(`/packageTravel/products?clientId=${clientId}`, productFilters)]

				if (total) {
					promises.push(this.$httpPost(`/packageTravel/productsCount?clientId=${clientId}`, { ...omit(productFilters, ['limit', 'offset']) }))
				}

				const [{ products }, productsCount] = await Promise.all(promises)

				if (total) this.total = productsCount?.total

				this.products = this.mapProducts(products)
				console.log('Products', this.products)
				this.$emit('get-products', this.products)
			} catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.showLoader()
			}
		},
		mapProducts(data) {
			return data.map(item => ({
				...item,
				activities: omitBy(item.activities, isEmpty), id: item.product_id,
				disableSelectRow: this.existingProductsSKU.includes(item.product_sku),
			}))
		},
		search() {
			this.filters.offset = 0
			this.getProducts({ total: true })
		},
		clear() {
			this.filters.offset = 0
			this.filters.searchString = ''
			this.getProducts({ total: true })
		},
		updateSelected(selected) {
			this.selected = selected
		},
		updateLimit(limit) {
			this.filters.offset = 0
			this.filters.limit = limit
			this.getProducts()
		},
		updateOffset(offset) {
			this.filters.offset = offset
			this.getProducts()
		},
		async applyFilters(filters) {
			this.filters = { ...this.filters, ...filters }
			this.getProducts({ total: true })
		},
		checkIfHasMissingTranslations(locale) {
			return !this.model.fields.name[locale]
		},
		hasSalesChannelMismatch() {
			if (!this.products?.length > 0 || !this.model) return false
			else {
				const salesChannels = this.model.fields.websites.de.map(channel => channel.id)
				if (!this.linkedEntries) return

				const addedValueEntries = this.model.fields.addedValues?.de.map(({ sys: { id } }) => this.linkedEntries?.[id])
				const addedValuesSalesChannels = [...new Set(addedValueEntries?.flatMap(item => item.fields?.websites?.de || []).map(channel => channel?.website_id))]

				const hasMissingSalesChannels = addedValuesSalesChannels.some(channel => !salesChannels.includes(channel))
				const hasExtraSalesChannels = salesChannels.some(channel => !addedValuesSalesChannels.includes(channel))
	
				const mismatch = hasMissingSalesChannels || hasExtraSalesChannels
				this.$emit('mismatch', mismatch)
				return mismatch
			}
		},
	},
	async mounted() {
		await this.getProducts({ total: true })
		this.model = JSON.parse(JSON.stringify(this.modelValue))

		this.hasSalesChannelMismatch()
	},
};
</script>

<style scoped lang="scss">
.sub-title {
	line-height: normal;
	color: #b1b1b0;
}

.content {
	p {
		font-size: 12px;
		line-height: 16px;
		color: black;
	}

	.title {
		font-size: 17px;
		line-height: 22px;
		font-weight: bold;
	}
}

.tableCard {
	margin-top: 24px;
}

.offers-list {
	padding: 0;
	background-color: #F4F4F4;

	.active {
		background-color: #58A9EE;
	}
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.optional-items {
	// TODO: this is breaking the left border and each optional item now has its own border. Is that fine?
	& > * {
		padding-bottom: 20px;
	}
}

.expansionPanelHeader {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.step-2-content-item {
	margin-bottom: 20px; 
	margin-left: 240px;
}
</style>
