<template>
	<div>
		<v-data-table class="action-table mt-8" :headers="[]" :items="items" :item-value="item => item">
			<template #top>
				<v-row align="center" justify="end" no-gutters class="top-header">
					<v-btn class="mr-4" variant="outlined" density="compact" size="large" icon="mdi-plus" rounded="lg" @click="handleAdd" />
				</v-row>
			</template>
			<template v-slot:item="{ item }">
				<tr class="item">
					<td>
						<div style="display: flex; align-items: center; justify-content: space-between;">
							<p>{{ item.title?.[selectedLocale] }}</p>
							<PopoverMenu :items="actions" />
						</div>
					</td>
				</tr>
			</template>
			<template #bottom></template>
		</v-data-table>
	</div>
</template>

<script>
import PopoverMenu from '@/components/common/PopoverMenu.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ActionTable',
	components: { PopoverMenu },
	mixins: [Common],
	props: {
		items: Array,
		actions: Array,
		handleAdd: Function,
	},
}
</script>

<style lang="scss">
.action-table {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.top-header {
	height: 52px;
	background-color: #F4F4F4;
}

.item {
	:hover {
		background-color: #F9F9F9;
	}
}
</style>
