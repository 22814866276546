<template>
	<div>
		<div v-if="hasNoData">{{ $t('text.' + noDataText) }}</div>
		<v-data-table v-else
			:headers="headers"
			:items="items"
			:no-data-text="$t('text.' + noDataText)"
			class="reusableTable"
			:class="{'mismatch': salesChannelMismatch}"
		>
			<template v-slot:item="{ item }">
				<tr class="itemRow">
					<template v-for="(column, index) in headers" :key="index" >
						<td >
							<template v-if="column.type === 'date'">
								{{ formatDate(item[column.key]) }}
							</template>
							<template v-else-if="column.type === 'string' || column.type === 'number'">
								{{ item[column.key] }}
							</template>
							<template v-else-if="column.type === 'chip'">
								<div>
									<template v-for="value in item[column.key].split(',')">
										<v-chip color="grey" text-color="black" class="ma-2">
											{{ value.trim() }}
										</v-chip>
									</template>
								</div>
							</template>
							<template v-else-if="column.type === 'options'">
								<PopoverMenu :items="options" @click="(action) => handleOptionClick(item.sys?.id ?? item, action)" />
							</template>
						</td>
					</template>
				</tr>
			</template>
			<template #bottom>
				<TableFooter v-if="!hideFooter" :offset="offset" :limit="limit" :results="items" :total="total"
			@update:offset="$emit('update:offset', $event)" @update:limit="$emit('update:limit', $event)" />
			</template>
		</v-data-table>
		<p v-if="items.length && salesChannelMismatch" class="helpText" style="color: orange">{{ $t('text.salesChannelMismatch') }}</p>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import PopoverMenu from '@/components/common/PopoverMenu.vue'
import TableFooter from '@/components/common/tables/TableFooter.vue'

export default {
	name: 'ReusableTable',
	mixins: [Common],
	components: { PopoverMenu, TableFooter },
	props: {
		columns: {
			type: Array,
			required: true,
		},
		items: {
			type: Array,
			required: true,
		},
		noDataText: {
			type: String,
			default: 'noData',
		},
		hideFooter: {
			type: Boolean,
			default: true,
		},
		salesChannelMismatch: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		limit: 15,
		offset: 0,
	}),
	computed: {
		hasNoData() {
			return this.items.length === 0
		},
		headers() {
			return this.columns.map(column => {
				return {
					title: column?.id !== '' ? this.$t('text.' + column.id) : '',
					key: column.id ?? column,
					width: column.width ?? '20%',
					sortable: column?.id == '' ? false : true,
					cellClass: column.id ?? column,
					type: column.type ?? 'string',
				}
			})
		},
		total() {
			return this.items.length
		},
	},
	methods: {
		handleOptionClick(id, action) {
			this.$emit('handleOptionClick', id, action)
		},
	},
	created() {
	},
}
</script>

<style scoped>
.itemRow {
	padding: 10px 10px;
	border-radius: 4px;
	width: 100%;
	align-items: center;
}

.itemRow:hover {
	background-color: #f2f2f2;
	cursor: pointer !important;
}

.reusableTable {
	width: 100%;
	padding: 10px 10px;
}

.reusableTable th {
	text-align: left;
	padding: 15px 10px;
	/* border-bottom: 1px solid #ccc; */
}

.reusableTable td {
	padding: 15px 10px;
}

.dotsIcon:hover {
	transform: scale(1.2);
}

.optionItem:hover {
	cursor: pointer !important;
}

.mismatch {
	border: 2px solid orange;
}

/* TODO: I did not find a way to overwrite the default vuetify table in order to hide the borders in table data items */
/* ::v-deep .v-data-table-header {
	font-weight: bold !important;
	color: black !important;
} */
/* .v-data-table > table > tbody > tr > td {
	border-bottom: none !important;
	border-collapse: collapse !important;
} */

/* TODO: overwrite default table headers to have bold and black text */
/* ::v-deep .v-data-table-header th {
	font-weight: bold !important;
	color: black !important;
} */
</style>