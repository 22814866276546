<template>
	<div>
		<Field
			v-for="locale in effectiveLocales"
			:key="locale"
			v-bind="$props"
			v-model="model"
			:fieldLocale="locale"
			@update:modelValue="this.$emit('update:modelValue', $event)"
			@input.stop
		/>
	</div>
</template>

<script>
// This comp is being used by TranslationWizard to show multiple fields for multiple locales automatically.
// It also covers the concern of 'which field is even translateable'

export default {
	name: 'TranslateableField',
	props: {
		modelValue: [ String, Number, Object, Array, Boolean ],
		// TODO: rename to typeId and fieldId
		typeName: String,
		fieldName: String,
		disabled: Boolean,
		dataCy: String,
		fieldLocale: String,

		// optional overrides
		title: String,
		required: { type: Boolean, default: undefined },
		localized: { type: Boolean, default: undefined },
		helpText: String,
		infoText: String,
		options: Array,
		locales: Array,
	},
	data: () => ({
		model: null,
		field: null,
		type: null,
	}),
	computed: {
		effectiveLocales() {
			if (this.field?.localized) {
				if (this.locales) return this.locales
				if (this.fieldLocale) [ this.fieldLocale ]
				if (this.$store.state.serviceLocale) return [ this.$store.state.serviceLocale ]
			}
			return [ 'de' ]
		},
	},
	watch: {
		modelValue(n) {
			this.model = n
		},
	},
	mounted() {
		this.model = this.modelValue
		if (this.typeName) {
			// TODO: this is copied from Field - generalize
			// TODO: inject the models instead of taking them from the store
			const type = this.$store.state.fieldModels.find(({ name }) => name === this.typeName)
			if (!type) return console.error('Field: type not found [' +  this.typeName + ']')
			this.field = type?.fields[this.fieldName]
			if (!this.field) return console.error('Field: field not found [' + this.typeName + '.' + this.fieldName + ']')
			this.type = this.field.type
			if (this.model == undefined) {
				console.error('Field: model is undefined for [' + this.typeName + '.' + this.fieldName + ']')
			}
		}
	},
}
</script>