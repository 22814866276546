<template>
	<div>
		<DataDialog ref="dialogRef" v-model="model" :title="$t('text.transportationProducts')"
			width="70vw" height="95vh"
		>
			<template #title>
				<v-toolbar-title>
					<span>{{ $t("text.editTransportation") }}</span>
				</v-toolbar-title>
			</template>

			<template #content="{ model }">
				<DisclosureItems :modelValue="model" ref="disclosureItems">
					<template #itemHead="{ item, expanded }">
						<div class="expansionPanelHeader">
							<div><v-icon :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" />{{ item.fields.name[serviceLocale] }}</div>
							<AddedValueType @customizeOptions="$refs.optionalSettingsDialog?.open?.()" />
						</div>
					</template>

					<template #itemBody="{ item }" >
						<div class="optional-items">
							<Optional id="validitySettings" v-model="optionalSettings" startEnabled>
								<FieldSet id="validitySettings" required>
									<Field typeName="AddedValue" fieldName="validityType" v-model="item.fields.validityType" />
									<div style="display: flex; gap: 10px;" :class="{
										disabled: item.fields.validityType?.de != 'more',
									}">
										<Field typeName="AddedValue" fieldName="validDaysBefore" v-model="item.fields.validDaysBefore" style="flex: 1;" />
										<Field typeName="AddedValue" fieldName="validDaysAfter" v-model="item.fields.validDaysAfter" style="flex: 1;" />
									</div>
								</FieldSet>
							</Optional>

							<!-- TODO: extend field to support an array field that adds elements when pressing enter and shows them as tags -->
							<Optional id="arrivalAndDepartureRestrictions" v-model="optionalSettings" startEnabled
								:autoShow="item.fields.stations?.de?.length > 0" @disable="!item.fields.stations;">
								<FieldSet id="restrictions">
									<Field typeName="AddedValue" fieldName="stations" v-model="item.fields.stations" />
								</FieldSet>
							</Optional>

							<!-- since this is not part of the model we cannot use the Field component for the model driven pattern -->
							<FieldSet v-show="isOutwardJourney(item)" id="copyToReturnJourney">
								<v-row no-gutters style="padding-right: 10px;">
									<v-col cols="6" class="d-flex justify-start">
										<p>{{ $t('text.copyToReturnJourneyHelp') }}</p>
									</v-col>
									<v-col cols="6" class="d-flex justify-end">
										<!-- TODO: replace with a button -->
										<mys-switch
											v-model="copyToReturnJourney"
											@update:modelValue="copyStationsForReturnJourney()"
										/>
									</v-col>
								</v-row>
							</FieldSet>

							<Optional id="showPrice" v-model="optionalSettings"
							:autoShow="item.fields.showPrice?.de" @disable="!item.fields.showPrice;">
								<Field typeName="AddedValue" fieldName="showPrice" v-model="item.fields.showPrice" />
							</Optional>

							<Optional id="mandatory" v-model="optionalSettings">
								<Field typeName="AddedValue" fieldName="required" v-model="item.fields.required" />
							</Optional>
						</div>
					</template>
				</DisclosureItems>
			</template>
		</DataDialog>
		<DataDialog v-model="optionalSettings" :title="$t('text.customizeOptions')" ref="optionalSettingsDialog" :useWrap="true">
			<template #content="{ wrap }">
				<!-- TODO: the model is wrong here, we need a separate model per item.
							probably this whole dialog should actually move into the itemHead instead.
				-->
				<OptionalSettings v-model="wrap.model" @update:modelValue="wrap['model'] = $event" :items="optionalSettingsItems" />
			</template>
		</DataDialog>
	</div>
</template>

<script>
import FieldSet from '../FieldSet.vue'
import Field from '../../../../components/fields/Field.vue'
import MysSwitch from '../../../../components/mys/mys-switch.vue'
import Optional from '../Optional.vue'
import OptionalSettings from '../OptionalSettings.vue'
import AddedValueType from '../AddedValueType.vue'
import Common from '@/mixins/Common.vue'
import DisclosureItems from './DisclosureItems.vue'
import DataDialog from '../../../../components/common/DataDialog.vue'

export default {
	components: { FieldSet, Field, MysSwitch, Optional, OptionalSettings, AddedValueType, DisclosureItems, DataDialog },
	mixins: [Common],
	props: {
		modelValue: Object,
	},
	data() {
		return {
			model: null,
			copyToReturnJourney: false,
			optionalSettings: {},
		}
	},
	computed: {
		optionalSettingsItems() {
			return [
				{ id: 'arrivalAndDepartureRestrictions' },
				{ id: 'validitySettings' },
				{ id: 'additionalSettings', items: ['showPrice', 'mandatory'] },
			]
		},
	},
	watch: {
		model(n) { this.$emit('update:modelValue', n) },
		modelValue(n) { this.model = n },
	},
	methods: {
		open() {
			this.$refs.dialogRef.open()
		},
		copyStationsForReturnJourney() {
			if (this.copyToReturnJourney) {
				// we copy the stations from outward journey to return journey. Since we are sure that there will always be two items in the model we can do this directly
				this.model[1].fields.stations = JSON.parse(JSON.stringify(this.model[0].fields.stations))
			}
			else {
				// TODO: should we clear stations for return journey? We might overwrite the stations that were set manually
				// this.model[1].fields.stations = []
			}
		},
		isOutwardJourney(item) {
			return item.fields.name.de.toLowerCase().includes('outward')
		},
	},
	async mounted() {
		this.model = this.modelValue
	},
};
</script>

<style scoped lang="scss">
.sub-title {
	line-height: normal;
	color: #b1b1b0;
}

.content {
	p {
		font-size: 12px;
		line-height: 16px;
		color: black;
	}

	.title {
		font-size: 17px;
		line-height: 22px;
		font-weight: bold;
	}
}

.tableCard {
	margin-top: 24px;
}

.expandable-section {
	margin-top: 24px;
}

.section-body {
	margin-left: 12px;
	margin-right: 12px;
}

.days {
	display: flex;
	justify-content: space-between;
	// width: 50%;
	flex-grow: 1;
	padding: 12px;
}
.column {
	width: 50%;
}
.title {
	font-size: 12px;
	line-height: 22px;
	font-weight: bold;
}
.subtitle {
	font-size: 12px;
	line-height: 16px;
	color: #b1b1b0;
	padding: 12px 0;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
}
.sidebar-options {
	padding: 12px;
	border-bottom: 1px solid #e0e0e0;
	cursor: pointer;
}
.sidebar-options:hover {
	background-color: #0ba7e1;
	color: white;
	// border: none;
}
.highlighted {
	background-color: #0ba7e1;
	color: white;
	// border: none;
}
.panel-header {
	background-color: #f4f4f4;
}

.expandIcon {
	display: flex;
	align-items: center;
	justify-content: left;
	width: calc(18vw + 400px);
	height: 24px;
	border-radius: 50%;
	background-color: #f4f4f4;
}

.sectionTitle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 24px;
	font-size: 14px;
	font-weight: bold;
}

@media (min-width: 1900px) {
	.sectionTitle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80%;
		height: 24px;
	}
}

.sectionTitleEnd {
	display: flex;
	align-items: center;
	justify-content: end;
	width: 30%;
	height: 24px;
}

.optional-items {
	// TODO: this is breaking the left border and each optional item now has its own border. Is that fine?
	& > * {
		padding-bottom: 20px;
	}
}

.expansionPanelHeader {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}


</style>
