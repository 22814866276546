<template>
	<div>
		<FieldSet id="tickets" infoText="ticketsHelp" required>
			<AddButton @click="addTicket" buttonText="ticket" />
			<SectionTable
				:columns="headers"
				:items="tableItems"
				no-data-text="noTicketsAdded"
				@handleOptionClick="handleOptionClick"
			/>
		</FieldSet>
		<AddTicketDialog2
			ref="addTicketDialog"
			v-model="ticket"
			@update:modelValue="upsertTicket"
		/>
		<!-- TODO: use the new component that has both the tickets info and the additional settings in one so we can send props from one model to the other easily -->
		<!-- TODO: the edit ticket option dialog (looks like it needs a translation wizard dialog, but only the title is localizable, whereas the general settings should be the same for every locale) -->
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import SectionTable from '@/views/applications/packageDesigner/SectionTable.vue'
import AddButton from '@/views/applications/packageDesigner/AddButton.vue'
import FieldSet from './FieldSet.vue'
import AddTicketDialog2 from '@/components/packageDesigner/AddTicketDialog2.vue'
import RadioSelect from '@/components/common/RadioSelect.vue'
import Field from '../../../components/fields/Field.vue'
import FieldWrap from '../../../components/common/FieldWrap.vue'

export default {
	name: 'TicketsInfo2',
	components: { SectionTable, AddButton, FieldSet, AddTicketDialog2, RadioSelect, Field, FieldWrap },
	mixins: [Common],
	props: {
		// updateModel: Boolean,
		// serviceProvider: Object,
		// status: String,
		// modelValue: Object,
	},
	data() {
		return {
			tickets: [
				{
					sys: {
						id: '1',
					},
					fields: {
						title: {
							de: 'Toddler & Child (0 - 6)',
							en: 'Toddler & Child (0 - 6)',
						},
						duration: {
							de: {
								value: '1',
								unit: 'year',
							}
						},
						ageGroupStart: {
							de: '2006',
						},
						ageGroupEnd: {
							de: '2008',
						},
						price: {
							de: '15',
						},
					},
				},
				{
					sys: {
						id: '2',
					},
					fields: {
						title: {
							de: 'Child (7 - 17)',
							en: 'Child (7 - 17)',
						},
						duration: {
							de: {
								value: '1',
								unit: 'year',
							}
						},
						ageGroupStart: {
							de: '2003',
						},
						ageGroupEnd: {
							de: '2005',
						},
						price: {
							de: '25',
						},
					},
				},
			],
			ticket: {},
			reductionOptions: [
				{
					title: {
						de: 'Senior (>65)',
						en: 'Senior (>65)',
					},
				},
				{
					title: {
						de: 'Adult (>18)',
						en: 'Adult (>18)',
					},
				},
				{
					title: {
						de: 'Teen (15-17)',
						en: 'Teen (15-17)',
					},
				},
				{
					title: {
						de: 'Junior (7-14)',
						en: 'Junior (7-14)',
					},
				},
				{
					title: {
						de: 'Toddler & Child (0-6)',
						en: 'Toddler & Child (0-6)',
					},
				},
			],
			priceRules: [(v) => (v && v >= 1) || 'Price cannot be 0'],
		}
	},
	computed: {
		headers() {
			// return [
			// 	{ text: this.$t('text.type'), value: 'type', type: 'string' },
			// 	{
			// 		text: this.$t('text.ageRestriction'),
			// 		value: 'ageRestriction',
			// 		type: 'string',
			// 	},
			// 	{
			// 		text: this.$t('text.duration'),
			// 		value: 'duration',
			// 		type: 'string',
			// 	},
			// 	{ text: this.$t('text.price'), value: 'price', type: 'string' },
			// 	{ text: '', value: 'actions', type: 'options' },
			// ]
			// only return the id and type, the child component will take care of localization
			return [
				{ id: 'title', type: 'string' },
				{ id: 'ageRestriction', type: 'string' },
				{ id: 'duration', type: 'string' },
				{ id: 'price', type: 'string' },
				{ id: '', type: 'options', width: '0%' },
			]
		},
		tableItems() {
			return this.tickets.map((ticket) => {
				return {
					sys: ticket.sys,
					title: ticket.fields.title[this.serviceLocale],
					ageRestriction: this.showAgeRestriction(ticket),
					duration: this.showDuration(ticket.fields.duration.de),
					price: this.showPrice(ticket.fields.price.de),
				}
			})
		},
		currency() {
			return this.$store.state.selectedClient?.fields?.currency?.de || '€'
		},
		durationItems() {
			return [
				{
					title: this.$t('text.year'),
					value: 'year',
				},
				{
					title: this.$t('text.season'),
					value: 'season',
				},
				{
					title: this.$t('text.month'),
					value: 'month',
				},
				{
					title: this.$t('text.day'),
					value: 'day',
				},
			]
		},
	},
	methods: {
		ticketFactory() {
			return {
				sys: {
					id: '',
					type: 'Entry',
					createdAt: '',
					updatedAt: '',
					contentType: {
						sys: {
							id: 'ticket',
							type: 'Link',
							linkType: 'ContentType',
						},
					},
				},
				fields: {
					title: {
						de: '',
						en: '',
					},
					duration: {
						de: {
							value: '',
							unit: '',
						}
					},
					ageGroupStart: {
						de: '',
					},
					ageGroupEnd: {
						de: '',
					},
					price: {
						de: '',
					},
				},
			}
		},
		addTicket() {
			// this.$emit('addTicket')
			this.ticket = this.ticketFactory()
			console.log('factory called', this.ticket)
			this.$refs.addTicketDialog.open()
		},
		handleOptionClick(id, action) {
			// this.$emit('handleOptionClick', id, action)
			switch (action) {
				case 'edit':
					this.editTicket(id)
					break
				case 'delete':
					this.deleteTicket(id)
					break
				default:
					break
			}
		},
		editTicket(id) {
			// TODO: if we get the ticket as a prop then we can emit to the parent component otherwise we can implement the editTicket method here
			// this.$emit('editTicket', id)
			this.ticket = this.tickets.find((ticket) => ticket.sys.id === id)
			console.log('edit', this.ticket)
			this.$refs.addTicketDialog.open()
		},
		deleteTicket(id) {
			// TODO: if we get the ticket as a prop then we can emit to the parent component otherwise we can implement the deleteTicket method here
			// this.$emit('deleteTicket', id)
			this.tickets = this.tickets.filter((ticket) => ticket.sys.id !== id)
			// TODO: some server method to delete the ticket
		},
		upsertTicket() {
			if (this.ticket.sys.id !== '') {
				this.updateTicket()
			} else {
				this.createTicket()
			}
		},
		showAgeRestriction(ticket) {
			const ageGroupStart = ticket.fields.ageGroupStart.de
			const ageGroupEnd = ticket.fields.ageGroupEnd.de

			if (!ageGroupStart) {
				return `> ${ageGroupEnd}`
			}

			return `${ageGroupStart} - ${ageGroupEnd}`
		},
		showPrice(price) {
			return `${Number(price).toFixed(2)} ${this.currency}`;
		},
		showDuration(duration) {
			const translatedUnit = this.$t(`text.${duration.unit}`);
			return `${duration.value} ${translatedUnit}`;
		},
		async createTicket() {
			// TODO: server method
			this.tickets.unshift(this.ticket)
			// TODO: for now we generate a temporary id to avoid double addition. How will we handle the id-s in the future?
			this.ticket.sys.id = Math.random().toString(36).substr(2, 9)
			console.log('this.tickets', this.tickets)
		},
		async updateTicket() {
			// TODO: server method
			const index = this.tickets.findIndex(
				(ticket) => ticket.sys.id === this.ticket.sys.id
			)
			this.tickets[index] = this.ticket
		},
	},
}
</script>

<style scoped></style>
