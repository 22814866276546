<template>
	<div class="NavList">
		<LoadingSpinner v-if="loading" />
		<div v-for="item of items" :key="item.sys.id"
			:class="{ item: true, selected: value && value.sys.id == item.sys.id }"
			@click="$emit('update:modelValue', item)"
		>
			<slot :item="item"></slot>
			{{ item.fields.title.de }}
			<v-icon style="float: right;">mdi-chevron-right</v-icon>
		</div>
	</div>
</template>

<script>
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'

export default {
	name: 'NavList',
	components: { LoadingSpinner },
	props: {
		modelValue: Object, // Contentful-style-object
		items: Array, // array of Contentful-style-objects
		loading: Boolean,
	},
	data: () => ({
		selectedItem: null,
		model: {},
	}),
	watch: {
		modelValue(n) { this.model = n },
		model(n) { this.$emit('update:modelValue', n) }
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style scoped>
.NavList { width: 270px; overflow-y: auto; border-right: 1px solid #eee; }
.item { padding: 15px 0 15px 10px; border-bottom: 1px solid #eee; cursor: pointer; }
.item:hover { background-color: aliceblue; color: black; }
.selected { background-color: dodgerblue; color: white; }
.item.selected:hover { background-color: deepskyblue; color: white; }
</style>

<style>
.NavList input[type=checkbox] { width: 15px; height: 15px; margin-right: 3px; vertical-align: -0.1em; }
</style>