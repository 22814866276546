<template>
	<div class="DropdownField" :class="{ error }">
		<select class="input" v-model="model" @focus="onFocus" @blur="onBlur">
			<option :value="null">Choose a value</option>
			<option v-for="value of allowedValues" :key="value" :value="value?.id ?? value">{{ value?.label ?? value }}</option>
		</select>
		<svg class="icon-dchevron" viewBox="0 0 24 24"><path d="M5.29 8.29C5.68 7.90 6.31 7.90 6.70 8.29L12 13.58L17.29 8.29C17.68 7.90 18.31 7.90 18.70 8.29C19.09 8.68 19.09 9.31 18.70 9.70L12.70 15.70C12.31 16.09 11.68 16.09 11.29 15.70L5.29 9.70C4.90 9.31 4.90 8.68 5.29 8.29Z"></path></svg>
	</div>
</template>

<script lang="ts">
import { field } from './FieldMixin.js'
import countries from '../../json/countries.js'

// TODO: validation!

export default {
	name: 'DropdownField',
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
		options: Array,
	},
	data: () => ({
		model: null,
	}),
	computed: {
		allowedValues() {
			if (this.validations?.in?.[0] == '<COUNTRIES>')
				return countries
					// TODO: ui locale instead of 'de'
					.map(({ code, name, priority }) => ({ id: code, label: name['de'], priority }))
					.sort((a, b) => (a.priority == b.priority ? a.label.localeCompare(b.label) : a.priority > b.priority) ? 1 : -1)
			return this.options ?? this.field.validations?.[0]?.in ?? []
		},
	},
}
</script>

<style scoped>
.DropdownField { position: relative; }
select { appearance: none; }
.icon-dchevron { width: 1em; height: 1em; font-size: 16px; position: absolute; right: 10px; top: 12px; }
</style>
