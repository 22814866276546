<template>
	<Step id="tickets" icon="mdi-ticket-outline">
		<div class="todo">https://overflow.io/s/CDRGOW7X/?node=a4a3823a</div>
		<Section id="tickets" open>
			<TicketsInfo2 ref="ticketsSection" />
		</Section>

		<Section id="additionalSettings" open>
			<AdditionalSettings ref="additionalSettingsSection" />
		</Section>
	</Step>
</template>

<script>
import Step from './Step.vue'
import Section from './Section.vue'
import FieldSet from './FieldSet.vue'
import Field from '../../../components/fields/Field.vue'
import TicketsInfo2 from './TicketsInfo2.vue'
import AdditionalSettings from './AdditionalSettings.vue'

export default {
	components: { Section, FieldSet, Field, Step, TicketsInfo2, AdditionalSettings },
	props: {
		modelValue: Object,
		salesChannels: Array,
		products: Array,
	},
	data() {
		return {
		}
	},
}
</script>

<style scoped>
</style>
