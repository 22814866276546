<template>
	<Application :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail">
		<template #navbar>
			<Navbar :fetchData="getPackages" :onSearch="onSearch" :onClear="onSearch">
				<template #add-button>
					<!-- Add New Package Dialog -->
					<AddNewTypeDialog ref="addNewPackageDialogRef" :showLoader="showLoader" type="package" />
					<v-btn class="gradientButton" size="small" elevation="0" data-cy="addPackage"
						@click="() => $refs.addNewPackageDialogRef.getServiceTypes()">
						<v-icon>mdi-plus</v-icon>
						<span class="d-none d-lg-inline">{{ $t('text.addPackage') }}</span>
					</v-btn>
				</template>
			</Navbar>
		</template>

		<PackagesTable v-if="packages.length > 0" v-model:items="packages" :total="total" :limit="limit" :offset="offset"
			@update:limit="updateLimit" @update:offset="updateOffset" />
	</Application>
</template>

<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Common from '@/mixins/Common.vue'
import Application from '@/views/applications/Application.vue'
import Navbar from '@/views/applications/packageDesigner/Navbar.vue'
import PackagesTable from '@/views/applications/packageDesigner/tables/PackagesTable.vue'
import AddNewTypeDialog from '@/views/applications/serviceDesigner/AddNewTypeDialog.vue'

export default {
	name: 'PackagesView',
	components: { Application, Navbar, PackagesTable, AddNewTypeDialog },
	mixins: [Common],
	data() {
		return {
			packages: [],
			loading: false,
			errorTitle: '',
			errorDetail: '',
			limit: 10,
			offset: this.$store.state.offset,
			total: 0,
		}
	},
	methods: {
		showLoader(value = false) {
			this.loading = value
		},
		constructPayload(data) {
			return omitBy(data, value => isEmpty(value?.toString()))
		},
		async getPackages({ total } = { total: false }) {
			try {
				this.showLoader(true)
				const clientId = this.$store.state.selectedClient.sys.id
				const searchString = this.$store.state.searchString
				const payload = this.constructPayload({ limit: this.limit, offset: Math.floor(this.offset / this.limit), searchString })

				const promises = [this.$httpPost(`/packageTravel/packages?clientId=${clientId}`, payload)]

				if (total) promises.push(this.$httpPost(`/packageTravel/packagesCount?clientId=${clientId}`, this.constructPayload({ searchString })))

				const [{ packages }, totalCount] = await Promise.all(promises)

				if (total) this.total = totalCount?.packages

				this.packages = packages.map(item => ({ ...item.fields, id: item?.sys?.id }))
			} catch (error: any) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.showLoader(false)
			}
		},
		updateLimit(limit) {
			this.offset = 0
			this.limit = limit
			this.getPackages()
		},
		updateOffset(offset) {
			this.offset = offset
			this.getPackages()
		},
		onSearch() {
			this.offset = 0
		},
	},
	created() {
		FreshdeskWidget.methods.prefill('MyServices')
	},
	async mounted() {
		this.getPackages({ total: true })
	},
}
</script>
