<template>
	<Disclosure :title="$t('text.contactInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
		:lock="!userMayEditFields" data-cy="contactInfo" ref="SECTION_contactInfo"
	>
		<v-radio-group v-model="modelValue.fields.copyContactInfos.de" col
			@change="$emit('change')"
		>
			<v-radio data-cy="copyContactInfo" style="border-bottom: 1px solid #ddd;" :label="$t('text.copyContactInfos')" :value="true" />
			<v-radio :label="$t('text.newContactInfos')" :value="false" />
		</v-radio-group>
		<div v-if="!modelValue.fields.copyContactInfos.de">
			<v-form :disabled="modelValue.fields.copyContactInfos.de" v-for="contactInfoGroup in modelValue.fields.contactInfoGroups.de"
				:key="contactInfoGroup.sys.id"
			>
				<div v-if="hasField('contactInfo','contactInfoAddress', modelValue)" class="field left-border">
					<ContactAddress
						ref="ContactAddress"
						:title="$t('text.serviceAddress')"
						:contactAddress="contactInfoAddress.fields.contactAddresses.de[0]"
						:location="modelValue.fields.location.de"
						@change="$emit('change')"
					/>
				</div>
				<div v-if="hasField('contactInfo','contactInfoPhone', modelValue) || hasField('contactInfo','contactInfoEmail', modelValue)"
					class="field left-border"
				>
					<div style="display: flex;">
						<v-col v-if="hasField('contactInfo','contactInfoPhone', modelValue)" style="padding:0px 10px 0px 0px">
							<v-label>{{ $t('text.contactPhoneNumber') }}</v-label>
							<v-text-field v-if="contactInfoPhone" variant="outlined" density="compact" persistent-hint :hint="phoneFormat"
								v-model="contactInfoPhone.fields.contactDetail.de"
								@change="$emit('change')"
							/>
						</v-col>
						<v-col v-if="hasField('contactInfo','contactInfoEmail', modelValue)" style="padding:0">
							<v-label>{{ $t('text.contactEmailAddress') }}</v-label>
							<v-text-field variant="outlined" density="compact"
								v-if="contactInfoEmail"
								v-model="contactInfoEmail.fields.contactDetail.de"
								:error-messages="contactPersonEmailErrors"
								:hide-details="contactPersonEmailErrors.length===0"
								@update:modelValue="validateContactPersonEmail"
								@change="$emit('change')"
							/>
						</v-col>
					</div>
				</div>
			</v-form>
		</div>
		<div v-if="hasField('contactInfo','notificationEmails', modelValue)" class="field left-border mt-3">
			<v-label>{{ $t('text.notificationEmailAddress') }}</v-label>

			<p class="helpText">{{ $t('text.notificationEmailHelp') }}</p>

			<div
				v-for="(contactEmail, contactEmailIndex) in modelValue.fields.notificationEmails.de"
				style="display: flex;"
				:class="!contactEmailIndex ? '' : 'mt-2'"
				:key="contactEmailIndex"
			>
				<v-text-field class="pr-2 mt-0" variant="outlined" density="compact"
					:error-messages="validateNotificationEmail(contactEmail)"
					v-model="modelValue.fields.notificationEmails.de[contactEmailIndex]"
					@change="$emit('change')"
				/>

				<v-btn class="gradientButton" elevation="0"
					@click="modelValue.fields.notificationEmails.de.splice(contactEmailIndex, 1); $emit('change')"
				>
					<v-icon size="24px" v-if="modelValue.fields.notificationEmails.de.length == 1">mdi-delete</v-icon>
					<v-icon size="24px" v-else>mdi-close</v-icon>
				</v-btn>
			</div>
			<v-btn id="btnAddEmailRow" class="gradientButton mt-2" elevation="0"
				style="height:40px;font-size:12pt;margin-right:10px"
				@click="modelValue.fields.notificationEmails.de.push(''); $emit('change')"
			>
				<v-icon size="24px">mdi-plus</v-icon>
				{{ $t('text.addAnotherNotificationEmail') }}
			</v-btn>
		</div>
		<Field v-if="hasField('contactInfo','meetingPoint', modelValue)" typeName="Service" fieldName="meetingPoint" dataCy="meetingPoint"
			v-model="modelValue.fields.meetingPoint" @update:modelValue="$emit('change')" />
		<Field v-if="hasField('contactInfo','postPurchaseInfo', modelValue) && modelValue.fields.vouchers?.de?.[0]?.fields" typeName="Service"
			fieldName="postPurchaseInfo" dataCy="postPurchaseInfo" v-model="modelValue.fields.vouchers.de[0].fields.generalInfo" @update:modelValue="$emit('change')" />
	</Disclosure>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import ContactAddress from '@/components/common/ContactAddress.vue'
import Field from '@/components/fields/Field.vue'
import { ekContactAddress } from '@/components/common/ContactAddress.vue'

export default {
	name: 'ContactInfo',
	components: { Disclosure, ContactAddress, Field },
	mixins: [ Common ],
	props: {
		updateModel: Boolean,
		serviceProvider: Object,
		status: String,
		modelValue: Object, // product
	},
	data() { return {
		sectionMessage: { error: false, message: '' },
		countries: this.getCountries(),
		notificationEmails: { de: [''] },
		contactPersonEmailErrors: [],
		initialContactInfos: null,
	}},
	computed: {
		phoneFormat() {
			if (!this.$store.state.userSpecificGerman) return '(+49 79 123 4567)'
			if (this.$store.state.userSpecificGerman === 'ch') return '(+41 79 123 4567)'
			if (this.$store.state.userSpecificGerman === 'at') return '(+43 79 123 4567)'
			if (this.$store.state.userSpecificGerman === 'de') return '(+49 79 123 4567)'
			return '(+49 79 123 4567)'
		},
		contactInfos() {
			return this.modelValue.fields.contactInfoGroups?.de?.[0]?.fields?.contactInfos?.de
		},
		contactInfoPhone() {
			return this.findContactInfo(this.contactInfos, 'Phone')
		},
		contactInfoEmail() {
			return this.findContactInfo(this.contactInfos, 'E-Mail')
		},
		contactInfoWebsite() {
			return this.findContactInfo(this.contactInfos, 'Website')
		},
		contactInfoName() {
			return this.findContactInfo(this.contactInfos, 'Name')
		},
		contactInfoAddress() {
			return this.findContactInfo(this.contactInfos, 'Address')
		},
		// TODO: do we really need this?
		contactInfoAddressCountryName() {
			// TODO: why is it not country[.de].name?
			return this.contactInfoAddress.fields.contactAddresses.de[0].fields?.country?.name?.de
		},
		addressCountry() {
			return this.countries.find(country => country.name.de === this.contactInfoAddressCountryName)
		},
	},
	watch: {
		modelValue(v) {
			this.fixModel(v)
		},
		'modelValue.fields.copyContactInfos.de'(v) {
			if (v) {
				// reference the contact infos of the SP
				this.modelValue.fields.contactInfoGroups.de[0].fields.contactInfos = this.serviceProvider.fields.contactInfos ?? this.getNewContactInfos()
			}
			else {
				// reuse the initial CIs (this could also be a new model)
				this.modelValue.fields.contactInfoGroups.de[0].fields.contactInfos = this.initialContactInfos
			}
		},
	},
	methods: {
		findContactInfo(contactInfos, type) {
			if (!contactInfos?.length) return
			return contactInfos?.find(ci => ci?.fields?.contactInfoType?.de?.fields?.type?.de == type)
		},
		validateAllFields() {
			// Reset the section error message
			this.resetSectionError(this.sectionMessage)

// TODO: should we omit validation if copy is set? (because then the user cant fix the errors..
			// Validate the contact address
			const ekAddress = ekContactAddress(this.contactInfoAddress?.fields?.contactAddresses?.de?.[0])

			// Validate the contact person email
			const isEmailValid = this.validateContactPersonEmail()

			// Validate the notification emails
			const invalidNotificationEmails = this.modelValue.fields.notificationEmails?.de?.map(email => this.validateNotificationEmail(email)).filter(el => el && el.length > 0)

			// Check if any fields are invalid
			if (ekAddress || !isEmailValid || invalidNotificationEmails?.length > 0) {
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
				return false
			}

			return true
		},
		validateContactPersonEmail() {
			let isValid = true
			this.contactPersonEmailErrors = []
			if (this.contactInfoEmail?.fields?.contactDetail?.de && !this.validateEmail(this.contactInfoEmail.fields.contactDetail.de)) {
				isValid = false
				this.contactPersonEmailErrors.push(this.$t('text.invalidEmail'))
			}
			return isValid
		},
		validateEmail(email) {
			let re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
			return re.test(String(email).toLowerCase());
		},
		validateNotificationEmail(email) {
			let errors = []
			if (email && email.length > 0 && !this.validateEmail(email)) {
				errors.push(this.$t('text.invalidEmail'))
			}
			return errors
		},
		getNewContactInfos() {
			return { de: [
				{ sys: { id: '' }, fields: { contactInfoType: { de: { fields: { type: { de: 'Phone' }}}}, contactDetail: { de: '' } } },
				{ sys: { id: '' }, fields: { contactInfoType: { de: { fields: { type: { de: 'E-Mail' }}}}, contactDetail: { de: '' } } },
				{ sys: { id: '' }, fields: { contactInfoType: { de: { fields: { type: { de: 'Website' }}}}, contactDetail: { de: '' } } },
				{ sys: { id: '' }, fields: { contactInfoType: { de: { fields: { type: { de: 'Name' }}}}, contactDetail: { de: '' } } },
				{
					sys: { id: '' },
					fields: {
						contactInfoType: { de: { fields: { type: { de: 'Address' } } } },
						contactAddresses: {
							de: [{
								sys: { id: '' },
								fields: {
									streetAddress: { de: '' },
									zipCode: { de: '' },
									city: { de: '' },
									country: { de: null },
								},
							}]
						},
					},
				},
			]}
		},
		fixModel(v) {
			if (!v) return
			if (!v.fields.contactInfoGroups?.de?.[0]?.fields?.contactInfos?.de?.[0]) {
				v.fields.contactInfoGroups = { de: [{
					sys: { id: '' },
					fields: {
						contactInfos: this.getNewContactInfos(),
					},
				}] }
			}
			if (!v.fields.copyContactInfos) {
				v.fields.copyContactInfos = { de: false }
			}
			if (!v.fields.vouchers?.de?.[0]?.fields) {
				v.fields.vouchers = { de: [ { sys: { id: 'id_v_0' }, fields: { generalInfo: { de: '' } } } ] }
			}
		},
	},
	async mounted() {
		this.fixModel(this.modelValue)

		// we may have inconsistent data where we have copy == false, but the contact infos actually are the service provider's
		if (this.modelValue.fields.contactInfoGroups.de[0]?.fields?.contactInfos?.de?.[0]?.sys?.id === this.serviceProvider.fields.contactInfos?.de?.[0]?.sys?.id) {
			this.modelValue.fields.copyContactInfos.de = true
		}

		this.initialContactInfos = this.modelValue.fields.copyContactInfos.de
			? this.getNewContactInfos()
			: this.modelValue.fields.contactInfoGroups?.de?.[0]?.fields?.contactInfos ?? this.getNewContactInfos()

		// watcher of copyContactInfos - it has to be done here too since initially when copying everything is empty
		if (this.modelValue.fields.copyContactInfos.de) {
			// reference the contact infos of the SP
			this.modelValue.fields.contactInfoGroups.de[0].fields.contactInfos = this.serviceProvider.fields.contactInfos ?? this.getNewContactInfos()
		}
		else {
			// reuse the initial CIs (this could also be a new model)
			this.modelValue.fields.contactInfoGroups.de[0].fields.contactInfos = this.initialContactInfos
		}
	},
}
</script>