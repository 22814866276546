<template>
	<div class="Step" v-show="visible" :id="id">
		<slot></slot>
	</div>
</template>

<script>
import eventBus from '@/utils/eventBus.js'
import ChildErrorReceiver from './ChildErrorReceiver.vue'

export default {
	name: 'Step',
	mixins: [ ChildErrorReceiver ],
	props: {
		id: String,
		icon: { type: String, default: 'mdi-wallet-membership' },
	},
	data: () => ({
		visible: false,
		status: 'incomplete',
	}),
	watch: {
		childErrorMessage(n) {
			// TODO: how to set the complete status? in validation also?
			this.status = n ? 'error' : 'incomplete'
		},
	},
	methods: {
		register() {
			eventBus.$emit('step-register', this)
		},
		show(id) {
			this.visible = id == this.id
			if (this.visible)
				window.scrollTo({ top: 0, behavior: 'smooth' })
		},
	},
	mounted() {
		eventBus.$on('step-show', this.show)
		this.register()
		eventBus.$on('step-cfr', this.register)
	},
	unmounted() {
		eventBus.$off('step-show', this.show)
		eventBus.$off('step-cfr', this.register)
	},
}
</script>

<style scoped>
.Step { margin-top: 60px; }
</style>