<template>
	<v-menu>
		<template v-slot:activator="{ props }">
			<v-btn class="dotsIcon" variant="plain" size="large" density="compact" icon="mdi-dots-horizontal-circle-outline" color="#0ba7e1" v-bind="props" />
		</template>

		<v-list density="compact">
			<v-list-item v-for="(item, i) in items" :key="i" @click="handleOptionClick(item)">
				<div class="item">
					<v-icon size="small">{{ $t(item.icon) }}</v-icon>
					<v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
				</div>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	name: 'PopoverMenu',
	props: {
		items: Array,
	},
	methods: {
		handleOptionClick(item) {
			console.log('handleOptionClick', item.action)
			this.$emit('click', item.action)
		},
	},
}
</script>

<style scoped>
.item {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
}
.dotsIcon:hover {
	transform: scale(1.2);
}
</style>