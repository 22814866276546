<template>
	<FieldWrap style="margin-top: 16px;" :title="title">
		<v-text-field variant="outlined" density="compact" hide-details class="vfield"
			:placeholder="title"
			:error-messages="errorMessages"
            :suffix="suffix"
			@update:modelValue="$emit('input', $event)"
			@blur="$emit('blur', $event)"
			v-model="model"
		/>
	</FieldWrap>
</template>

<script>
import FieldWrap from './FieldWrap.vue'

export default {
	name: 'TextField',
	components: { FieldWrap },
	props: {
		title: String,
		errorMessages: Array,
		modelValue: String,
		suffix: String
	},
	data: () => ({
		model: null,
	}),
	watch: {
		modelValue(v) { this.model = v },
		model(v) { this.$emit('update:modelValue', v) },
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style scoped>
div.vfield .v-input { margin-top: 3px; }
</style>