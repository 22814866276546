import moment from 'moment'

const HttpUtil = {
	install(app) {
		app.config.globalProperties.$httpFetch = async function(method, url, body, options) {
			try {
				const user = this.$store.state.loggedInUser
				const client = this.$store.state.selectedClient
				const apps = this.$store.state.spApplications ?? []
				const app = this.$store.state.selectedComponent

				let appId = app?.sys?.id ?? ''
				
				// Get Application Id for Service Designer if the current selectedComponent = 'Manage Services'
				if (app?.fields?.title?.en === 'Manage Services') {
					appId = apps.find(app => app.fields?.title?.en === 'Service Designer')?.sys?.id
				}
	
				// Get Application Id for Business Profile if the current selectedComponent = 'Service Providers'
				if (app?.fields?.title?.en === 'Service Providers') {
					appId = apps.find(app => app.fields?.title?.en === 'Business Profile')?.sys?.id
				}
	
				if (url.indexOf('/') == 0 && url.indexOf('/api') == -1) url = '/api' + url
				let response = await fetch(url, {
					method: method,
					headers: {
						'Authorization': 'Bearer ' + (options?.token ?? user?.kc_token),
						'Accept': 'application/json, text/plain, */*',
						'Content-Type': 'application/json',
						'mys-user-id': user?.sys?.id ?? '',
						'mys-user-type': user?.fields?.type?.de ?? '',
						'mys-client-id': client?.sys?.id ?? '',
						'mys-app-id': appId,
						'mys-tx-id': moment().valueOf()
					},
					body:
						body === undefined ? body :
						typeof body == 'string' ? body :
						JSON.stringify(body),
				})
	
				if (response.status === 204) return null
				if (response.status === 401 || response.status === 403) { 
					this.$root.app.showLogin()
				}
	
				let result
				try {
					result = await response.clone().json()
				}
				catch (e) {
					// TODO: certain bodies may be allowed, like OK
					if (response.status === 200) {
						result = {}
					} else {
						throw new Error('could not read response: ' + method + ' ' + url, e)
					}
				}
	
				if (result.isError && result.status >= 400) throw result
				if (response.status >= 400) throw await response.text()
				return result
			}
			catch (e) {
				let errorObject = typeof e === 'string' ? JSON.parse(e) : e
				
				if (errorObject?.status === 401 && this.$route.path !== '/') {
					// TODO: refresh the token instead?
					// TODO: make a message instead with a logot button!
					//       otherwise a programming error on the api may lead to a logout
					//       so the user still has the screen to make a grab or sth.
					this.$root.app.showLogin()
					return
				}
				// TODO: handle other codes specifically as well?
				// 403 -> you are not allowed here
				throw errorObject
			}
		}

		app.config.globalProperties.$httpGet = async function(url, query, options) {
			if (!query) query = {}
			url += url.indexOf('?') < 0 ? '?' : '&'
			return await this.$httpFetch('GET', url + new URLSearchParams(query).toString(), undefined, options)
		}

		app.config.globalProperties.$httpPut = async function(url, object, options) {
			return await this.$httpFetch('PUT', url, object, options)
		}

		app.config.globalProperties.$httpPost = async function(url, object, options) {
			return await this.$httpFetch('POST', url, object, options)
		}

		app.config.globalProperties.$httpDelete = async function(url, object, options) {
			return await this.$httpFetch('DELETE', url, object, options)
		}
	},
}

export default HttpUtil