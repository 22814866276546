<template>
	<div class="container">
		<div class="sidebar">
			<div v-for="(item, index) in modelValue" :key="index"
				:ref="`sidebar_${item.sys.id}`"
				class="sidebar-options"
				:class="{ highlighted: sidebarItemActive?.sys.id == item.sys.id}"
				@click="clickSidebar(item)"
				>
				{{ item.fields.name[serviceLocale] ?? item.fields.name.de }}
			</div>
		</div>
		<div class="content">
			<div class="expandable-section" v-for="(item, index) in modelValue" :key="index" :ref="item.sys.id" @mouseover="hoverItem(item)"
			>
			<v-expansion-panels v-model="panelModel[item.sys.id]">
				<v-expansion-panel>
					<v-expansion-panel-title class="panel-header">
						<template v-slot:actions />
						<template v-slot="{ expanded }">
							<slot name="itemHead" :item="item" :expanded="expanded" />
						</template>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<slot name="itemBody" :item="item" />
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
		</div>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	mixins: [Common],
	props: {
		modelValue: Array,
		optionalFields: Array,
		typeName: String,
		showCopyToReturnJourney: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			panelModel: {},
			model: null,
			sidebarItemActive: null,
		}
	},
	computed: {

	},
	watch: {
		model(n) { this.$emit('update:modelValue', n) },
		modelValue(n) {
			this.model = n
			for (const item of this.model ?? []) {
				this.panelModel[item.sys.id] = 0
			}
		},
	},
	methods: {
		clickSidebar(item) {
			console.log('clickSidebar', item)
			const div = this.$refs[item.sys.id]?.[0]
			div?.scrollIntoView?.({ behavior: 'smooth', block: 'start' })
			this.panelModel[item.sys.id] = 0
			this.sidebarItemActive = item
		},
		hoverItem(item) {
			this.sidebarItemActive = item
			const div = this.$refs[`sidebar_${item.sys.id}`]?.[0]
			div?.scrollIntoView?.({ behavior: 'smooth', block: 'start' })
		},
	},
	async mounted() {
		this.model = this.modelValue
		for (const item of this.model ?? []) {
			this.panelModel[item.sys.id] = 0
		}
		console.log('disclosure items model', this.model)
	},
};
</script>

<style scoped>
.container {
	display: flex;
}

.sidebar {
	position: fixed;
	top: 64px;
	bottom: 64px;
	width: 240px;
	background-color: #f4f4f4;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	overflow: auto;
	margin-left: -30px;
}

.content {
	flex-grow: 1;
	padding-left: 240px;
}

.sidebar-options {
	padding: 12px 24px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.sidebar-options:hover:not(.highlighted) {
	background-color: #e0e0e0;
}

.highlighted {
	background-color: #0ba7e1;
	color: white;
}

.panel-header {
	background-color: #f4f4f4;
}

.expandable-section {
	margin-top: 24px;
}

.expandIcon {
	display: flex;
	align-items: center;
	justify-content: left;
}

</style>

