<template>
	<div>
		<Disclosure :title="$t('text.contactPersonInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="contactPersonInfo" ref="SECTION_contactPersonInfo">
			<p class="subTitle" v-html="$t('text.contactPersonSubTitle')"/>
			<div class="field left-border">
				<v-label>{{$t('text.contactPersonName')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field variant="outlined" density="compact" required
					:hide-details="contactPersonNameErrors.length===0"
					:error-messages="contactPersonNameErrors"
					@update:modelValue="v$.contactInfoName.fields.contactDetail.de.$touch()"
					@blur="v$.contactInfoName.fields.contactDetail.de.$touch()"
					v-model.trim="contactInfoName.fields.contactDetail.de"
				/>
				<p class="helpText" v-html="$t('text.contactPersonNameDesc')"/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.contactPersonEmail')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field variant="outlined" density="compact" required
					:hide-details="contactPersonEmailErrors.length===0"
					:error-messages="contactPersonEmailErrors"
					@update:modelValue="v$.model.fields.contactPersonEmail.de.$touch()"
					@blur="v$.model.fields.contactPersonEmail.de.$touch()"
					v-model="model.fields.contactPersonEmail.de"
				/>
				<p class="helpText" v-html="$t('text.contactPersonEmailHelp')"/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.contactPersonPhone', { format: phoneFormat})}}</v-label>
				<v-text-field variant="outlined" density="compact" required
					placeholder=""
					hide-details
					v-model="model.fields.contactPersonPhone.de"
				/>
			</div>
			<div class="field left-border" v-if="userLoginEmail">
				<v-label>{{$t('text.loginInformation')}}</v-label>
				<v-text-field variant="outlined" density="compact" readonly hide-details
					v-model="userLoginEmail"
				/>
				<p class="helpText" v-html="$t('text.loginInformationDesc')"/>
			</div>

			<!-- Braze communication data -->
			<div class="field left-border" data-cy="notificationMailLanguageDiv" >
				<p class="subTitle" v-html="$t('text.communicationSettings')"/>
				<p class="helpText" v-html="$t('text.communicationSettingsHelp')"/>
				<br/>

				<v-label>{{$t('text.notificationLanguage')}}</v-label>
				<v-select variant="outlined" density="compact" required
					v-model="model.fields.language.de"
					:items="languages"
					:item-title="language => language?.name ? $t('text.' + language.name) : ''"
					item-value="code"
					data-cy="notificationMailLanguage"
				/>
				<p class="helpText" style="margin-top: -20px;" v-html="$t('text.notificationLanguageHelp')"/>
				<br/>

				<v-label>{{$t('text.communicationMail')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field variant="outlined" density="compact"
					v-model="model.fields.communicationMail.de"
					:hide-details="communicationMailErrors.length === 0"
					:error-messages="communicationMailErrors"
					@blur="validateCommunicationMailExists"
				/>
				<p class="helpText" v-html="$t('text.communicationMailHelp')"/>
			</div>
		</Disclosure>
	</div>
</template>

<script>
	import Common from '@/mixins/Common.vue'
	import Disclosure from '@/components/common/Disclosure.vue'
	import eventBus from '@/utils/eventBus.js'
	import { useVuelidate } from "@vuelidate/core"
	import { required, minLength, maxLength, email, sameAs } from "@vuelidate/validators"
	import isEqual from 'lodash/isEqual'
	
	export default {
	  name: "ContactPersonInfo",
	  components: {
		Disclosure
	  },
	  mixins: [Common],
	 
	  props: {
		serviceProvider: {
			type: Object,
			required: true,
		},
		updateModel: Boolean,
	},
	validations: {
		model: {
			fields: {
				contactPersonEmail: {
					de: { required, email, minLength: minLength(2), maxLength: maxLength(256) }
				},
			},
		},
		contactInfoName: {
			fields: {
				contactDetail: {
					de: { required, minLength: minLength(2), maxLength: maxLength(128) }
				}
			}
		},
	  },
	  setup() {
	  	return {
	  		v$: useVuelidate()
	  	}
	  },
	  data() {
		return {
			model: {},
			initData: {},
			sectionMessage: {
				error: false,
				message: ''
			},
			userLoginEmail: '',
			languages: [],
			communicationMailIsUnique: true,
			contactInfoName: {sys: {id:'id_1'}, fields:{contactInfoType: {de:{fields:{type:{de:'Name'}}}},contactDetail:{de:''}}},contactInfoName: {sys: {id:'id_1'}, fields:{contactInfoType: {de:{fields:{type:{de:'Name'}}}},contactDetail:{de:''}}},
		}
	},
	computed: {
		contactPersonNameErrors () {
			const errors = []
			const { $dirty, required, minLength, maxLength } = this.v$.contactInfoName.fields.contactDetail.de

			if (!$dirty) return errors

			if (required.$invalid) errors.push(this.$t('text.nameRequired'))
			if (minLength.$invalid) errors.push(this.$t('text.nameMinChars'))
			if (maxLength.$invalid) errors.push(this.$t('text.nameMaxChars'))

			return errors
		},
		contactPersonEmailErrors () {
			const errors = []
			const { fields } = this.v$.model

			if (!fields?.contactPersonEmail?.de.$dirty) return errors

			const { email, required, maxLength, minLength } = fields.contactPersonEmail.de

			if (email.$invalid) errors.push(this.$t('text.emailInvalid'))
			if (required.$invalid) errors.push(this.$t('text.emailRequired'))
			if (minLength.$invalid) errors.push(this.$t('text.emailMinChars'))
			if (maxLength.$invalid) errors.push(this.$t('text.emailMaxChars'))

			return errors
		},
		communicationMailErrors() {
			let errors = []
			// we allow an empty communication mail
			if (!this.model.fields?.communicationMail?.de) return errors
			if (!this.validEmail(this.model.fields.communicationMail.de)) errors.push(this.$t('text.emailInvalid'))
			!this.communicationMailIsUnique && errors.push(this.$t('text.communicationEmailExistsError'))
			return errors
		}
	},
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.setContactPersonInfoDetails()
			this.setInitData()
		},
	},
	methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))
			this.initData = {
				contactPersonEmail: initModel.fields.contactPersonEmail,
				contactPersonPhone: initModel.fields.contactPersonPhone,
				language: initModel.fields.language,
				contactInfoName: JSON.parse(JSON.stringify(this.contactInfoName)),
				communicationMail: this.model.fields.communicationMail
			}
		},
		sendData() {
			eventBus.$emit('contact-info-name-changed', this.contactInfoName)
			const data = {
				contactPersonEmail: this.model.fields.contactPersonEmail,
				contactPersonPhone: this.model.fields.contactPersonPhone,
				language: this.model.fields.language,
				contactInfoName: this.contactInfoName,
				communicationMail: this.model.fields.communicationMail
			}

			data.changed = !isEqual(data, this.initData)

			return data
		},
		async validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)

			this.v$.contactInfoName.fields.contactDetail.de.$touch()
			this.v$.model.fields.contactPersonEmail.de.$touch()

			if (this.contactPersonNameErrors.length ||
				this.contactPersonEmailErrors.length) {

				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			}

			// we only check if the email is unique if its also set
			if (this.model.fields.communicationMail.de) {
				await this.validateCommunicationMailExists()
			}
			else {
				this.communicationMailIsUnique = true
			}

			if (this.communicationMailErrors.length > 0) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.communicationMailErrors[0])
			}

			return allFieldsAreValid
		},
		async validateCommunicationMailExists() {
			// we dont check if the email is unique if its empty
			if (!this.model.fields.communicationMail.de) return

			const email = this.model.fields.communicationMail.de
			const res = await this.$httpGet(`/check-communication-email?email=${email}&spId=${this.model.sys.id}`)
			if (res?.error) console.error('Error checking communication mail!', res)
			this.communicationMailIsUnique = !res.emailExists
		},
		setContactPersonInfoDetails() {
			if (this.$store.state.selectedServiceProvider?.sys?.id) {
				this.userLoginEmail = this.$store.state.loggedInUser.fields.email.de
				if (this.$store.state.loggedInUser.sys.id !== this.$store.state.selectedServiceProvider.sys.id) {
					this.userLoginEmail = this.$store.state.selectedServiceProvider.fields?.mainUserAccount?.email
				}
				else {
					// main user account stuff
				}
			}
			if (this.model.fields.mainUserAccount?.fields?.email) {
				this.userLoginEmail = this.model.fields.mainUserAccount.fields.email.de
			}

			const sp = this.model.fields

			for (let contactInfo of this.model.fields.contactInfos?.de ?? []) {
				const type = contactInfo.fields.contactInfoType.de.fields.type.de
				if (type === "Name") {
					this.contactInfoName = contactInfo ?? { de: '' }
				} 
			}

			// We need a reference from internal data to the model data
			if (sp.contactInfos) {
				sp.contactInfos.de = [
					this.contactInfoName
				]
			}
		},
	},
	created() {
		this.model = this.valueToModel(this.serviceProvider)
		this.setContactPersonInfoDetails()
		this.setInitData()
	},
	mounted() {
		// assign locale codes and locale names to this.languages
		this.languages = this.$store.state.locales.map(locale => ({
			code: locale.code,
			name: locale.name
		}))
	},
}
</script>