<template>
	<div v-if="hasFeature" class="container">
		<div v-if="isCustom" class="badge">
			<p>{{ featureValue }}</p>
		</div>
		<slot></slot>
	</div>
</template>

<script lang="ts">
import Common from '@/mixins/Common.vue'

export default {
	name: 'IfFeatureFlag',
	components: {},
	props: {
		flag: String,
	},
	mixins: [Common],
	computed: {
		featureValue() {
			return this.$store.state.featureFlags[this.flag]
		},
		hasFeature() {
			if (this.isCustom) return this.isOperatorFlag ? this.isOperator : true

			return this.featureValue
		},
		isCustom() {
			return !!this.featureValue?.length
		},
		isOperatorFlag() {
			return this.featureValue.toLowerCase?.().startsWith('op-')
		},
		isOperator() {
			return this.$store.state.loggedInUser?.fields?.type?.de === 'operator'
		},
	},
}
</script>

<style scoped lang="scss">
.container {
	position: relative;
}

.badge {
	position: absolute;
	top: -12px;
	right: 12px;
	background-color: #ff8000;
	padding: 2px 10px;
	border-radius: 8px;
	z-index: 2;

	p {
		color: white;
		font-weight: bold;
	}
}
</style>