<template>
	<Application :loading="isLoading" :full-page="fullPage" v-model:error-title="errorTitle" :error-detail="errorDetail">
		<template #navbar>
			<div class="search-container">
				<div>
					<v-menu location="start" origin="top right" :close-on-content-click="false" v-model="isSearchOpened">
						<template #activator="{ props }">
							<v-row align="center" justify="end" v-bind="props">
								<v-btn class="gradientButton" elevation="0" style="font-size: 12pt; margin-top: 15px; margin-left: 5px; border: 0 !important;">
									<v-icon>mdi-magnify</v-icon>
									<span class="d-none d-md-inline" v-if="searchType===''">{{ $t('text.search') }}</span>
									<span class="d-none d-md-inline" v-if="searchType!==''">{{ $t('text.'+searchType) }}</span>
									<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
								</v-btn>
								<div v-if="filter" class="dot-container stack-top"><span class="dot"></span></div>
							</v-row>
						</template>
						<v-list>
							<v-list-item v-for="searchType of searchTypes" :key="searchType">
								<div style="width:100%" @click="setSelectedSearchType(searchType)">{{$t('text.'+searchType)}}</div>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>

				<v-text-field flat variant="solo" density="compact" clearable hide-details
					data-cy="search"
					:placeholder="$t('text.searchOrders')"
					v-model="searchString"
					@keyup.enter="getOrders()"
					@click:clear="clearSearch()"
				/>
			</div>

          <!-- Filter -->
          <FilterMenu v-model="filter" @clear="clearFilter" @apply="applyFilter">
            <FilterFieldWrap>
              <div>{{$t('text.status')}}</div>
              <v-select
                :placeholder="$t('text.allLabel')" 
                variant="outlined" 
                density="compact"
                v-model="selectedStatuses"
                :items="statuses"
                :item-title="item => $t('text.' + item.id)"
                :item-value="item => item.id"
                multiple
                chips
                hide-details
              >
              <template #selection="{ item }">
                  <v-chip :color="item.color" dark>{{$t('text.' + item.id)}}</v-chip>
                </template>
              </v-select>
            </FilterFieldWrap>

            <FilterFieldWrap>
              <div>{{$t('text.eventDate')}}</div>
              <div style="display: flex;">
                <v-btn class="gradientButton" filled density="compact" solo elevation="0" style="width:48%;margin-right:4%" @click="toggleDatePicker('from')">{{validFromText}}</v-btn>
                <v-btn class="gradientButton" filled density="compact" solo elevation="0" style="width:48%" @click="toggleDatePicker('to')">{{validToText}}</v-btn>
              </div>

                <v-date-picker
                  v-if="showFromDate"
                  v-model="eventFromDate"
                  color="green-lighten-1"
                  @update:modelValue="resetDates()"
                  hide-actions
                />

                <v-date-picker
                  v-if="showToDate"
                  v-model="eventToDate"
                  color="green-lighten-1"
                  :min="eventFromDate"
                  @update:modelValue="showToDate=false"
                  hide-actions
                />
            </FilterFieldWrap>
          </FilterMenu>
		</template>

		<v-card class="tableCard">
			<v-data-table fixed-header
				:headers="headers"
				:items="orders"
				item-key="subId"
				:items-per-page="limit"
				:mobile-breakpoint="850"
				:item-value="item => item"
			>
				<template v-slot:item="{ item }">
					<tr @click="showOrderDetail(item)">
						<td>{{ item.order_id }}</td>
						<td>{{ formatDate(item.order_date) }}</td>
						<td>{{ item.client_name }}</td>
						<td>{{ item.store?.name }}</td>
						<td>{{ item.customer_name }}</td>
						<td align="center">{{ item.item_count }}</td>
						<td align="left">{{ formatPrice(item.order_total) }}</td>
						<td align="center">
							<v-chip :color="getStatusColor(item.status)" dark>{{ getStatusLabel(item.status) }}</v-chip>
						</td>
					</tr>
				</template>
				<template #bottom>
					<TablePaginator v-model="offset" :total="ordersCount" :limit="limit" :results="orders" @update:modelValue="getOrders()" />
				</template>
			</v-data-table>
		</v-card>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'

import TablePaginator from '@/components/common/TablePaginator.vue'
import FilterMenu from '@/components/common/FilterMenu.vue'
import FilterFieldWrap from '@/components/common/FilterFieldWrap.vue'
import Application from '../Application.vue'
import { DATE_FORMATS } from '@/constants'
import Common from "@/mixins/Common.vue";

export default {
	name: 'Orders',
	components: { Loading, TablePaginator, FilterMenu, FilterFieldWrap, Application },
	mixins: [Common],
	data() { return {
		isLoading: false,
		fullPage: true,
		errorTitle: '',
		errorDetail: '',
		searchString: '',

		ordersCount: 0,
		isSearchOpened: false,
		statuses: [
			{ id: 'Ordered', color: 'green' },
			{ id: 'Canceled', color: 'red' },
		],
		selectedStatuses: this.filter ? this.filter.statuses : [],
		showFromDate: false,
		showToDate: false,
		eventFromDate: this.filter ? this.filter.eventFromDate : null,
		eventToDate: this.filter ? this.filter.eventToDate : null,

		filter: null,

		searchSubmitted: false,
		offset: 0,
		limit: 15,

		orders: [],
		searchType: 'orderId',
		searchTypes: ['orderId','serviceName','keycard','permissionId','pickupNumber','customerName','ticketHolderName','email'],
	}},
	computed: {
		headers() {
			return [
				{ title: this.$t('text.id'), key: 'id', sortable: true, width: '15%', cellClass: 'truncate', sortable: false },
				{ title: this.$t('text.purchaseDate'), key: 'purchase_date', width: '10%', cellClass: 'truncate', sortable: false },
				{ title: this.$t('text.marketplace'), key: 'marketplace', width: '10%', cellClass: 'truncate', sortable: false },
				{ title: this.$t('text.shop'), key: 'storeName', width: '20%', cellClass: 'truncate', sortable: false },
				{ title: this.$t('text.customerName'), key: 'customer_name', width: '15%', sortable: false },
				{ title: this.$t('text.people'), key: 'noOfItems', width: '10%', align: 'center', cellClass: 'truncate', sortable: false },
				{ title: this.$t('text.total'), key: 'total', width: '10%', align: 'right', cellClass: 'truncate', sortable: false },
				{ title: this.$t('text.status'), key: 'status', width: '10%', align: 'center', sortable: false }
			]
		},
		errorMessages() {
			return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
		},
		validFromText() {
			return this.eventFromDate ? this.formatDate(this.eventFromDate, DATE_FORMATS.YEAR_MONTH_DAY) : this.$t('text.dateFrom')
		},
		validToText() {
			return this.eventToDate ? this.formatDate(this.eventToDate, DATE_FORMATS.YEAR_MONTH_DAY) : this.$t('text.dateTo')
		},
	},
	methods: {
		setSelectedSearchType(type) {
			this.searchType = type
			this.isSearchOpened = false
		},
		getStatusColor (status) {
			if (!status) return 'gray'
			if (parseInt(status.refunded) === 1) {
				return '#f34545'
			} 
			else if (parseInt(status.canceled) === 1) {
				return '#f34545'
			} 
			else if (parseInt(status.shipped) === 1) {
				return '#64c823'
			}
			else if (parseInt(status.invoiced) === 1) {
				return '#64c823'
			} 
			else if (parseInt(status.ordered) === 1) {
				return '#64c823'
			}
		},
		getStatusLabel(status) {
			if (!status) return ''
			if (parseInt(status.refunded) === 1) {
				return this.$t('text.Refunded')
			} 
			else if (parseInt(status.canceled) === 1) {
				return this.$t('text.Cancelled')
			} 
			else if (parseInt(status.shipped) === 1) {
				return this.$t('text.Shipped')
			} 
			else if (parseInt(status.invoiced) === 1) {
				return this.$t('text.Invoiced')
			} 
			else if (parseInt(status.ordered) === 1) {
				return this.$t('text.Ordered')
			}
		},
		formatPrice(value) {
			if (value === undefined) return '-'
			let val = (value/1).toFixed(2).replace('.', ',')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
		},
		closeDropDown(ref) {
			if (!this.dropdownOpen) {
				this.$refs[ref].focus()
				this.dropdownOpen = true
			} else {
				this.$refs[ref].blur()
				this.dropdownOpen = false
			}
		},
		async clearSearch() {
			this.searchString= ''
			this.searchType = 'orderId'
			this.getOrders()
		},
		toggleDatePicker(pickerToToggle) {
			if (pickerToToggle === 'from') {
				this.showToDate = false
				this.showFromDate = true
			} else {
				this.showFromDate = false
				this.showToDate = true
			}
		},
		resetDates() {
			this.eventToDate = null
			this.showFromDate = false
		},
		applyFilter() {
			if (this.selectedStatuses.length > 0 || this.eventFromDate || this.eventToDate) {
				console.log('applyFilter', this.selectedStatuses, this.eventFromDate, this.eventToDate)
				
				//Add Refunded status when 'Canceled' is selected
				const statuses = [...this.selectedStatuses]
				if (statuses.find(x => x === 'Canceled')) {
					statuses.push('Refunded')
				}

				this.offset = 0
				this.filter = {
					statuses: statuses,
					eventFromDate: this.eventFromDate ? this.eventFromDate.toISOString().split('T')[0] : null,
					eventToDate: this.eventToDate ? this.eventToDate.toISOString().split('T')[0] : null
				}
				this.getOrders()
			}
			else {
				this.clearFilter()
			}
		},
		clearFilter() {
			this.selectedStatuses = []
			this.eventFromDate = null
			this.eventToDate = null

			this.offset = 0
			this.filter = null
			this.getOrders()
		},
		async showOrderDetail(item) {
			await this.$store.commit('setSelectedOrder', item)
			this.$router.push("/orderDetail")
		},
		async getOrders() {
			this.isLoading = true
			let url = ''
			if (this.userIsOperator) {
				url = `/client-orders?clientId=${this.$store.state.selectedClient.sys.id}&category=SKIPASS&skip=${this.offset}&limit=${this.limit}&searchString=${this.searchString}&searchType=${this.searchType}`
			} else {
				url = `/orders/${this.$store.state.selectedServiceProvider.sys.id}?skip=${this.offset}&limit=${this.limit}&searchString=${this.searchString}&searchType=${this.searchType}`
			}
			
			if (this.filter) {
				url += `&filter=${JSON.stringify(this.filter)}`
			}

			const res = await this.$httpGet(url)

			this.orders = res.orders
			this.ordersCount = res.total

			this.isLoading = false
		}
	},
	async mounted() {
		this.getOrders()
	},
}
</script>

<style scoped>
.search-container {
  display: flex;
  background: rgb(255, 255, 255);
  border: 1px solid #dddddd;
  border-radius: 6px;
  overflow: hidden; 
  width: 100%;
}

.container { padding: 0px !important; width:100% !important; }
.tableCard { border-radius:5px; background-color:#808080 !important; top: 0px; }
.tableCard span { white-space: nowrap; text-overflow: ellipsis; }
.dot-container { width: 75px; height: 75px; position: absolute; top: 0; right: 0; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
/* .dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: -1px; right: -1px; } */
.v-text-field.v-text-field--enclosed .v-input__prepend-inner { margin-top: 12px !important; }
</style>
