<template>
	<!-- Service Provider Navbar-->
	<template v-if="!appIsServiceManager">
		<v-text-field variant="outlined" density="compact" clearable hide-details prepend-inner-icon="mdi-magnify"
			placeholder='Search' v-model="searchString" @focus="focusedSearch" @blur="blurredSearch" @keyup.enter="search"
			data-cy="search" @click:clear="clear" />
		<v-btn-toggle>
			<v-btn id="btnShowServices" class="gradientButton" :class="{ active: pathname === '/services' }" @click="$router.push('/services')">
				<v-icon class="d-lg-none">mdi-cart</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.services') }}</span>
			</v-btn>
			<v-btn v-if="$store.state.featureFlags.FEATURE_FAQ_IN_SERVICES" id="btnShowFAQs" class="gradientButton"
				:class="{ active: pathname === '/faq-list' }" @click="$router.push('/faq-list')">
				<v-icon class="d-lg-none">mdi-frequently-asked-questions</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.faqs') }}</span>
			</v-btn>
			<v-btn v-if="$store.state.featureFlags.FEATURE_PACKAGE_DESIGNER && serviceProviderHasTemplateTypes" class="gradientButton" id="btnShowPackages"
			:class="{ active: pathname === '/packages' }" @click="$router.push('/packages')">
				<v-icon class="d-lg-none">mdi-package-variant-closed</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.packages') }}</span>
			</v-btn>
		</v-btn-toggle>

		<slot name="add-button"></slot>

		<!-- Filters -->
		<FilterMenu v-model="filter" @clear="clearFilter" @apply="applyFilter">
			<FilterFieldWrap dataCy="statusFilter">
				<span>{{ $t('text.serviceStatus') }}</span>
				<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact" v-model="selectedStatuses"
					:items="statuses" :item-title="item => $t('text.' + item.id)" :item-value="item => item.id" multiple
					chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ $t('text.' + item.id) }}</v-chip>
					</template>
				</v-select>
			</FilterFieldWrap>

			<!-- coming out of MYS-4107 i have deactivated this for now, because this feature was never implemented in CM.
					<FilterFieldWrap>
						<div>{{ $t('text.availabilityPeriod') }}</div>
						<div style="display: flex">
							<v-btn class="clearButton flex-grow-1 mr-4" elevation="0" @click="toggleDatePicker('from')">{{validFromText}}</v-btn>
							<v-btn class="clearButton flex-grow-1" elevation="0" @click="toggleDatePicker('to')">{{validToText}}</v-btn>
						</div>

						<v-date-picker
							v-if="showFromDate"
							v-model="validFromDate"
							color="green-lighten-1"
							@update:modelValue="resetDates()"
							hide-actions
						/>

						<v-date-picker
							v-if="showToDate"
							v-model="validToDate"
							color="green-lighten-1"
							:min="validFromDate"
							@update:modelValue="showToDate=false"
							hide-actions
						/>

						<mys-switch
							v-model="isNegative"
							:label="$t('text.negativeAvailabilityPeriod')"
						/>
					</FilterFieldWrap>
				-->

			<FilterFieldWrap v-if="productCategories.length > 0" dataCy="categoryFilter">
				<span>{{ $t('text.serviceType') }}</span>
				<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact"
					v-model="selectedProductCategories" :items="productCategories"
					:item-title="item => item.fields.title?.[selectedLocale] ? item.fields.title?.[selectedLocale] : item.fields.title?.de"
					:item-value="item => item.sys.id" multiple chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ item.fields.title?.[selectedLocale] }}</v-chip>
					</template>
				</v-select>
			</FilterFieldWrap>

			<FilterFieldWrap dataCy="marketplaceFilter">
				<span>{{ $t('text.clientsTitle') }}</span>
				<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact" v-model="selectedClients"
					:items="clients" :item-title="item => item.fields.title?.[selectedLocale]"
					:item-value="item => item.sys.id" multiple chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ item.fields.title?.[selectedLocale] }}</v-chip>
					</template>
				</v-select>
			</FilterFieldWrap>

			<FilterFieldWrap dataCy="categoryFilter">
				<span>{{ $t('text.source') }}</span>
				<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact" v-model="selectedSources"
					:items="sources" :item-title="item => item" :item-value="item => item" multiple chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ item }}</v-chip>
					</template>
				</v-select>
			</FilterFieldWrap>
		</FilterMenu>
	</template>

	<!-- Operator Navbar -->
	<template v-if="appIsServiceManager">
		<v-text-field variant="outlined" density="compact" clearable hide-details prepend-inner-icon="mdi-magnify"
			placeholder='Search' v-model="searchString" @keyup.enter="search()" data-cy="search" @click:clear="clear()" />

		<v-btn-toggle>
			<v-btn id="btnShowServices" class="gradientButton" :class="{ active: pathname === '/services' }" @click="$router.push('/services')">
				<v-icon class="d-lg-none">mdi-cart</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.services') }}</span>
			</v-btn>
			<v-btn v-if="$store.state.featureFlags.FEATURE_PACKAGE_DESIGNER" class="gradientButton" id="btnShowPackages"
			:class="{ active: pathname === '/packages' }" @click="$router.push('/packages')">
				<v-icon class="d-lg-none">mdi-package-variant-closed</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.packages') }}</span>
			</v-btn>
		</v-btn-toggle>

		<slot name="add-button"></slot>

		<!-- Filters -->
		<FilterMenu v-model="filter" @clear="clearFilter" @apply="applyFilter">
			<FilterFieldWrap dataCy="statusFilter">
				<span>{{ $t('text.serviceStatus') }}</span>
				<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact" v-model="selectedStatuses"
					:items="statuses" :item-title="item => $t('text.' + item.id)" :item-value="item => item.id" multiple
					chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ $t('text.' + item.id) }}</v-chip>
					</template>
				</v-select>
			</FilterFieldWrap>

			<!-- coming out of MYS-4107 i have deactivated this for now, because this feature was never implemented in CM.
					<FilterFieldWrap>
						<span>{{$t('text.availabilityPeriod')}}</span>
						<div style="display: flex">
							<v-btn class="clearButton flex-grow-1 mr-4" elevation="0" @click="toggleDatePicker('from')">{{validFromText}}</v-btn>
							<v-btn class="clearButton flex-grow-1" elevation="0" @click="toggleDatePicker('to')">{{validToText}}</v-btn>
						</div>

						<v-date-picker
							v-if="showFromDate"
							v-model="validFromDate"
							color="green-lighten-1"
							@update:modelValue="resetDates()"
							hide-actions
						/>

						<v-date-picker
							v-if="showToDate"
							v-model="validToDate"
							color="green-lighten-1"
							:min="validFromDate"
							@update:modelValue="showToDate=false"
							hide-actions
						/>

						<mys-switch
							v-model="isNegative"
							:label="$t('text.negativeAvailabilityPeriod')"
						/>
					</FilterFieldWrap>
				-->

			<FilterFieldWrap v-if="productCategories.length > 0" dataCy="categoryFilter">
				<span>{{ $t('text.serviceType') }}</span>
				<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact"
					v-model="selectedProductCategories" :items="productCategories"
					:item-title="item => item.fields.title?.[selectedLocale] ? item.fields.title?.[selectedLocale] : item.fields.title?.de"
					:item-value="item => item.sys.id" multiple chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ item.fields.title?.[selectedLocale] }}</v-chip>
					</template>
				</v-select>
			</FilterFieldWrap>

			<FilterFieldWrap dataCy="marketplaceFilter">
				<span>{{ $t('text.clientsTitle') }}</span>
				<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact" v-model="selectedClients"
					:items="clients" :item-title="item => item.fields.title?.[selectedLocale]"
					:item-value="item => item.sys.id" multiple chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ item.fields.title?.[selectedLocale] }}</v-chip>
					</template>
				</v-select>
			</FilterFieldWrap>

			<FilterFieldWrap dataCy="categoryFilter">
				<span>{{ $t('text.source') }}</span>
				<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact" v-model="selectedSources"
					:items="sources" :item-title="item => item" :item-value="item => item" multiple chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ item }}</v-chip>
					</template>
				</v-select>
			</FilterFieldWrap>
		</FilterMenu>
	</template>
</template>

<script>
import Common from '@/mixins/Common.vue'
import FilterMenu from '@/components/common/FilterMenu.vue'
import FilterFieldWrap from '@/components/common/FilterFieldWrap.vue'
import IfFeatureFlag from '@/components/ifFeatureFlag/IfFeatureFlag.vue'

export default {
	name: 'Navbar',
	props: {
		fetchData: Function,
		showLoader: Function,
	},
	components: { FilterMenu, FilterFieldWrap, IfFeatureFlag },
	mixins: [Common],
	data() {
		return {
			searchString: this.$store.state.searchString,
			filter: this.$store.state.filter,
			searchSmCols: 2,
			showControls: true,
			selectedStatuses: this.$store.state.filter ? this.$store.state.filter.statuses : [],
			validFromDate: this.$store.state.filter ? this.$store.state.filter.validFromDate : '',
			validToDate: this.$store.state.filter ? this.$store.state.filter.validToDate : '',
			showFromDate: false,
			showToDate: false,
			isOpened: false,
			isNegative: this.$store.state.filter ? this.$store.state.filter.isNegative : false,
			productCategories: { de: [] },
			selectedProductCategories: this.$store.state.filter ? this.$store.state.filter.productCategories : [],
			clients: [],
			selectedClients: this.$store.state.filter ? this.$store.state.filter.selectedClients : [],
			selectedSources: this.$store.state.filter ? this.$store.state.filter.selectedSources : [],
			statuses: [
				{ id: 'active', color: 'green' },
				{ id: 'deactivated', color: 'red' },
				{ id: 'pending', color: 'orange' },
				{ id: 'archived', color: 'grey' },
				{ id: 'expired', color: '#737373' },
			],
			sources: ['MYSERVICES', 'AXESS', 'EXPERIENCEBANK', 'FERATEL', 'REGIONDO', 'SBBV3', 'SKIDATA', 'TOMAS'],
		}
	},
	computed: {
		pathname() {
			return this.$route.path
		},
		appIsServiceManager() {
			return this.$store.state.selectedComponent?.fields?.title?.en === 'Manage Services'
		},
		validFromText() {
			return this.validFromDate !== '' ? this.formatDate(this.validFromDate, DATE_FORMATS.YEAR_MONTH_DAY) : this.$t('text.validFrom')
		},
		validToText() {
			return this.validToDate !== '' ? this.formatDate(this.validToDate, DATE_FORMATS.YEAR_MONTH_DAY) : this.$t('text.validUntil')

		},
		notValidFromText() {
			return this.validFromDate !== '' ? this.validFromDate : 'Not valid from'
		},
		notValidToText() {
			return this.validToDate !== '' ? this.validToDate : 'Not valid to'
		},
		serviceProviderHasTemplateTypes() {
			return this.$store.state.selectedServiceProvider.fields.userAccount?.de?.fields?.serviceTypes?.de.length > 0
		},
	},
	methods: {
		resetDates() {
			this.validToDate = ''
			this.showFromDate = false
		},
		toggleDatePicker(pickerToToggle) {
			if (pickerToToggle === 'from') {
				this.showToDate = false
				this.showFromDate = true
			} else {
				this.showFromDate = false
				this.showToDate = true
			}
		},
		focusedSearch() {
			this.searchSmCols = 10
			this.showControls = false
		},
		blurredSearch() {
			this.searchSmCols = 2
			this.showControls = true
		},
		applyFilter() {
			this.offset = 0

			if (true
				&& !this.selectedStatuses.length
				&& !this.validFromDate
				&& !this.validToDate
				&& !this.selectedProductCategories.length
				&& !this.selectedClients.length
				&& !this.selectedSources.length
			) {
				this.clearFilter()
				return
			}

			this.filter = {
				statuses: this.selectedStatuses,
				validFromDate: this.validFromDate,
				validToDate: this.validToDate,
				isNegative: this.isNegative,
				productCategories: this.selectedProductCategories,
				selectedClients: this.selectedClients,
				selectedSources: this.selectedSources
			}
			this.$store.commit('setFilter', this.filter)

			this.isOpened = false
			this.fetchData()
		},
		clearFilter() {
			this.isOpened = false
			this.selectedStatuses = []
			this.validFromDate = ''
			this.validToDate = ''
			this.isNegative = false
			this.selectedProductCategories = []
			this.selectedClients = []
			this.selectedSources = []
			this.filter = null
			this.$store.commit('setFilter', this.filter)
			this.fetchData()
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
			this.offset = 0
			this.fetchData()
		},
		async clear() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', null)
			this.fetchData()
		},
		async getProductCategories() {
			try {
				this.productCategories = this.$store.state.selectedClient.fields.productCategories?.de ?? []

				if (this.productCategories?.length > 0) {
					this.productCategories.sort(this.compare)

					for (let productCategory of this.productCategories) {
						if (productCategory.fields?.subProductCategories?.de) { productCategory.fields.subProductCategories.de.sort(this.compare) }
					}
				}
			}
			catch (error) {
				this.showError(error)
			}
		},
		async getMarketplaceClientsForClient() {
			const client = this.$store.state.selectedClient

			let clients = []
			const clientSysId = client.sys.id
			let marketplaces = await this.$httpGet('/marketplace?clientSysId=' + clientSysId)
			let mp = marketplaces?.[0]
			let lookup = {}

			for (let client of mp.fields.externalClients.de) {
				clients.push(client)
				lookup[client.sys.id] = client
			}
			// add homeClient if it wasnt in the external clients
			if (!lookup[mp.fields.homeClient.de.sys.id])
				clients.push(mp.fields.homeClient.de)
			this.clients = clients
		},
	},
	created() {
		if (!this.userIsOperator) {
			this.statuses.unshift({ id: 'saved', color: 'blue' })
		}
	},
	async mounted() {
		await Promise.all([this.getProductCategories(), this.getMarketplaceClientsForClient()])
	},
}
</script>

<style lang="scss"></style>
