<script>
// TODO: currently we pass objects like { id: 'daysInAdvance', myParam: 'myValue' }
//       instead pass an object like this { id: 'daysInAdvance', key: 'generalInfo', myParam: 'myValue' }
export default {
	data: () => ({
		childErrorsDispatched: {},
		childErrorDispatcherId: null,
	}),
	methods: {
		dispatchChildEvent(el, name, detail) {
			if (!this.childErrorDispatcherId)
				this.childErrorDispatcherId = '<' + Math.floor(Math.random() * 99999) + '>'
			detail.id += this.childErrorDispatcherId

			const event = new CustomEvent(name, { detail, bubbles: true })
			// TODO: the semantics in this case is different - we are dispatching from the root instead of the individual field
			//       if this is ok, i think we always should do it like that..
			if (!el) el = this.$el
			if (el.target) el = el.target
			// we have to add a random id to the id, so that multiple comps dont clear each others errors
			el.dispatchEvent(event)
			return event
		},
		// counterpart to this is the ChildErrorReceiver
		childError(el, id, params) {
			const detail = { id, ...params }
			this.dispatchChildEvent(el, 'error', detail)
			this.childErrorsDispatched[id] = detail
		},
		clearChildError(el, id) {
			const detail = { id, clear: true }
			this.dispatchChildEvent(el, 'error', detail)
			this.childErrorsDispatched[id] = undefined
		},
		childErrorAutoDispatch(el, errors) {
			const errorLookup = {}
			for (const error of errors)
				errorLookup[error.id] = error
			for (const id in this.childErrorsDispatched) {
				if (!errorLookup[id])
					this.clearChildError(el, id)
			}
			for (const error of errors) {
				if (!this.childErrorsDispatched[error.id])
					this.childError(el, error.id, error)
			}
		},
		// counterpart to this is Application.vue
		applicationError(el, title, detail) {
			this.dispatchChildEvent(el, 'applicationError', { title, detail })
		},
		applicationSuccess(el, title, detail) {
			this.dispatchChildEvent(el, 'applicationSuccess', { title, detail })
		},
	},
	// TODO: should we really take the validation trigger concern in here?
	mounted() {
		if (this.validate) this.validate()
	},
	// on unmount we have to clear all errors that we previously dispatched
	unmounted() {
		for (const id in this.childErrorsDispatched) {
			this.clearChildError(this.$el, id)
		}
	},
}
</script>