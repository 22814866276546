<template>
	<Application v-model:errorTitle="errorTitle" :errorDetail="errorDetail" v-model:successTitle="successTitle" :successDetail="successDetail" :loading="loading" :external="true">
		<iframe ref="iframe"></iframe>
	</Application>
</template>

<script>
// TODO: the default bahaviour of vuetify is to allow scrolling on the html element
//       but with plugins this creates an awkward elastic-scroll-effect
//       we should style: html { overflow-y: hidden !important; }
//       unfortunately ALWAYS setting it will break other MYS apps..
//       how to only do it when a plugin app is active?
//       maybe the Application comp can set a class on the html element?

import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import Mys from './MYS.js'
import Application from '../Application.vue'

export default {
	name: 'AppHost',
	components: { Loading, Alert, Application },
	props: {
		locale: String,
		_getUser: Function,
		_getIdentity: Function,
		_userHasAppAccess: Function,
		client: Object,
		selectedComponent: Object, // optionally the container can pass in the application object
	},
	data: () => ({
		loading: false,
		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',
		messageListener: null,
		mys: null,
		app: null,
		appPath: null,
		fullPath: '',
		url: '',
	}),
	watch: {
		locale(v) {
			// TODO: only send events in after the plugin has initialized
			this.mys?.localeChanged(v)
		},
	},
	mounted() {
		console.log('AppHost mounted')
		this.appPath = this.$route.params.appName
		this.app = this.selectedComponent ?? this.$store.state.selectedComponent
	
		// Check if URL has a deeplink hash value
		this.fullPath = this.$route.fullPath
	
		let deeplinkHash = ''
		const urlHashIndex = this.fullPath.indexOf('/#/')
		if (urlHashIndex > -1) {
			deeplinkHash = this.fullPath.substring(urlHashIndex + 3, this.fullPath.length)
		}
	
		// The app can be null if the plugin is accessed via a deeplink
		// Check if the app is null and set the application according to the url that is in the window.location.pathname
		if (!this.app?.fields) {
			const allApplications = this.allApplications ?? this.$store.state.allApplications ?? []
			const pathname = window.location.pathname.replace('/sa-app/', '/app/').replace(/\/+$/, '')
			this.app = allApplications.find(app => app.fields.link?.de == pathname)
			console.log('AppHost auto-selected app', this.app)
		}

		// test app: 'https://admin.skiline.cc/php/tools/proto-mys-plugin/2/TestApp.html'
		this.url = this.app?.fields?.appConfig?.de?.url
		console.log('AppHost url', this.url)
		if (!this.url && this.$store.state.loggedInUser?.fields) throw new Error('App url not found in config!')

		this.mys = new Mys(this.url, this, this.$refs.iframe.contentWindow)

		// TODO: bootstrap: how to auth the app itself?
		//       a s2s call?
		//       api key is insecure since it would be visible in the client
		//       host -(challenge)-> guest -(challenge)-> guest server -> -(solution)-> mys server
		//                                   <-(solution)-'
		//       can a pure browser-app be authenticated safely at all?
		// TODO: limit guest urls - how?
		// TODO: require auth with every call + signature verification (both sides)
		// TODO: pass down location parameters and appPath?
		this.open(this.url, deeplinkHash)
	},
	beforeUnmount () {
		this.mys?.destroy()
	},
	methods: {
		open(url, hash) {
			if (hash?.length) {
				url = `${url}/#/${hash}`
				this.setHash(hash)
			}

			this.$refs.iframe.src = url
		},
		// mys api implementation
		getClient(param) {
			return this.client ?? this.$store.state.selectedClient
		},
		getClients(param) {
			if (this.client) return [ this.client ]
			return this.$store.state.loggedInUser?.fields?.clients?.de ?? []
		},
		async setClient(param) {
			const clients = this.getClients()

			/* param can be a :
				- MYS Client Sys Id e.g. 15Y3LzDkWo0RFsplUPu043
				- MYS Client object
				- MYS Client Id e.g. SGV
				- MYS Shop Client Id e.g. TUZ
			*/
			const selectedClient = clients.find(client => 
				client.sys.id === param ||
				client.sys.id === param?.sys?.id ||
				client?.fields?.clientId?.de === param ||
				client?.fields?.shopClientId?.de === param
			);

			if (!selectedClient) {
				throw new Error("Client selection is invalid for logged in user");
			}
			
			await this.$store.commit('setSelectedClient', selectedClient)
		},
		getAppComponent(param) {
			return this.app
		},
		getAppPathName(param) {
			return this.$route.params.appName
		},
		getQuery(param) {
			return window.location.search
		},
		getIdentity(param) {
			if (this._getIdentity) return this._getIdentity(param)

			return this.$store.state.loggedInUser.kc_token
		},
		getUser(param) {
			if (this._getUser) return this._getUser(param)

			if (this.$store.state.isImpersonation) {
				return this.$store.state.impersonatedServiceProvider.fields.mainUserAccount
			}
			return this.$store.state.loggedInUser
		},
		getLocale(param) {
			return this.locale ?? this.$store.state.selectedLocale
		},
		getLocales(param) {
			return this.$store.state.locales
		},
		getHash(param) {
			return this.$store.state.deeplinkHash ?? ''
		},
		async setHash(param) {
			await this.$store.commit('setDeeplinkHash', param);
			
			let path = this.fullPath

			//Reset URL to exclude Hash value if there is one in the URL already
			const urlHashIndex = this.fullPath.indexOf('/#/')
			if (urlHashIndex > -1) {
				path = this.fullPath.substring(0, urlHashIndex)
			}
			
			if (param?.length) {
				path = `${path}/#/${param}`
			}

			this.$router.replace(path).catch(err => {})
		},
		userHasAppAccess(param) {
			if (this._userHasAppAccess) return this._userHasAppAccess(param)

			let userApps = []

			if (this.$store.state.loggedInUser?.fields?.type?.de === 'operator' && 
				this.$store.state.isImpersonation === false) {
				//Operator user is logged in and not impersonating an SP
				userApps = this.$store.state.loggedInUser?.fields?.applications?.de ?? []
			} else {
				//User is a service provider or an operator impersonating a service provider
				userApps = this.$store.state.impersonatedServiceProvider?.fields?.userAccount?.de?.fields?.applications?.de ?? []
			}
			
			return userApps.findIndex(userApp => userApp.sys.id === this.app.sys.id) > -1
		},
		getAppConfig(param) {
			return this.app?.fields?.appConfig?.de ?? null
		},
		navigateToUrl(href) {
			window.location.href = href
		},
	}
}
</script>

<style scoped>
iframe { position: absolute; left: 0; top: 0; width: 100%; height: 100%; border: 0; }
.theme--light.v-app-bar.v-toolbar.v-sheet { background-color: transparent !important; }
.navbar { background-color: transparent !important; }
</style>

