<template>
	<div :class="{backdrop}" @click="backdropClick">
		<div class="dialog-div" style="height:75%;width:700px">
			<v-toolbar elevation="0" color="white" style="width:100%" class="dialog-header">
				<v-row style="width:100%" align="center">
				<v-toolbar-title style="margin-left:20px">{{ dialogTitle }}</v-toolbar-title>
				<v-spacer/>
				<v-btn class="btn-close" elevation="0" @click="closeDialog()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				</v-row>
				<v-spacer></v-spacer>
			</v-toolbar>
			<v-divider style="border-color:#dedede"/>

			<!-- Ticket View -->
			<v-layout row wrap class="scrollable dialog-content">
				<v-col class="d-flex overflow-x-auto ma-0" style="padding:10px;">

					<div class="pa-3" style="width:100%">
						<v-row class="pa-0" style="width:100%">
							<span class="nameLabel" style="width:85%">{{ ticket.ticketInfo.productName }}</span><br/>
							<span v-if="ticket.contingentInfo.free === 0" class="redeemedLabel" style="text-align:center;">{{ $t('text.redeemed')}}</span>
						</v-row>
						<br/><br/>

						<!-- Ticket Details -->
						<span class="fieldLabel">{{ $t('text.ticketDetails') }}</span><br/>
						<v-divider/>

						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.product') }}</span><br/>
						</v-row>
						
						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.type') }}</span><br/>
							<span class="fieldValue" style="width:50%">{{ ticket.ticketInfo.ticketTypeName }}</span>
						</v-row>

						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.category') }}</span><br/>
							<span class="fieldValue" style="width:50%">{{ ticket.ticketInfo.consumerGroupName }}</span>
						</v-row>

						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.price') }}</span><br/>
							<span class="fieldValue" style="width:50%">{{ $store.state.selectedClient.fields.currency.de }} {{ formatPrice(ticket.ticketInfo.price?.value ? ticket.ticketInfo.price.value : 0) }}</span>
						</v-row>

						<br/><br/>

						<!-- Validity -->
						<span class="fieldLabel">{{ $t('text.validity') }}</span><br/>
						<v-divider/>

						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.validOn') }}</span><br/>
							<span class="fieldValue" style="width:50%">
								{{ formatDate(ticket.ticketInfo.validityDate) }} 
								<span v-if="ticket.ticketInfo.validityEndDate">-</span> 
								{{ formatDate(ticket.ticketInfo.validityEndDate) }}
							</span>
						</v-row>
						
						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.totalUsages') }}</span><br/>
							<span class="fieldValue" style="width:50%">{{ ticket.contingentInfo.used }}</span>
						</v-row>

						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.remainingUsages') }}</span><br/>
							<span class="fieldValue" style="width:50%">{{ ticket.contingentInfo.free }}</span>
						</v-row>

						<br/><br/>

						<!-- Order -->
						<span class="fieldLabel">{{ $t('text.order') }}</span><br/>
						<v-divider/>

						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.name') }}</span><br/>
							<span class="fieldValue" style="width:50%">{{ ticket.orderInfo.customerFirstName }} {{ ticket.orderInfo.customerLastName }}</span>
						</v-row>
						
						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.order') }}</span><br/>
							<span class="fieldValue" style="width:50%">{{ ticket.orderInfo.orderId }}</span>
						</v-row>

						<v-row class="pa-0" style="width:100%">
							<span class="fieldLabel" style="width:50%">{{ $t('text.orderDate') }}</span><br/>
							<span class="fieldValue" style="width:50%">{{ ticket.orderInfo.orderDate }}</span>
						</v-row>
					</div>
				</v-col>
			</v-layout>

			<v-divider/>

			<!-- Footer -->
			<v-toolbar elevation="0" color="white" style="width:100%" height="60" class="dialog-footer">
			<v-spacer></v-spacer>
			<v-btn class="gradientButton" elevation="0" v-if="isGuestcard"
				@click="backHandler()"
				data-cy="back"
			><v-icon>mdi-arrow-left</v-icon> {{$t('text.back')}}</v-btn>

			<v-btn class="gradientButton" elevation="0" v-if="!isGuestcard"
				@click="closeDialog()"
				data-cy="cancel"
			>{{ $t('text.cancel') }}</v-btn>
			
			<v-btn class="greenButton" theme="dark" elevation="0" 
				:disabled="ticket.contingentInfo.free === 0"
				@click="redeemTicket()"
				data-cy="redeem"
			>{{ $t('text.redeem') }}</v-btn>
			</v-toolbar>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import Dialog from '@/components/common/Dialog.vue'

export default {
	name: 'TicketDetails',
	components: {Loading, Dialog},
	mixins: [Common],

	props: {
		isGuestcard: Boolean,
		gateId: String,
		ticket: Object
	},

	data() {
		return {
			loading: false,
			hasError: false,

			isWarning: false,
			isError: false,
			isSuccess: false,
			isLoading: false,

			canRetry: false,
			messageTitle: '',
			messageDetail: '',
			confirmLabel: '',
			cancelLabel: '',
			backLabel: '',

			backdrop: true,
			closeOnOuterClick: false,

			dialogTitle: this.isGuestcard === true ? this.$t('text.validateGuestcard') : this.$t('text.validateTicket')
		}
	},

	methods: {
		async redeemTicket() {
			this.showLoading()
			try {
				const redemptionResponse = await this.$httpPost('/validitychecker/redeem-ticket',
				{ 
					gateId: this.gateId,
					identificationId: this.ticket.ticketInfo.identificationId,
					qrCodeData: this.qrCodeData,
					clientId: this.$store.state.selectedClient.sys.id
				})

				this.hideMessage()

				if (redemptionResponse.resultInfo.code > 0) {
					this.showWarning(redemptionResponse.resultInfo.message)
				} else {
					this.ticket.contingentInfo.free = 0
					this.showSuccess()
				}
				
			} catch (e) {
				this.hideMessage()
				this.showGeneralError()
			}
		},
		showMessage(type, title, text, confirmLabel, canRetry) {
			this.$emit('show-message', {type:type, title:title, text:text, confirmLabel:confirmLabel, canRetry:canRetry})
		},
		showGeneralError() {
			this.showMessage('error', this.$t('text.generalErrorTitle'), this.$t('text.errorUnexpected'), this.$t('text.done'), true)
		},
		showInvalidWarning(text) {
			this.showMessage('warning', this.$t('text.invalidErrorTitle'), text, this.$t('text.done'), false)
		},
		showWarning(text) {
			this.showMessage('warning', this.$t('text.invalidErrorTitle'), text, this.$t('text.done'), false)
		},
		showSuccess() {
			this.showMessage('success', this.$t('text.success'), this.$t('text.ticketRedemptionSuccess'), this.$t('text.done'), false)
		},
		showLoading() {
			this.showMessage('loading', this.$t('text.ticketRedemptionProgress'), '', '', false)
		},
		hideMessage() {
			this.$emit('hide-message')
		},
		closeDialog() {
			this.$emit('close-dialog')
		},
		backdropClick() {
			if (this.closeOnOuterClick) {
				this.closeDialog();
			}
		},
		backHandler() {
			this.$emit('view-guestcard')
		},
		cancelHandler() {
			this.reset()
			this.$emit('close-ticket-redemption')
		},
	},
}
</script>

<style scoped>
.btn-close {
	background-color: #ffffff !important; 
	height:40px !important;
	margin-right:0px !important;
	color: #999999 !important;
}
.title { font-size: 24pt; color: #000000; font-weight: bold; }
.generalLabel { color: #999999 !important; font-size: 12pt;}
.fieldLabel { font-weight: bold; font-size: 12pt; line-height: 2;}
.fieldValue { font-size: 12pt; line-height: 2;}
.helpLabel { font-size: 10.5pt;}
.nameLabel { font-weight: bold; font-size: 1.25rem;}
.ticketRow {  cursor: pointer }
.ticketRow:hover { background-color: #f4f4f4; }
.theme--light.v-divider { border-color: #999999 !important; }
.redeemedLabel {
  height: 32px;
  background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%);
  border: thin #088ec0 solid;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 11pt;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  color: #ffffff;
  padding: 10px;
}
</style>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  height: calc(100vh - 64px);
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 64px;
  left: 0;
}

.dialog-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1100px;
  height: min(700px, calc(100vh - 20px));
  max-width: calc(100vw - 20px);

  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;

  i {
    margin-right: 5px;
  }

  .dialog-header {
    max-height: 64px;
  }

  .dialog-footer {
    max-height: 60px;

    .gradientButton {
      height: 40px !important;
      min-height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
      color: #000;
    }

    .greenButton {
      height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
    }

    .redButton {
      height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
