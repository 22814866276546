<template>
	<Application :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :successDetail="successDetail" v-model:successTitle="successTitle" :loading="loading"
	>
	<Step id="packageOffers" icon="mdi-plus">
		<!-- TODO:
			we should not have a central save button for "mehrwerte".
			we need a separate api endpoint for these offers (they are also persisted individually in peak).
			also the combined editor as proposed in the design is problematic.
			this will lead to many calls at once and to large transactional brackets.
			instead we should have each offer in its own editor.
			IMO we would also not need a detail-popup if we would use an expander like on this view: https://overflow.io/s/CDRGOW7X/?node=ae33fed9
		-->
		<div class="todo">https://overflow.io/s/CDRGOW7X/?node=6cc4129b</div>
		<Section id="name" open>
			<Field typeName="Package" fieldName="name" v-model="modelValue.fields.name" />
		</Section>
		<Section id="channels" open>
			<FieldSet id="channels">
				<PackageSalesChannels v-model="model" :products="products" :salesChannels="salesChannels" />
			</FieldSet>
		</Section>
		<Section id="addedOffers" open>
			<FieldSet id="addedOffers" infoText="addedOffersHelp">
				<AddButton @click="updateAddedValueProducts" buttonText="addedValues" />
				<SectionTable :columns="addedValueColumns" :items="addedValueItems" :salesChannelMismatch="salesChannelMismatch" :options="options" no-data-text="noTransportationProductsAdded" @handleOptionClick="handleOptionClick"/>
				<AddedValuesDialogExperimental v-model="addedValueArray" ref="editAddedValueDialog" v-if="transportationProducts" />
				<!-- TODO: this needs to have a v-model to pass down the added values and get updated on confirming the dialog -->
				<NewPackageDialog ref="addedValuesDialog" v-if="addedValueProducts" v-model="model" :linkedEntries="linkedEntries" @mismatch="(val) => salesChannelMismatch = val" @get-products="getProducts" />
			</FieldSet>
		</Section>
		<Section id="transportProducts" open>
			<FieldSet id="transportationProducts" infoText="transportationProductsHelp">
				<AddButton @click="updateTransportProducts" buttonText="transportation" :update="true" />
				<SectionTable :columns="transportationColumns" :items="transportationItems" no-data-text="noTransportationProductsAdded" />
				<!-- <TransportationAddedValuesDialog v-model="transportationProducts" ref="transportProductsDialog" v-if="transportationProducts" /> -->
				<AddedValuesDialogExperimental v-model="transportationProducts" ref="transportProductsDialog" v-if="transportationProducts" />
			</FieldSet>
		</Section>


		<Section id="transportProducts" open>
			<FieldSet id="transportationProducts" infoText="transportationProductsHelp">
				<AddButton @click="openRoundingDialog" buttonText="transportation" :update="true" />
			</FieldSet>
		</Section>
		
		<DialogV2 ref="roundingDialog"
			:title="$t('text.roundingDialog')"
			:confirmLabel="confirmLabel" :cancelLabel="cancelLabel" :onConfirm="setRoundingSettings"
			:onCancel="$refs.roundingDialog?.close?.()"
		>
			<template #content>
				<div>
					<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
					<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
					<v-progress-linear v-show="loading" :model-value="roundingProgress" color="green" height="8"></v-progress-linear>
					<br/>
					<v-label>{{$t('text.generalSettings')}}</v-label>
					<p class="helpText" v-html="$t('text.generalSettingsHelp')"/>
					<br/>
					<v-label><v-icon>mdi-lifebuoy</v-icon>{{$t('text.helpNeeded')}}</v-label>
					<p class="helpText" v-html="$t('text.helpNeededDesc')"/>
					<br/>
					<FieldSet id="roundingPrecision" infoText="roundingPrecisionHelp">
						<Field typeName="GeneralSettings" fieldName="roundingPrecision" v-model="roundingPrecision" class="precision" />
					</FieldSet>
					<br/>
					<FieldSet id="roundingStrategy" infoText="roundingStrategyHelp" >
						<Field typeName="GeneralSettings" fieldName="roundingStrategy" v-model="roundingStrategy" :options="roundingStrategies" class="strategy" />
					</FieldSet>
				</div>
			</template>
		</DialogV2>
		
		<DataDialog v-if="addedValue" ref="editAddedValueSalesChannels"
			v-model="addedValue.fields.websites.de"
			:title="$t('text.salesChannels')"
			:useWrap="true"
		>
			<template #content="{ wrap }">
				<!-- TODO: https://app.zeplin.io/project/604748c2bca41a3e66787ef4/screen/659fed1da16c1ec72109ce93 -->
				<div v-for="(scp, s) of salesChannelProductsLookup" :key="'scp' + s"
					style="display: flex; gap: 10px; align-items: center;"
				>
					<v-checkbox v-model="wrap.model" hide-details :label="scp.salesChannel.name" :value="{ website_id: scp.salesChannel.id, website_name: scp.salesChannel.name }" />
					{{ scp.products.length }} Products
				</div>
			</template>
		</DataDialog>
	</Step>
	</Application>
</template>

<script>
import Step from './Step.vue'
import Section from './Section.vue'
import FieldSet from './FieldSet.vue'
import Field from '../../../components/fields/Field.vue'
import Table from './Table.vue'
import SectionTable from '@/views/applications/packageDesigner/SectionTable.vue'
import DataDialog from '@/components/common/DataDialog.vue'
import AddButton from '@/views/applications/packageDesigner/AddButton.vue'
import Common from '@/mixins/Common.vue'
import TransportationAddedValuesDialog from './TransportationAddedValuesDialog.vue'
import NewPackageDialog from './dialogs/NewPackageDialog.vue'
import PackageSalesChannels from '@/views/applications/packageDesigner/PackageSalesChannels.vue'
import AddedValuesDialogExperimental from './added-values-PoC/AddedValuesDialogExperimental.vue'
import DialogV2 from '@/components/common/DialogV2.vue'
import Alert from '@/components/common/Alert.vue'
import Application from '../Application.vue'

export default {
	name: 'OffersStep',
	components: { TransportationAddedValuesDialog, Section, FieldSet, Field, Step, Table, SectionTable, AddButton, TransportationAddedValuesDialog, NewPackageDialog, PackageSalesChannels, DataDialog, AddedValuesDialogExperimental, DialogV2, Alert, Application },
	mixins: [Common],
	props: {
		modelValue: Object,
		linkedEntries: Object,
		salesChannels: Array,
	},
	data() {
		return {
			model: null,
			salesChannelMismatch: false,
			products: null,
			addedValue: null,
			options: [
				{ label: 'text.edit', icon: 'mdi-pencil', action: 'edit' },
				{ label: 'text.editChannel', icon: 'mdi-pencil', action: 'edit-channel' },
				{ label: 'text.delete', icon: 'mdi-delete', action: 'delete' },
			],
			roundingPrecision: null,
			roundingStrategy: null,
			roundingProgress: 0,
			// transportationProducts: [
			// 	// one of these objects is of type AddedOffer (should come from package.fields.addedValuesTransportation.de)
			// 	{
			// 		fields: {
			// 			name: { de: 'Outward journey', en: 'Outward journey' },
			// 			reductionPercent: { de: 0 },
			// 			seasonalDiscounts: { de: [] },
			// 			maximumRedemptionCount: { de: 1 },
			// 			validityType: { de: 'stay' },
			// 			validDaysBefore: { de: 7 },
			// 			validDaysAfter: { de: 0 },
			// 			showPrice: { de: true },
			// 			stations: { de: [] },
			// 			required: { de: 'optional' },
			// 		}
			// 	},
			// 	{
			// 		fields: {
			// 			name: { de: 'Return journey', en: 'Return journey' },
			// 			reductionPercent: { de: 0 },
			// 			seasonalDiscounts: { de: [] },
			// 			maximumRedemptionCount: { de: 1 },
			// 			validityType: { de: 'stay' },
			// 			validDaysBefore: { de: 0 },
			// 			validDaysAfter: { de: 7 },
			// 			showPrice: { de: true },
			// 			stations: { de: ['123', '123'] },
			// 			required: { de: 'optional' },
			// 		}
			// 	}
			// ],
		}
	},
	watch: {
		model(n) { this.$emit('update:modelValue', n) },
		modelValue(n) { this.model = n },
	},
	computed: {
		addedValueArray: {
			get() {
				return [this.addedValue]
			},
			set(value) {
				this.linkedEntries[value[0]?.sys.id] = value[0]
			}
		},
		transportationProducts: {
			get() {
				const transportationProductIds = this.model.fields.addedValuesTransportation?.de ?? []
				const transportProducts = transportationProductIds.map(entry => this.linkedEntries[entry.sys.id])
				return transportProducts
			},
			set(value) {
				for (const item of value) {
					this.linkedEntries[item.sys.id] = item
				}
			}
		},
		addedValueProducts: {
			get() {
				return this.model.fields.addedValues?.de ?? []
			},
			set(value) {
				this.model.fields.addedValues = { de: value }
			}
		},
		transportationItems() {
			return this.transportationProducts.map((item, index) => {
				// const item = this.linkedEntries[id]

				return {
					id: item.sys.id,
					product: item.fields.name[this.serviceLocale] ?? item.name.de ?? '-',
					// TODO: how is All being taken in the table? does it check somehow every id entered in the dialog for restrictions against some list of stations?
					arrivalDepartureRestrictions: item.fields.stations?.de?.length === 0 ? '-' : item.fields.stations?.de?.join(', ') || this.$t('text.all'),
					reduction: item.fields.reductionPercent?.de > 0 ? item.fields.reductionPercent?.de + ' %' : '-',
					validity: `${item.fields.transportType.de.toLowerCase() == 'outward' ? '-' : (item.fields.validDaysBefore.de || item.fields.validDaysAfter.de) ? ((item.fields.transportType.de.toLowerCase() == 'outward' ? '-' : '+') + (item.fields.transportType.de.toLowerCase() == 'outward' ? item.fields.validDaysBefore.de : item.fields.validDaysAfter.de) + ' ' + this.$t('text.daysShort')) : '-'}`,
					purchase: item.fields.required?.de ? this.$t('text.mandatory') : this.$t('text.optional'),
				}
			})
		},
		transportationColumns() {
			return [
				{ id: 'product', type: 'string' },
				{ id: 'arrivalDepartureRestrictions', type: 'chip' },
				{ id: 'reduction', type: 'string' },
				{ id: 'validity', type: 'string' },
				{ id: 'purchase', type: 'string' },
			]
		},
		addedValueColumns() {
			return [
				{ id: 'product', type: 'string' },
				{ id: 'salesChannels', type: 'string'},
				{ id: 'status', type: 'string' },
				{ id: 'type', type: 'string' },
				{ id: 'reduction', type: 'string' },
				{ id: 'redemption', type: 'string' },
				{ id: 'validity', type: 'string' },
				{ id: 'purchase', type: 'string' },
				{ id: '', type: 'options'}
			]
		},
		addedValueItems() {
			return this.addedValueProducts.map(({ sys: { id } }, index) => {
				const item = this.linkedEntries[id]

				return {
					id: item.sys.id,
					product: item.fields.name[this.serviceLocale] ?? item.fields.name.de ?? '-',
					salesChannels: item.fields.sku?.de?.length === 1 ? item.fields.websites?.de?.length > 0 ? item.fields.websites?.de?.map(website => website.website_name)?.join(', ') : '-' : this.$t('text.multipleProducts'),
					status: item.fields.status?.de ?? '-',
					type: item.fields.validityType?.de ? this.$t(`text.${item.fields.validityType.de}`) : '-',
					reduction: item.fields.reductionPercent?.de > 0 ? item.fields.reductionPercent?.de + ' %' : '-',
					redemption: item.fields.maximumRedemptionCount?.de > 0 ? item.fields.maximumRedemptionCount?.de : '-',
					// Note: validityType can be (stay | more | fixed)
					validity: `${item.fields.validity?.de.toLowerCase() == 'yes' ? this.$t('text.any') : this.$t('text.duringStay')}`,
					purchase: item.fields.purchase?.de ?? '-',
				}
			})
		},
		salesChannelProductsLookup() {
			const r = {}
			if (!this.salesChannels) return r
			for (const salesChannel of this.salesChannels) {
				const products = this.products.filter(p => p.product_websites.some(website => website.product_website_id === salesChannel.id))
				r[salesChannel.id] = { salesChannel, products }
			}
			return r
		},
	},
	methods: {
		updateTransportProducts() {
			this.$refs.transportProductsDialog.open()
		},
		updateAddedValueProducts() {
			this.$refs.addedValuesDialog.open()
		},
		getProducts(val) {
			this.products = val
			this.$emit('get-products', val)
		},
		handleOptionClick(item, action) {
			switch (action) {
				case 'edit':
					this.editAddedValue(item)
					break
				case 'edit-channel':
					this.editAddedValueChannels(item)
					break
				case 'delete':
					this.deleteAddedValue(item.sys.id)
					break
				default:
					break
			}
		},
		editAddedValueChannels(item) {
			// TODO: server call to update the sales channels of the underlying product of the added value
			this.addedValue = this.linkedEntries[item.id]
			this.$nextTick(() => {
				this.$refs.editAddedValueSalesChannels.open()
			})
		},
		deleteAddedValue(id) {
			// TODO: server call to delete the link of the added value
			this.addedValueProducts = this.addedValueProducts.filter((item) => item.sys.id !== id)
		},
		editAddedValue(item) {
			this.addedValue = this.linkedEntries[item.id]
			// TODO: revise this when the linked entries has the sys and fields included
			this.addedValue.id = item.id
			console.log('editAddedValue', this.addedValue)
			this.$nextTick(() => {
				this.$refs.editAddedValueDialog.open()
			})
		},
		async getRoundingStrategies() {
			this.loading = true
			try {
				const roundingStrategies = await this.$httpGet('/settings/roundingStrategies')
				// we need to add a label for the model driven dropdown to show the translatable text for the keys
				roundingStrategies.forEach(strategy => {
					strategy.label = this.$t('text.' + strategy.key)
				})
				this.roundingStrategies = roundingStrategies
			} catch (error) {
				console.error(error)
			}
			this.loading = false
		},
		async setRoundingSettings() {
			this.loading = true
			try {
				const totalAddedValues = this.addedValueProducts.length
				let completedAddedValues = 0

				for (const addedValue of this.addedValueProducts) {
					let addedValueWithoutPrefix = addedValue.sys.id.split('AV_')[1]
					await this.$httpPut(`/packageTravel/addedValue/${addedValueWithoutPrefix}/round`, { clientId: this.$store.state.selectedClient?.sys.id , roundingMode: this.roundingStrategy.de, roundingPrecision: this.roundingPrecision.de})					
					completedAddedValues++
					this.roundingProgress = Math.floor((completedAddedValues / totalAddedValues) * 100)
				}

				this.successTitle = this.$t('text.roundingSettingsSuccess')
				// this.successDetail = this.$t('text.roundingSettingsSuccess')
				await new Promise(resolve => setTimeout(resolve, 3000))
			} catch (error) {
				console.error(error)
				this.errorTitle = this.$t('text.roundingSettingsError')
				this.errorDetail = error
			}
			this.loading = false
		},
		initializeRoundingSettings() {
			const firstAddedValueId = this.model.fields.addedValues?.de[0]?.sys.id
			this.roundingPrecision = this.linkedEntries[firstAddedValueId]?.fields?.roundingPrecision ?? null
			const strategyKey = this.linkedEntries[firstAddedValueId]?.fields?.roundingMode.de ?? null
			this.roundingStrategy = { de: this.roundingStrategies.find(strategy => strategy.key === strategyKey)?.id ?? null }
		},
		openRoundingDialog() {
			this.roundingProgress = 0
			this.$refs.roundingDialog.open()
		},
	},
	async mounted() {
		this.model = this.modelValue

		await this.getRoundingStrategies()
		this.initializeRoundingSettings()
	},
}
</script>

<style scoped>
.loading {
	pointer-events: none;
}
</style>
