<template>
	<div class="NumberField" :class="{ error }">
		<input type="number" class="input" v-model="model" @focus="onFocus" @blur="onBlur" />
		<div v-if="this.field?.control?.settings?.unit" class="unit">{{ this.field?.control?.settings?.unit }}</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'NumberField',
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
	},
	data: () => ({
		model: null,
	}),
	watch: {
		modelValue(n) {
			this.model = n
			this.validate()
		},
		model(n) {
			this.$emit('update:modelValue', Number(n))
		},
	},
	methods: {
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateIn(),
			])
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>

<style scoped>
.NumberField { position: relative; max-width: 100px; }
.unit { position: absolute; top: 8px; right: 8px; color: #333; }
</style>