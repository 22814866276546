<template>
	<div v-if="show" :class="{backdrop}">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading v-model:active="loading" :is-full-page="true" color="#4caf50" />

		<div class="dialog-div">
			<!-- Header -->
			<v-toolbar class="dialog-header">
				<div class="toolbar-title"><v-toolbar-title>{{ titleText }}</v-toolbar-title></div>
				<div class="stepper"><StepBar :steps="steps" /></div>
				<div class="close"><v-btn class="none" elevation="0" @click="closeDialog()"><v-icon color="#646464">mdi-window-close</v-icon></v-btn></div>
			</v-toolbar>

			<!-- Content -->
			<v-layout row wrap class="scrollable dialog-content">
				<v-col class="d-flex overflow-x-auto ma-0" style="padding: 10px;">
					<!-- Type Selection -->
					<div v-if="currentStep===1" style="padding: 0 20px;">
						<p>{{ $t('text.chooseType') }}</p>
						<p class="text" v-html="$t('text.commissionTypeHelp')"/>

						<v-radio-group v-model="selectedType" class="pa-10">
							<v-radio :label="$t('text.setChannelCommission')" value="service_provider" />
							<v-radio :label="$t('text.setCategoryCommission')" value="attribute_set" />
							<v-radio :label="$t('text.setProductCommission')" value="product" />
						</v-radio-group>
					</div>

					<ChannelCommission  v-if="channelCommsVisible"  ref="channelCommission"  :existingCommissions="existingCommissions" :commissionErrors="commissionErrors" :datesDisabled="datesDisabled" @enable-confirm="enableConfirm" class="pa-5"/>
					<CategoryCommission v-if="categoryCommsVisible" ref="categoryCommission" :existingCommissions="existingCommissions" :commissionErrors="commissionErrors" :datesDisabled="datesDisabled" @select-attribute-set="setAttributeSet" @enable-confirm="enableConfirm" class="pa-5"/>
					<ProductCommission  v-if="productCommsVisible"  ref="productCommission"  :existingCommissions="existingCommissions" :commissionErrors="commissionErrors" :datesDisabled="datesDisabled" :serviceProvider="serviceProvider" @select-products="selectProducts" @enable-confirm="enableConfirm" class="pa-5"/>
				</v-col>
			</v-layout>
			<v-divider style="border-color:#646464"/>

			<!-- Actions -->
			<v-toolbar elevation="0" color="white" style="width:100%" class="dialog-footer">
				<v-spacer></v-spacer>
				<v-btn v-if="cancelVisible" class="default mr-3 mb-3" elevation="0" data-cy="comms-wiz-cancel" @click="cancel()">{{$t('text.discardChanges')}}</v-btn>
				<v-btn v-if="backVisible" class="default mr-3 mb-3" elevation="0" data-cy="comms-wiz-back" @click="back()"><v-icon>mdi-arrow-left</v-icon>{{$t('text.back')}}</v-btn>
				<v-btn v-if="nextVisible" class="default mr-3 mb-3" elevation="0" data-cy="comms-wiz-next" :disabled="!isValid" @click="next()">{{$t('text.next')}}<v-icon>mdi-arrow-right</v-icon></v-btn>
				<v-btn v-if="confirmVisible" class="default mr-3 mb-3" elevation="0" data-cy="comms-wiz-confirm" :disabled="!isValid" @click="confirm()">{{$t('text.confirmSelection')}}</v-btn>
			</v-toolbar>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import StepBar from "@/components/common/StepBar.vue"
import Alert from '@/components/common/Alert.vue'
import Dialog from '@/components/common/Dialog.vue'
import ChannelCommission from './ChannelCommission.vue'
import CategoryCommission from './CategoryCommission.vue'
import ProductCommission from './ProductCommission.vue'
import { CommissionType } from '@/plugins/enum.js'
import { CommissionError } from '@/models/commission.ts'
import Common from '@/mixins/Common.vue'
import moment from 'moment'

export default {
	name: 'CommissionWizard',
	components: { Loading, StepBar, Alert, Dialog, ChannelCommission, CategoryCommission, ProductCommission },
	mixins: [ Common ],
	props: {
		serviceProvider: { type: Object, required: true },
		title: String,
		showStepper: Boolean,
		datesDisabled: Boolean,
	},
	data() { return {
		show: false,
		backdrop: true,
		closeOnOuterClick: false,
		loading: false,
		errorTitle: null,
		errorDetail: null,
		currentStep: 1,
		isValid: false,

		titleText: '',
		selectedType: '',
		channelCommsVisible: false,
		categoryCommsVisible: false,
		productCommsVisible: false,

		cancelVisible: false,
		backVisible: false,
		nextVisible: false,
		confirmVisible: false,
      
		steps: [
			{ key: 1, label: this.$t('text.type'), icon: 'mdi-cursor-default', status: 'complete' },
			{ key: 2, label: this.$t('text.choice'), icon: 'mdi-shape', status: 'incomplete' },
			{ key: 3, label: this.$t('text.commission'), icon: 'mdi-cash-multiple', status: 'incomplete' },
		],

		channelCommissions: [],
		categoryCommissions: [],
		productCommissions: [],
		existingCommissions: [],
		commissionErrors: {},
		clientCA: null,
	}},
	watch: {
		show(n) {
			this.fixZindexes(n)
			this.reset()
			// Get Existing Commissions
			if (this.clientCA) {
				this.channelCommissions = this.clientCA.fields.commissions?.de?.filter(comm => comm?.fields?.type === CommissionType.SALES_CHANNEL)
				this.categoryCommissions = this.clientCA.fields.commissions?.de?.filter(comm => comm?.fields?.type === CommissionType.CATEGORY)
				this.productCommissions = this.clientCA.fields.commissions?.de?.filter(comm => comm?.fields?.type === CommissionType.PRODUCT)
			}
		},
		title() {
			this.titleText = this.title
		},
		selectedType() {
			if (this.selectedType != '') {
				this.currentStep = 2
				this.steps[1].status = 'complete'
				this.setSelectedType()
			}
		}
	},
	methods: {
		async confirm() {
			this.loading = true
			let commissionData = {}
			let isCopiedProductCommissions = false

			if (this.selectedType === CommissionType.SALES_CHANNEL) {
				commissionData = this.$refs.channelCommission.sendData()
			}
			else if (this.selectedType === CommissionType.CATEGORY) {
				commissionData = this.$refs.categoryCommission.sendData()
			}
			else if (this.selectedType === CommissionType.PRODUCT) {
				commissionData = this.$refs.productCommission.sendData()
				isCopiedProductCommissions = this.$refs.productCommission.copyCommissions
			}

			if (!commissionData?.commissions?.length) return

			const commissionsToValidate = commissionData?.mainCommissions?.length ? commissionData.mainCommissions : commissionData.commissions
			const isValid = this.validateCommissions(commissionsToValidate)
			
			try {
				if (isValid === true && commissionData.changed === true) {
					if (isCopiedProductCommissions === true && commissionData.commsToDelete?.length) {
						// First delete original commissions if there were any assigned to the selected products before the copy option was selected
						for (const commToDelete of commissionData.commsToDelete) {
							await this.deleteCommission(commToDelete)
						}
					}
					
					if (!this.clientCA?.sys?.id?.length) return this.showError('No client assignment')

					const data = {
						commissions: commissionData.commissions,
						clientAssignmentId: this.clientCA.sys.id
					}

					const res = await this.$httpPost('/commissions', data)
					this.$emit('update', res)
					this.closeDialog()
				}
				this.loading = false
				
			}
			catch (error) {
				this.showError(error)
			}
		},
		async deleteCommission(commission) {
			this.loading = true
			try {
				const res = await this.$httpDelete('/commission', {
						clientAssignmentId: this.clientCA?.sys?.id,
						commission: commission
				})
				this.loading = false
			} catch (error) {
				this.showError(error)
			}
		},
    validateCommissions(commissions) {
      let valid = true
      this.commissionErrors = {}

      for (const commission of commissions) {
        let haveError = false
        const percentageError = commission.fields.percentage.length === 0 ? 'error' : ''

        //check if commission matches another sales channel, valid from and valid to dates
        let matchedChannelValidFromValidTo = commissions.filter(comm => 
          comm.sys.id !== commission.sys.id &&
          comm.fields.peakWebsiteId === commission.fields.peakWebsiteId &&
          comm.fields.validFrom === commission.fields.validFrom &&
          comm.fields.validTo === commission.fields.validTo  && 
          comm.fields.peakEntity === commission.fields.peakEntity)

        if (matchedChannelValidFromValidTo?.length) {
            console.log("matched commission: " + JSON.stringify(commission))
            this.commissionErrors[commission.sys.id] = new CommissionError('error', 'error', 'error', percentageError)
            valid = false
            continue
        } 

        //check if commissions have overlapping dates
        if (commission.fields.validFrom.length || commission.fields.validTo.length) {
          const validFromDate = moment(`${commission.fields.validFrom.split("T")[0]} ${commission.fields.validFrom.split("T")[1]}`, "YYYY-MM-DD HH:mm")
          const validToDate = moment(`${commission.fields.validTo.split("T")[0]} ${commission.fields.validTo.split("T")[1]}`, "YYYY-MM-DD HH:mm")
          
          for (const comm of commissions) {
            if (comm.sys.id === commission.sys.id) continue
            if (comm.fields.peakWebsiteId !== commission.fields.peakWebsiteId) continue
            if (comm.fields.peakEntity?.id !== commission.fields.peakEntity?.id) continue
            if (comm.fields.attributeSet?.id !== commission.fields.attributeSet?.id) continue

            if (comm.fields.validFrom.length || comm.fields.validTo.length) {
              const commValidFromDate = moment(`${comm.fields.validFrom.split("T")[0]} ${comm.fields.validFrom.split("T")[1]}`, "YYYY-MM-DD HH:mm")
              const commValidToDate = moment(`${comm.fields.validTo.split("T")[0]} ${comm.fields.validTo.split("T")[1]}`, "YYYY-MM-DD HH:mm")
          
              if (validFromDate.isBetween(commValidFromDate, commValidToDate) || 
                  validToDate.isBetween(commValidFromDate, commValidToDate)) {
                  console.log("overlapping dates: " + JSON.stringify(comm))
                  this.commissionErrors[commission.sys.id] = new CommissionError('', 'error', 'error', percentageError)
                  valid = false
                  haveError = true
                  break
              }
            }
          }

          if (haveError === true) {
            if (this.$refs.channelCommission) {
              this.$refs.channelCommission.errorMessage = this.$t('text.overlappingDatesError')
            } else if (this.$refs.categoryCommission) {
              this.$refs.categoryCommission.errorMessage = this.$t('text.overlappingDatesError')
            } else if (this.$refs.productCommission) {
              this.$refs.productCommission.errorMessage = this.$t('text.overlappingDatesError')
            }
            continue
          }
        }

        //check if sales channel is selected for channel commission
        if (!haveError) {
          if (commission.fields.type === CommissionType.SALES_CHANNEL && !commission.fields.peakWebsiteId) {
            this.commissionErrors[commission.sys.id] = new CommissionError('error', '', '', percentageError)
            valid = false
          } else {
            if (percentageError.length) {
              this.commissionErrors[commission.sys.id] = new CommissionError('', '', '', percentageError)
              valid = false
            }
          }
        } else {
          console.log("commissions: " + JSON.stringify(commissions))
        }
      }

      return valid
    },
    next() {
      if (this.currentStep === 1) {
        this.setSelectedType()
      } else {
        if (this.selectedType === CommissionType.PRODUCT) {
          this.showProductCommissions()
        }
      }
    },
    back() {
      if (this.selectedType === CommissionType.CATEGORY) {
        if (this.$refs.categoryCommission.selectedAttributeSet) {
          this.$refs.categoryCommission.selectedAttributeSet = null
          this.steps[2].status = 'incomplete'
        } else {
          this.reset()
        }
      } else if (this.selectedType === CommissionType.PRODUCT) {
        if (this.$refs.productCommission.productListVisible === true) {
          this.$refs.productCommission.reset()
          this.reset()
        } else {
          this.steps[2].status = 'incomplete'
          this.$refs.productCommission.productListVisible = true
          this.$refs.productCommission.commissionsVisible = false
          this.cancelVisible = false
          this.nextVisible = true
          this.backVisible = true
          this.confirmVisible = false
        }
        
      } else {
        this.reset()
      }
    },
    enableConfirm(event) {
      this.isValid = event

      if (this.isValid) {
        this.commissionErrors = {}
      }
    },
    setSelectedType() {
      this.channelCommsVisible = false
      this.categoryCommsVisible = false
      this.productCommsVisible = false
      
      if (this.selectedType === CommissionType.SALES_CHANNEL) {
        this.showChannelCommissions()

      } else if (this.selectedType === CommissionType.CATEGORY) {
        this.showCategoryCommissions()
        
      } else if (this.selectedType === CommissionType.PRODUCT) {
        this.titleText = this.$t('text.productBasedCommission')
        this.productCommsVisible = true
        this.cancelVisible = false
        this.nextVisible = true
        this.backVisible = true
        this.confirmVisible = false
      }
    },
    showChannelCommissions() {
        this.titleText = this.$t('text.channelCommission')
        this.channelCommsVisible = true
        this.isValid = false
        
        this.cancelVisible = false
        this.nextVisible = false
        this.backVisible = true
        this.confirmVisible = true
        
        for (let step of this.steps) {
          step.status = 'complete'
        }

        this.existingCommissions = this.channelCommissions
    },
    showCategoryCommissions() {
        this.titleText = this.$t('text.categoryBasedCommission')
        this.categoryCommsVisible = true
        this.isValid = false
        
        this.cancelVisible = false
        this.nextVisible = true
        this.backVisible = true
        this.confirmVisible = false
        
        this.steps[1].status = 'complete'
    },
    showProductCommissions() {
        this.$refs.productCommission.setSelectedProducts()
        this.isValid = false
        
        this.cancelVisible = false
        this.nextVisible = false
        this.backVisible = true
        this.confirmVisible = true
        
        this.steps[2].status = 'complete'
    },
    setAttributeSet() {
      const selectedAttributeSet = this.$refs.categoryCommission.selectedAttributeSet
      
      if (selectedAttributeSet) {
        this.existingCommissions = this.categoryCommissions?.filter(existing => existing.fields.attributeSet.id === selectedAttributeSet.attribute_set_id)
        
        for (let step of this.steps) {
            step.status = 'complete'
          }
          this.nextVisible = false
          this.confirmVisible = true
      } else {
        this.nextVisible = true
        this.confirmVisible = false
      }
    },
    selectProducts(event) {
      this.existingCommissions = []

      if (event?.length) {
        for (const product of event) {
          const existingCommsForProduct = this.productCommissions?.filter(existing => existing.fields.peakEntity.id === product.entity_id) ?? []
          this.existingCommissions = [...this.existingCommissions, ...existingCommsForProduct]
        }
      }
      this.isValid = event?.length > 0 ? true : false
    },
    reset() {
      this.channelCommsVisible = false
      this.categoryCommsVisible = false
      this.productCommsVisible = false

      this.cancelVisible = true
      this.nextVisible = true
      this.backVisible = false
      this.confirmVisible = false
      
      this.currentStep = 1
      this.selectedType = '' 
      this.isValid = false
      this.commissionErrors = {}

      for (let step of this.steps) {
        step.status = 'incomplete'
      }

		this.titleText = this.title
      this.steps[0].status = 'complete'
    },
    cancel() {
      if (this.cancelHandler && typeof this.cancelHandler === 'function') {
        this.cancelHandler();
      }
      this.closeDialog();
    },
    closeDialog() {
      this.reset()

      for (let step of this.steps) {
        step.status = step.key !== 1 ? 'incomplete' : 'complete'
      }

      if (this.closeHandler && typeof this.closeHandler === 'function') {
        this.closeHandler();
      }
      this.show = false;
    },
    backdropClick() {
      if (this.closeOnOuterClick) {
        this.closeDialog()
      }
    },
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style?.['z-index-backedup']) 
						el.$el.style['z-index'] = 1 //el.$el.style['z-index-backup']
				}
			}
		},
	},
	beforeUnmount () {
		this.fixZindexes(false)
	},
	mounted() {
		this.reset()
		this.clientCA = this.serviceProvider.fields.clientAssignments.de.find(ca => ca?.fields?.client?.de?.fields?.shopClientId?.de === this.$store.state.selectedClient.fields.shopClientId.de)
	},
}
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  height: calc(100vh - 64px);
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 64px;
  left: 0;
}

.dialog-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70%;
  height: min(700px, calc(100vh - 20px));
  max-width: calc(100vw - 20px);

  background-color: #ffffff;
  border-radius: 5px !important;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;

  i {
    margin-right: 5px;
  }

  .dialog-header {
    max-height: 72px;
  }

  .dialog-content {
    height: 100%;
  }

  .dialog-footer {
   
    .gradientButton {
      height: 40px !important;
      min-height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
      color: #000;
    }

    .greenButton {
      height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
    }

    .redButton {
      height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.scrollable { overflow-y: auto; overflow-x: hidden; }
.text {
	color: black !important;
	font-family: 'Inter', sans-serif !important;
	font-size: 13pt !important;
}
.v-label {
  color: black !important;
	font-family: 'Inter', sans-serif !important;
	font-size: 13pt !important;
}

.toolbar-title { position:absolute; float:left; margin-left:20px; margin-top: 15px; }
.stepper { position: absolute; float: left; width: 100%; margin-top: 15px; }
.close { position:absolute; right: 0px; }

@media screen and (max-width: 800px) {
	.stepper { display: none; }
	.dialog-div { width: 100%; }
}
</style>

<style>
.dialog-div .v-toolbar-title__placeholder { max-width: 340px; white-space: normal; max-height: 53px; }
</style>