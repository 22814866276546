<template>
	<v-date-picker no-title hide-actions v-if="model"
		class="IsoDatePicker"
		v-model="model"
		height="300px"
		max="2030-12-31"
		min="2020-01-01"
		:disabled="disabled"
	/>
</template>

<script>
import moment from 'moment-timezone'

// this comp is only here to make v-date-picker compatible with iso-dates
// (like 2023-12-31T00:00:00.000Z) instead of js dates (like 2023-12-31)

// TODO: hide the weird headlines

// TODO: timezone handling??
//         picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

export default {
	props: {
		modelValue: String,
		disabled: { type: Boolean, default: false },
		// ASSUMPTION: this timezone is relative to the services local timezone
		// TODO: we will have to add a timezone field to the service and to the service provider and pass it in here
		// TODO: where to get the timezone offset of the service from?
		// TODO: what about DST?
		//tz: { type: String, default: 'America/Los_Angeles' },
		tz: { type: String, default: 'Europe/Vienna' },
		// TODO: warn about mismatch in TZ
	},
	data: () => ({
		model: null,
	}),
	watch: {
		// iso string at target tz -> local Date
		modelValue(n) {
			// the date coming in is an iso date string. it will have a time zone attached, that should match this.tz
			//console.log('IsoDatePicker modelValue 1', n, typeof n, 'tz', this.tz)
			if (!n) return new Date().setHours(0, 0, 0)
			n = moment(n.substring(0, 10)).toDate()
			//console.log('IsoDatePicker modelValue 2', n, typeof n)
			this.model = n
		},
		// local Date -> iso string at target tz
		model(n) {
			// the date coming in is in browser timezone like Sun Jun 11 2023 00:00:00 GMT-0700 (Pacific Daylight Time)
			//console.log('IsoDatePicker model 1', n, typeof n, 'tz', this.tz)
			// we need to bring it into the services time zone - in our case this is easy, as we dont have time, only date
			n = moment.tz(n, this.tz).format('YYYY-MM-DD')
			//console.log('IsoDatePicker model 2', n, typeof n)
			n = moment.tz(n, this.tz).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
			//console.log('IsoDatePicker model 3', n, typeof n)
			this.$emit('update:modelValue', n)
		},
	},
	mounted() {
		if (!this.modelValue) {
			const d = new Date()
			d.setHours(0, 0, 0)
			this.model = d
			return
		}

		// reading the iso string we transform it to the local timezone
		// a string like this comes in 2023-06-11T00:00+01:00 - this means 11.06 at midnight at the service
		let n = this.modelValue
		//console.log('IsoDatePicker mounted 1', n, typeof n, 'tz', this.tz)
		// if the browser is in LA, output = Sun Jun 11 2023 00:00:00 GMT-0700 (Pacific Daylight Time), so the picker control will match
		n = moment(n.substring(0, 10)).toDate()
		//console.log('IsoDatePicker mounted 2', n, typeof n)
		this.model = n
		window.moment = moment
	},
}
</script>

<style>
.IsoDatePicker.v-picker { width: 100% !important; }
.IsoDatePicker .v-picker-title { display: none !important; }
/* we have to style the datepicker much smaller, so 2 of them fit on the screen */
.IsoDatePicker .v-date-picker-controls .v-btn--icon { width: 20px !important; }
.IsoDatePicker .v-date-picker-controls__date { font-size: larger; font-weight: bold; }
.IsoDatePicker .v-date-picker-month { min-width: 100%; max-width: 100%; }
.IsoDatePicker .v-date-picker-month__day { height: 20px; width: 20px; }
.IsoDatePicker .v-date-picker-month__day .v-btn { height: 20px; }
.IsoDatePicker .v-date-picker-month__day .v-btn__underlay { height: 20px; }
.IsoDatePicker .v-date-picker-month__day .v-btn--active .v-btn__underlay { background: #333; width: 30px; height: 30px; top: -5px; left: -5px; border-radius: 20px; z-index: 1; }
.IsoDatePicker .v-date-picker-month__day .v-btn--active .v-btn__content { z-index: 2; }
</style>