<template>
	<Application :loading="loading" :class="{ ServiceDesigner: true, isSideBarFixed }">
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color: transparent; min-width: 40px" @click="goback()"><v-icon>mdi-chevron-left</v-icon></v-btn>
			<h1>{{ title }}</h1>
		</template>

		<!-- TODO: use the slot instead of :navigation for the section navigation.
	wrap a comp SideBar2 around to handle the sectionBus concern?
			:clientStatuses="clientStatuses"
-->
		<SideBar ref="sidebar" displayEditTranslations
			:actions="sidebarActions"
			:translations="translations"
			:notifications="sideBarNotifications"
			:disabled="loading"
			@action="handleAction($event)"
			@edit:translations="$refs.translationWizardDialog?.open"
		>
			<SectionIndex />
		</SideBar>

		<div class="content" style="margin-top: 60px">
			<!-- TODO: we actually want a step-like behaviour
					where we have a persisted "max step"
					and anything after this max step would not be shown as red on errors
			-->
			<StepBar2 :showDividers="false" class="step-bar" />

			<OffersStep v-if="packageDetails" v-model="packageDetails" :linkedEntries="linkedEntries" :salesChannels="salesChannels" @get-products="(val) => products = val" />
			<PackageSpecificStep v-if="packageDetails" v-model="productMock" :salesChannels="salesChannels" :products="products" />
			<TicketsStep v-if="packageDetails" v-model="productMock" :salesChannels="salesChannels" :products="products" />
			<ContentStep v-if="packageDetails" v-model="productMock" :salesChannels="salesChannels" :products="products" />
			<MiscStep v-if="packageDetails" v-model="productMock" :salesChannels="salesChannels" :products="products" />

			<IfFeatureFlag flag="FEATURE_TRANSLATION_WIZARD">
				<!-- Translations incomplete
					:handleConfirm="confirmUnsavedChanges"
					:handleCancel="handleEditTranslations"
				-->
				<TranslationsIncompleteDialog ref="translationsIncompleteDialog"
					:translations="translations"
				/>
				<!-- Translation Wizard -->
				<TranslationWizardDialog ref="translationWizardDialog" typeName="Package" v-model="packageDetails" @update:modelValue="setPackageDetails" />
			</IfFeatureFlag>
			<!-- New Package Dialog -->
			<NewPackageDialog ref="newPackageDialog" v-model="packageDetails" :linkedEntries="linkedEntries" />
		</div>
	</Application>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Dialog from '@/components/common/Dialog.vue'
import SideBar from '@/components/common/SideBar.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import ContactInfo from '@/components/serviceDesigner/ContactInfo.vue'
import GeneralInfo from '@/components/serviceDesigner/GeneralInfo.vue'
import Application from '../Application.vue'
import GuestCardLocation from '@/views/applications/packageDesigner/GuestCardLocation.vue'
import GuestCardSettingsInfo from '@/components/serviceDesigner/GuestCardSettingsInfo.vue'
import IfFeatureFlag from '@/components/ifFeatureFlag/IfFeatureFlag.vue'
import TranslationsIncompleteDialog from '@/components/TranslationsIncompleteDialog.vue'
import TranslationWizardDialog from '@/components/TranslationWizardDialog.vue'
import StepBar2 from './StepBar2.vue'
import SectionIndex from './SectionIndex.vue'
import AddButton from './AddButton.vue'
import TestErrorDispatcher from './TestErrorDispatcher.vue'
import NewPackageDialog from './dialogs/NewPackageDialog.vue'
import PackageSpecificStep from './PackageSpecificStep.vue'
import OffersStep from './OffersStep.vue'
import ContentStep from './ContentStep.vue'
import TicketsStep from './TicketsStep.vue'
import MiscStep from './MiscStep.vue'

export default {
	name: 'PackageDesigner',
	components: { SideBar, LanguageFlag, Dialog, GuestCardSettingsInfo, ContactInfo, GeneralInfo,
		Application, GuestCardLocation, IfFeatureFlag, TranslationsIncompleteDialog, TranslationWizardDialog,
		StepBar2, SectionIndex, AddButton, TestErrorDispatcher, 
		NewPackageDialog, PackageSpecificStep, OffersStep, ContentStep, TicketsStep, MiscStep,
	},
	mixins: [ Common ],
	provide: {
		activePage: 'PackageDesigner',
	},
	data() {
		return {
			showPreview: false,
			serviceProvider: this.$store.state.selectedServiceProvider,
			currentStep: 1,
			changed: {},
			availabilities: [
				{ sys: { id: 'TS1' }, fields: { validFromDate: { de: '2024-01-01' }, validToDate: { de: '2024-01-20' }, }, },
				{ sys: { id: 'TS2' }, fields: { validFromDate: { de: '2024-01-21' }, validToDate: { de: '2024-01-30' }, }, },
			],
			packageDetails: null,
			linkedEntries: null,
			productMock: { fields: {
				title: { de: '' },
				longDescription: { de: '' },
				shortDescription: { de: '' },
				importantInformation: { de: '' },
				faqGroup: { de: null },
				imageGallery: { de: [] },
				medias: { de: [] },
				startDate: { de: '' },
				endDate: { de: '' },
				pricingType: { de: 'sum' },
				validityType: { de: 'fixed' },
				personalisations: { de: [ 'name', 'dateOfBirth' ] },
				name: { de: '' },
				bookableDaysInAdvance: { de: 0 },
				taxClass: { de: null },
				addedValues: { de: [
					// TODO: proper mock for Mehrwerte
					// TODO: should we really model Mehrwerte as Entries? its not saved as entry
					//       CON: its more complicated
					//       PRO: we can use decorators and other stuff
					{ sys: { id: 'AV1' }, fields: { title: { de: 'MW Product A' }, sku: { de: 'MY.SKU.A' } } },
					{ sys: { id: 'AV2' }, fields: { title: { de: 'MW Product B' }, sku: { de: 'MY.SKU.B' } } },
				] },
				salesChannels: { de: [ 2, 9 ] },
				businessHours: { de: null },
				emphasize: { de: null },
				durationMin: { de: null },
				durationMax: { de: null },
			}},
			testValidationValue: '',
			salesChannels: null,
			products: null,
			// products: [
			// 	// TODO: use proper model
			// 	{ id: 1, name: 'Product A', status: 'active', sku: 'MY.SKU.A', websites: [3, 9] },
			// 	{ id: 1, name: 'Product B', status: 'active', sku: 'MY.SKU.B', websites: [10, 9] },
			// ],
		}
	},
	computed: {
		isActive() {
			return this.packageDetails?.active?.de
		},
		sideBarNotifications() {
			let notifications = []
			return notifications
		},
		sidebarActions() {
			const actions = []

			//Service Provider View
			if (!this.userIsOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', datacy: "save" })
				
				if (!this.isActive) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishPackage'), function: 'submit', params: 'activate', datacy: "activate" })
				}

				actions.push({ icon: 'mdi-delete', style: 'red', dark: true, title: this.$t('text.deletePackage'), function: 'confirmDelete', datacy: "delete" })

				if (this.isActive) {
					actions.push({ icon: 'mdi-eye-off', style: 'outline', dark: false, title: this.$t('text.deactivatePackage'), function: 'deactivateProduct', params: 'deactivate', datacy: "deactivate" })
				}

				// TODO: Not implementing for now. Needs discussion with Kerstin as per Miro app comments
				// if (!this.productIsExternal && this.$store.state.selectedServiceProvider?.sys?.id && this.$store.state.selectedProduct?.sys?.id) {
				// 	actions.push({ icon: 'mdi-plus-box-multiple', style: 'none', dark: false, title: this.$t('text.duplicatePackage'), function: 'duplicatePackage', datacy: "duplicate" })
				// }

				actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}
			
			//Home Operator View
			if (this.userIsHomeOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', datacy: "save" })
				
				if (!this.isActive && (!this.serviceProvider.addl || this.serviceProvider.addl.statusClient == 'approved')) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishPackage'), function: 'submit', params: 'approve', datacy: "approve" })
				}

				actions.push({ icon: 'mdi-delete', style: 'red', dark: true, title: this.$t('text.deletePackage'), function: 'confirmDelete', datacy: "delete" })

				if (this.isActive) {
					actions.push({ icon: 'mdi-eye-off', style: 'outline', dark: false, title: this.$t('text.deactivatePackage'), function: 'deactivateProduct', params: 'deactivate', datacy: "deactivate" })
				}
				
				actions.push({ icon: 'mdi-archive', style: 'outline', dark: false, title: this.$t('text.archivePackage'), function: 'deactivateProduct', params: 'archive', datacy: "archive" })
				actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}

			//External Operator View
			if (this.userIsExternalOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', datacy: "save" })
				
				if (this.status != 'active' && (!this.serviceProvider.addl || this.serviceProvider.addl.statusClient == 'approved')) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishPackage'), function: 'submit', params: 'approve', datacy: "approve" })
				}

				actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}

			return actions
		},
		translations() {
			let translationProduct = JSON.parse(JSON.stringify(this.productMock))

			return this.getTranslationStatus('product', translationProduct)
		},
		title() {
			const name = this.productMock?.fields?.name?.de
			return name ? name : this.$t('text.newPackage')
		},
	},
	methods: {
		// TODO: Implement sidebarActions methods
		submit() {},
		confirmDelete() {},
		deactivateProduct() {},
		togglePreview() {
			this.showPreview = !this.showPreview
		},
		goback() {
			this.$router.push('/packages')
		},
		showError(error) {
			this.showLoader()
			const detail = error?.response?.data?.error ?? error

			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
		openCreateNewPackageDialog() {
			this.$refs.newPackageDialog.open()
		},
		handleAction(event) {
			this[event.function](event.params)
		},
		setLocale(code) {
			this.serviceLocale = code
		},
		async loadSalesChannels() {
			/*this.salesChannels = [
				{ id: 1, code: 'konfig', name: 'Config' },
				{ id: 2, code: 'app', name: 'App' },
				{ id: 3, code: 'test', name: 'Test' },
				{ id: 4, code: 'other', name: 'Other' },
			]*/
			const clientId = this.$store.state.selectedClient.sys.id
			const r = await this.$httpGet(`/packageTravel/getSalesChannels?clientId=${clientId}`)
			this.salesChannels = r.salesChannels
			console.log('SalesChannels', r)
		},
		async getPackageDetails() {
			this.loading = true
			const clientId = this.$store.state.selectedClient.sys.id
			const packageId = this.$route.params.id
			const { entry, linkedEntries } = await this.$httpGet(`/packageTravel/package/${clientId}/${packageId}`)
			// TODO: this response will probably change
			this.packageDetails = entry
			this.linkedEntries = linkedEntries
			console.log('PackageDetails', this.packageDetails, this.linkedEntries)

			if (!this.packageDetails?.fields?.addedValues?.de?.length) this.openCreateNewPackageDialog()

			this.loading = false
		},
		setPackageDetails(data) {
			this.packageDetails = data
		},
	},
	async mounted() {
		await this.getPackageDetails()
		/*
		if (!this.$store.state.locales?.length) {
			await this.$root.app.getLocales()
		}
		*/
		await this.loadSalesChannels()
	},
}
</script>

<style scoped>
h1 { flex-shrink: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 13pt; font-weight: bold; }
.content { padding-top: 1px; width: 100%; }
.step-bar { position: fixed; right: 0; left: 0; top: 76px; background: white; }
button.forTable { border: 1px solid #1c455e; color: #1c455e; border-radius: 4px; padding: 8px 15px; font-size: 16px; line-height: initial; }
button.forTable:hover { background: #1c455e33; }
button.forTable .v-icon { zoom: 0.85 !important; }
</style>

<style>
.todo { background-color: yellow; font-size: 10px; }
</style>