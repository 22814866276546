// TODO: serve this file for integrators to include directly
// TODO: mock version of this file for standalone testing

// This class handles the communication between Host and Guest.
// Both Host and Guest have an instance of this class and can call each other through its .api interface.

// call (1) -> (2) -> (3) -> (4) -> (5) -> (6) -> (7) function
//      (12)  <--  (11)  <--  (10)  <--  (9)  <-- (8) return

export default class Mys {
	constructor(origin, apiImplementation, window) {
		const messageBus = new MessageBus(
			origin,
			async (message) => {
				if (!apiImplementation[message.method])
					throw new Error('given apiImplementation does not support method [' + message.method + ']')
				// (7) we actually make the requested function call
				const r = await apiImplementation[message.method](message.param)
				return r
			},
			window
		)
	
		// TODO: check if the api implementation can be exploited because it is a proxy now
		return new Proxy(this, {
			get: (target, method) => {
				// when an integrator wants to have a dynamic look at the interface, he should use .api
				if (method == 'api') return target
				if (method == 'destroy') return () => messageBus.destroy()
		
				// any function call is forwarded to the messageBus as message
				return async (param) => {
					if (method == 'toJSON') return
					// (1) a function call comes first in here
					if (!target[method]) throw new Error('method [' + method + '] not available on MYS API')
					return await messageBus.call(method, param, 'REQUEST')
					// (12) and respond with the result
				}
			}
		})
	}

	// TODO: we dont get syntax completion when integrating - why? this was the actual intention with having this interface.
	// ATT: this only declares the interface for convenience - the actual calls are handled by the proxy above.
	async getClient() {}
	async getClients() {}
	async setClient() {}
	async getIdentity() {}
	async getLocale() {}
	async getLocales() {}
	async localeChanged() {}
	async getHash() {}
	async setHash() {}
	async userHasAppAccess() {}
	async getUser() {}
	async getAppConfig() {}
}

class MessageBus {
	messageHandlers
	origin
	listener
	debug = 1
  
	constructor(origin, inHandler, win) {
		this.messageHandlers = {}
		this.origin = origin
		this.window = win ? win : window.parent

		this.listener = async (event) => {
			var message = event.data
			try {
				// TODO: can we also pass non-string-messages? vue seems to do that.
				if (typeof message != 'string') return
				// (5) on the other side we receive the message
				message = JSON.parse(message)
//				if (this.debug > 0) console.log('IN', message)
				if (message.type == 'REQUEST') {
					try {
						// (6) we call the dispatcher
						const param = await inHandler(message)
						// (8) we send the result back
						this.call(message.method, param, 'RESPONSE', message.id)
					}
					catch (e) {
						// (8.x) on exception we send back an exception object
						const param = { error: e, type: e.constructor.name, message: e.message }
						this.call(message.method, param, 'ERROR', message.id)
					}
				}
				else if (message.type == 'RESPONSE' || message.type == 'ERROR') {
					// (10) we call the callback
					const handler = this.messageHandlers[message.id]
					if (handler === null) throw new Error('receiver message [' + message.id + '] timed out!')
					if (handler === undefined)
					throw new Error('no receiver found for message [' + message.id + '].')
					delete this.messageHandlers[message.id]
					await handler(message)
				}
			}
			catch (e) {
				//console.error('cant parse message', message)
				return
			}
		}
		window.addEventListener('message', this.listener, false)
	}
  
	async call(method, param, type, id) {
		return await new Promise((resolve, reject) => {
			// (2) then we wrap the message
			if (!id) id = 'MSG-' + Math.random()
			if (type == 'REQUEST') {
				// (3) we install a callback for the generated message id
				this.messageHandlers[id] = (message) => {
					// (11) we fulfil the promise
					if (message?.type == 'ERROR') reject(message.param)
					else resolve(message.param)
				}
				// TODO: reject on timeout
			}
			const message = { type, id, method, param }
			// (4) and send the message to the other side
			if (this.debug > 0) console.log('OUT', message, this.origin)
				this.window.postMessage(JSON.stringify(message), this.origin)
			if (type == 'RESPONSE' || type == 'ERROR') {
				// (9) on the way back we dont need a confirmation
				resolve()
			}
		})
	}

	destroy() {
		window.removeEventListener('message', this.listener)
	}
}