<template>
	<div class="Table">
		<div v-if="hasNoData">{{ $t('text.' + noDataText) }}</div>
		<v-data-table v-else
			:headers="headers"
			:items="model"
			:no-data-text="$t('text.' + noDataText)"
			class="editableTable"
		>
			<template v-slot:item="{ item }">
				<tr >
					<td v-for="column of columns" :key="column.id ?? column">
						<template v-if="typeId">
							<!-- {{ item.fields.ageGroup.de }} -->
							<Field :typeName="typeId" :fieldName="column.id ?? column" v-model="item.fields[column.id ?? column]" 
								:options="[
									{ id: '1', label: 'Adult (18+)' },
									{ id: '2', label: 'Child (0-17)' },
									{ id: '3', label: 'Senior (65+)' },
								]"
							/>
						</template>
						<template v-else>
							{{ item[column.key] }}
						</template>
						{{ item[column] }}
					</td>

					<td>
						<slot name="deleteItem" :item="item" ></slot>
					</td>
					<!-- <template v-for="(column, index) in headers" :key="index">
						<td>
							<template v-if="column.type === 'date'">
								{{ formatDate(item[column.key]) }}
							</template>
							<template v-else-if="column.type === 'string'">
								<template v-if="!column.editable">
									{{ item[column.key] }}
								</template>
								<template v-else>
									<v-text-field
										v-model="item[column.key]"
										:label=" $t('text.edit')"
										single-line
										density="compact"
										variant="outlined"
										hide-details
										@click="open(item)"
										@keydown.enter="save(item)"
										@blur="save(item)"
									></v-text-field>
								</template>
							</template>
							<template v-else-if="column.type === 'number'">
								<template v-if="!column.editable">
									{{ item[column.key] }}
								</template>
								<template v-else>
									<v-text-field
										v-model.number="item[column.key].de"
										:rules="numberRules"
										type="number"
										density="compact"
										variant="outlined"
										hide-details
										:label=" $t('text.edit')"
										single-line
										:min="0"
										@click="open(item)"
										@keydown.enter="save(item, column)"
										@blur="save(item, column)"
									></v-text-field>
								</template>
							</template>
							<template v-else-if="column.type === 'options'">
								<slot name="deleteItem" :item="item" ></slot>
							</template>
							<template v-else-if="column.type === 'dropdown'">
								<template v-if="!column.editable">
									{{ item[column.key][this.serviceLocale] ?? item[column.key] }}
								</template>
								<template v-else>
									<v-select
										v-model="item[column.key]"
										:items="column.options"
										:rules="dropdownRules"
										return-object
										:item-title="(item) => item?.[serviceLocale] || $t('text.select')"
										density="compact"
										variant="outlined"
										hide-details
										@click="open(item)"
										@keydown.enter="save(item)"
										@blur="save(item, column)"
									></v-select>
								</template>
							</template>
						</td>
					</template> -->
				</tr>
				<!-- save button that logs the data from the model in the table for testing -->
				<!-- <v-btn @click="save(item)">Show Model</v-btn> -->
			</template>
			<template #bottom>
				<TableFooter v-if="!hideFooter" :offset="offset" :limit="limit" :results="items" :total="total"
			@update:offset="$emit('update:offset', $event)" @update:limit="$emit('update:limit', $event)" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import PopoverMenu from '@/components/common/PopoverMenu.vue'
import TableFooter from '@/components/common/tables/TableFooter.vue'
import Field from '../../../components/fields/Field.vue'
import FieldSet from './FieldSet.vue'

export default {
	name: 'EditableTable',
	mixins: [Common],
	components: { PopoverMenu, TableFooter, Field, FieldSet },
	props: {
		modelValue: Array,
		columns: {
			type: Array,
			required: true,
		},
		typeId: String,
		// items: {
		// 	type: Array,
		// 	required: true,
		// },
		noDataText: {
			type: String,
			default: 'noData',
		},
		hideFooter: {
			type: Boolean,
			default: true,
		},
	},
	data: () => ({
		model: [],
		options: [
			{ label: 'text.edit', icon: 'mdi-pencil', action: 'edit' },
			{ label: 'text.delete', icon: 'mdi-delete', action: 'delete' },
		],
		limit: 15,
		offset: 0,
		numberRules: [
			v => !!v || 'This field is required',
			v => (v && v >= 0) || 'Value must be greater than or equal to 0',
			v => (v && !isNaN(v)) || 'Value must be a number',
		],
		dropdownRules: [
			v => !!v || 'This field is required',
		],
	}),
	computed: {
		hasNoData() {
			return this.model.length === 0
		},
		headers() {
			const r = this.columns.map(column => {
				return {
					title: column?.id !== '' ? this.$t('text.' + column.id ?? column) : '',
					key: column.id ?? column,
					// width: column.width ?? '20%',
					sortable: column?.id == '' ? false : true,
					cellClass: column.id ?? column,
					type: column.type ?? 'string',
					// editable: true,
					// options: column.options ?? [],
				}
			})
			r.push({
				title: '',
				key: '',
				sortable: false,
			})
			return r
		},
		total() {
			return this.model.length
		},
	},
	// wtch deeply on items, when an item or its properties change console log
	watch: {
		// items: {
		// 	handler: function (val, oldVal) {
		// 		console.log('items changed', val, oldVal)
		// 	},
		// 	deep: true,
		// },
		modelValue(n) { this.model = n; console.log('parent model updated:', n) },
		// model(n) { this.$emit('update:modelValue', n) }
	},
	methods: {
		handleOptionClick(id, action) {
			this.$emit('handleOptionClick', id, action)
		},
		save(item, column) {
			console.log('save', item, column)
			if (!this.validate(item, column)) {
				return; // Validation failed, do not save/update the model
			}
			// this.$emit('update-table-items', this.items)
			this.$emit('update:modelValue', this.model)
			console.log('child model updated:', this.model)
		},
		cancel(item) {
			// Handle cancel logic here
			console.log('cancel', item)
		},
		open(item) {
			// Handle open logic here
			console.log('open', item)
		},
		validate(item, column) {
			// Perform validation logic here
			if (column.type === 'number') {
				let value = item[column.key].de
				if (isNaN(value) || !Number.isInteger(value) || value < 0) {
					// TODO: Display an error message or prevent saving/updating the model
					console.log('Invalid number')
					// TODO: ATM I am defaulting to 0 when the number is not valid (ex: 2--) otherwise when using the v-model approach on the parent component the value is set to an empty string, which can be problematic when saving the model
					value = 0
					// return false
				}
				item[column.key].de = value
			} else if (column.type === 'dropdown') {
				const value = item[column.key]
				if (!value[this.serviceLocale]) {
					// TODO: Display an error message or prevent saving/updating the model
					console.log('Dropdown field cannot be empty')
					// TODO: should we default to the first option in the dropdown if no dropdown items were selected?
					return false
				}
			}
			// TODO: Add more validation logic for other fields if needed
			return true
		},
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style scoped>
.itemRow {
	padding: 10px 10px;
	border-radius: 4px;
	width: 100%;
	align-items: center;
}

.itemRow:hover {
	background-color: #f2f2f2;
	cursor: pointer !important;
}

.editableTable {
	width: 100%;
	padding: 10px 10px;
}

.editableTable th {
	text-align: left;
	padding: 15px 10px;
	/* border-bottom: 1px solid #ccc; */
}

.editableTable td {
	padding: 15px 10px;
}

.dotsIcon:hover {
	transform: scale(1.2);
}

.optionItem:hover {
	cursor: pointer !important;
}

/* TODO: I did not find a way to overwrite the default vuetify table in order to hide the borders in table data items */
/* ::v-deep .v-data-table-header {
	font-weight: bold !important;
	color: black !important;
} */
/* .v-data-table > table > tbody > tr > td {
	border-bottom: none !important;
	border-collapse: collapse !important;
} */

/* TODO: overwrite default table headers to have bold and black text */
/* ::v-deep .v-data-table-header th {
	font-weight: bold !important;
	color: black !important;
} */
</style>