
<template>
	<v-container fluid class="pa-0">
	  <span class="audit-entry-name">{{schema.contentTypes[contentType].fields.find(x=>x.id===propKey).name}}</span><br/>
  
		  <!-- Render Asset -->
		  <span v-if="isAsset()">
			<div style="width:100%;text-align:center">
			  <img :src="assetUrl()" height="100px" width="100px" contain/>
			</div>
			<br/>
			{{ diffEntry.sys.id }}
		  </span>
  
			<!-- Render Referenced Entry -->
		  <span v-if="isLinkedArray()">
			<div class="entries">
			  <div class="entry" v-for="val of diffEntry" :key="val.sys ? val.sys.id : val.id">
				<v-row class="pa-3">
				  <v-col class="header">{{ 'ID: ' + val.sys.id }}</v-col>
				</v-row>
				<v-divider></v-divider>
				<v-row class="pa-3">
				  <v-col>{{ val.fields?.title?.de ? val.fields.title.de : 'No title' }}</v-col>
				</v-row>
			  </div>
			</div>
		  </span>
  
		  <span v-if="isLinkedEntry()">
			<div class="entries">
			  <div class="entry">
				<v-row class="pa-3">
				  <v-col class="header">{{ 'ID: ' + diffEntry.sys.id }}</v-col>
				</v-row>
				<v-divider></v-divider>
				<v-row class="pa-3">
				  <v-col>{{ diffEntry.fields?.title?.de ? diffEntry.fields.title.de : 'No title' }}</v-col>
				</v-row>
			  </div>
			</div>
		  </span>
  
		  <span v-if="isText()">
			<p v-html="formatText()"/>
		  </span>
	</v-container>
  </template>
  
  <script>
  import Asset from '@/components/auditLog/Asset.vue'
  import Common from '@/mixins/Common.vue'
  
  
  export default {
	  name: "DiffData",
	  mixins: [ Common ],
	components: { Asset },
	  
	  props: {
		  entry: { type: Object },
	  contentType: { type: String },
	  propKey: { type: String }
	  },
  
	  data() {
		  return {
		diffEntry: null,
		asset: null
		  }
	  },
  
	async mounted() {
	  if (this.entry) {
		if (Object.keys(this.entry).length === 1) {
		  //Check booleans
		  if (typeof this.entry[Object.keys(this.entry)[0]] === 'boolean') {
			this.diffEntry = this.entry[Object.keys(this.entry)[0]].toString()
		  } else {
			this.diffEntry = this.entry[Object.keys(this.entry)[0]]
		  }
		} else {
		  this.diffEntry = this.entry
		}
  
		if (this.diffEntry?.sys?.linkType === 'Asset') {
		  this.asset = await this.$httpGet(`/asset/${this.diffEntry.sys.id}`)
		}
	  }
	},
  
	  methods: {
	  isAsset() {
		if (this.diffEntry?.sys?.linkType === 'Asset') {
		  return true
		}
		return false
	  },
	  isLinkedArray() {
		if (Array.isArray(this.diffEntry) && this.diffEntry[0]?.sys?.id) {
		  return true
		}
		return false
	  },
	  isLinkedEntry() {
		if (this.diffEntry?.sys?.id && this.diffEntry?.sys?.type === 'Entry') {
		  return true
		}
		return false
	  },
	  isText() {
		if (!this.isAsset(this.diffEntry) && !this.isLinkedArray(this.diffEntry) && !this.isLinkedEntry(this.diffEntry)) {
		  return true
		}
		return false
	  },
	  assetUrl() {
		return this.asset?.fields?.file?.de?.url
	  },
	  formatText() {
		if (this.diffEntry) {
	
		  if (typeof this.diffEntry === 'object') {
			let strDiffEntry = JSON.stringify(this.diffEntry)
		 
			if (Object.keys(strDiffEntry).length > 1) {
			  strDiffEntry = strDiffEntry.replace("{","").replace("}","")
			  strDiffEntry = strDiffEntry.replace("\"en\":","en: ").replace("\"de\":","de: ").replace("\"fr\":","fr: ").replace("\"it\":","it: ").replace("\"nl\":","nl: ")
			  strDiffEntry = strDiffEntry.replaceAll(",", "<br/>")
			}
			return strDiffEntry
		  } else {
			return this.diffEntry
		  }
		}
		return ''
	  }
	  }
  }
  </script>
  
  <style scoped>
  h3 { font-weight: normal; color: rgb(17, 27, 43); }
  .label { width: 15%; font-size: 13pt; font-weight: bold; color: rgb(17, 27, 43); }
  .detail { font-size: 13pt; color: rgb(17, 27, 43); }
  .audit-entry-container { -moz-box-flex: 1; flex-grow: 1; max-width: 100%; margin-top:20px; }
  .audit-entry-header-box { max-width: 50%; height: 48px; display: flex; flex: 1 0 0%; position: relative; background-color: rgb(247, 249, 250); border: 1px solid rgb(219, 219, 219); }
  .audit-entry-header { max-width: 50%; position: absolute; inset: 0px; cursor: pointer; padding-left: 20px; padding-top: 13px; }
  .audit-entry-row { display: flex; padding: 0px; }
  .audit-entry { max-width: 50%; width: 50%; padding: 20px; flex: 1; border: 1px solid rgb(219, 219, 219); }
  .audit-entry-name { line-height: 1.5; color: rgb(90, 101, 124); }
  
  .Field { padding-left: 10px; border-left: 3px solid #ddd; }
  .entry { border: 1px solid #c0c0c0; margin-bottom: 10px; border-radius: 5px; }
  .many .entries { display: flex; flex-wrap: wrap; }
  .many .entry { flex: 0 0 200px; margin-right: 10px; }
  .fieldTitle { color: gray; font-size: smaller; }
  .header { color: gray; font-size: smaller; text-transform: capitalize; }
  .pickerCard { border-radius: 10px; border: 0; padding: 15px; }
  .addEntry { border: 1px dashed silver; padding: 20px; border-radius: 5px; text-align: center; }
  </style>
  