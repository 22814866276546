<template>
	<div>
		<Disclosure :title="$t('text.myInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" :expanded="true" data-cy="myInfo" ref="SECTION_myInfo">

<!-- WIP: data driven ui
<Field typeName="Product" fieldName="title" v-model="model.fields.title" />
<Field typeName="Product" fieldName="shortDescription" v-model="model.fields.shortDescription" />
<Field typeName="Product" fieldName="longDescription" v-model="model.fields.longDescription" />
-->

			<p class="subTitle" v-html="$t('text.myInfoSubTitle')"/>
			<Field typeName="BusinessProfile" fieldName="title" data-cy="title" v-model="model.fields.title" :error-messages="businessNameErrors"
				@update:modelValue="validateBusinessName" @blur="checkBusinessNameExists" />

			<div class="field left-border" v-if="contactInfoAddress">
				<ContactAddress
					ref="ContactAddress"
					:title="$t('text.businessAddress')"
					:contactAddress="contactInfoAddress.fields.contactAddresses.de[0]"
					:location="model.fields.location.de"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.businessPhone', { format: phoneFormat})}}</v-label>
					<v-text-field
						v-if="contactInfoPhone"
						variant="outlined" density="compact" hide-details
						v-model="contactInfoPhone.fields.contactDetail.de"
					/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.businessEmail')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field
					variant="outlined"
					density="compact"
					v-if="contactInfoEmail"
					:hide-details="businessEmailErrors.length===0"
					v-model="contactInfoEmail.fields.contactDetail.de"
					@update:modelValue="validateBusinessEmail"
					:error-messages="businessEmailErrors"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.website')}}</v-label>
				<v-text-field variant="outlined" density="compact"
					v-if="contactInfoWebsite"
					:hide-details="urlErrors.length===0"
					v-model="contactInfoWebsite.fields.contactDetail.de"
					@update:modelValue="validateUrl"
					:error-messages="urlErrors"
				/>
			</div>
		</Disclosure>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import eventBus from '@/utils/eventBus.js'
import isEqual from 'lodash/isEqual'
import ContactAddress from '@/components/common/ContactAddress.vue'
import { ekContactAddress } from '@/components/common/ContactAddress.vue'
import { ekLocation } from '@/components/common/LocationFieldOld.vue'
import Field from '@/components/fields/Field.vue'

export default {
	name: 'MyInformation',
	components: { Disclosure, ContactAddress, Field },
	mixins: [ Common ],
  props: {
	serviceProvider: {
		  type: Object,
		  required: true
	},
	updateModel: Boolean
  },
  data() {
    return {
		model: {},
		initData: {},
		data: {},
		sectionMessage: {
			error: false,
			message: ''
		},
		businessNameAllowed: true,
		contactInfoPhone: {sys: {id:'id_1'}, fields:{contactInfoType: {de:{fields:{type:{de:'Phone'}}}},contactDetail:{de:''}}},
		contactInfoEmail: {sys: {id:'id_2'}, fields:{contactInfoType: {de:{fields:{type:{de:'E-Mail'}}}},contactDetail:{de:''}}},
		contactInfoWebsite: {sys: {id:'id_3'}, fields:{contactInfoType: {de:{fields:{type:{de:'Website'}}}},contactDetail:{de:''}}},
		contactInfoAddress: {
			sys: {id:'id_4'},
			fields:{
			contactInfoType: {de:{fields:{type:{de:'Address'}}}},
			contactAddresses:{
				de:[{
				sys: {id:'id_5'},
				fields: {
					streetAddress:{de:''},
					zipCode:{de:''},
					city:{de:''},
					country:{},
				}
				}]
			}
			}
		},
		businessEmailErrors: [],
		businessNameErrors: [],
		urlErrors: [],
    }
  },
    watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.setInitData()
			this.setContactInfoDetails()
		},
		'businessNameAllowed': {
			handler: function (newVal) {
				newVal === false ? this.validateBusinessName() : this.businessNameErrors = []
			},
		},
  },
  created() {
	  	this.model = this.valueToModel(this.serviceProvider)
		this.setInitData()
		this.setContactInfoDetails()

		eventBus.$on("contact-info-name-changed", (contactInfoName) => {
			let index = this.model.fields.contactInfos.de.findIndex(contactInfo => contactInfo.fields.contactInfoType.de.fields.type.de === "Name")
			if (index > -1) {
				this.model.fields.contactInfos.de[index] = contactInfoName
			} else {
				this.model.fields.contactInfos.de.unshift(contactInfoName)
			}
		})
  },
  beforeUnmount () {
    eventBus.$off('contact-info-name-changed')
  },
  methods: {
	valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
	setInitData() {
		const initModel = JSON.parse(JSON.stringify(this.model))

		this.initData = {
			title: initModel.fields.title,
			contactInfos: initModel.fields.contactInfos,
			location: initModel.fields.location
		}
	},
	sendData() {
		this.data = {
			title: this.model.fields.title,
			contactInfos: this.model.fields.contactInfos,
			location: this.model.fields.location
		}

		this.data.changed = !isEqual(this.data, this.initData)
		return this.data
    },
	validateAllFields() {
		this.resetSectionError(this.sectionMessage)

		const addressError = ekContactAddress(this.contactInfoAddress.fields.contactAddresses.de[0])
		const locationError = ekLocation(this.model.fields.location.de)
		const isBusinessNameValid = this.validateBusinessName()
		const isBusinessEmailValid = this.validateBusinessEmail()
		const isUrlValid = this.validateUrl()

		if (addressError || locationError || !isBusinessNameValid || !isBusinessEmailValid || !isUrlValid) {
			console.log('MyInformation.validateAllFields', 'addressError', addressError, 'locationError', locationError, 'isBusinessNameValid', isBusinessNameValid, 'isBusinessEmailValid', isBusinessEmailValid, 'isUrlValid', isUrlValid)
			this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			return false
		}
		return true
	},
	validateBusinessName() {
		let isValid = true
		this.businessNameErrors = []

		if (!this.validateTextLocales(this.model.fields.title)) {
			isValid = false
			this.businessNameErrors.push(this.$t('text.businessNameRequired'))
		}
		if (this.model.fields.title.de.length > 128) {
			isValid = false
			this.businessNameErrors.push(this.$t('text.businessNameMaxChars'))
		}
		if (!this.businessNameAllowed) {
			isValid = false
			this.businessNameErrors.push(this.$t('text.businessNameExists'))
		}

		if (isValid === true) {
// TODO: this is a side-effect and should not be done here (rather in an onchange or so).
//       this causes an error also:
//       "TypeError: Cannot read properties of null (reading 'emitsOptions')"
			//this.$emit('update-business-name', this.model.fields.title)
		}
		return isValid
	},
	validateBusinessEmail() {
      let isValid = true
      this.businessEmailErrors = []
      if (this.contactInfoEmail.fields?.contactDetail?.de && !this.validateEmail(this.contactInfoEmail.fields.contactDetail.de)) {
        isValid = false
        this.businessEmailErrors.push(this.$t('text.invalidEmail'))
      } else if (this.contactInfoEmail.fields?.contactDetail?.de === '') {
		isValid = false
		this.businessEmailErrors.push(this.$t('text.emailRequired'))
	  }
      return isValid
    },
	validateEmail(email) {
      let re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
      return re.test(String(email).toLowerCase());
    },
	validateUrl() {
		let isValid = true
		this.urlErrors = []
		if (this.contactInfoWebsite?.fields?.contactDetail?.de) {
				var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
				'(\\#[-a-z\\d_]*)?$','i'); // fragment locator

				if (!pattern.test(this.contactInfoWebsite.fields.contactDetail.de)) {
					isValid = false
					this.urlErrors.push(this.$t('text.urlInvalid'))
			}
		}			

		return isValid
	},
	
	setContactInfoDetails() {
		const sp = this.model.fields

		if (sp.contactInfos?.de?.length > 0) {
			for (let contactInfo of sp.contactInfos?.de ?? []) {
				const type = contactInfo.fields.contactInfoType.de.fields.type.de
				if (type === "Name") {
				this.contactInfoName = contactInfo ?? { de: '' }
				} else 
				if (type === "Phone") {
				this.contactInfoPhone = contactInfo ?? { de: '' }
				} else if (type === "E-Mail") {
				this.contactInfoEmail = contactInfo ?? { de: '' }
				} else if (type === "Website") {
				// ATT: we are fixing a data error here - maybe we should do this for every type?
				if (!contactInfo.fields.contactDetail) contactInfo.fields.contactDetail = { de: '' }
				this.contactInfoWebsite = contactInfo ?? { de: '' }
				} else if (type === "Address") {
				if (contactInfo.fields.contactAddresses && contactInfo.fields.contactAddresses.de) {
					if (!contactInfo.fields.contactAddresses.de[0].fields.streetAddress) {
					contactInfo.fields.contactAddresses.de[0].fields["streetAddress"] = {de:{}}
					}
					if (!contactInfo.fields.contactAddresses.de[0].fields.city) {
					contactInfo.fields.contactAddresses.de[0].fields["city"] = {de:''}
					}
					if (!contactInfo.fields.contactAddresses.de[0].fields.zipCode) {
					contactInfo.fields.contactAddresses.de[0].fields["zipCode"] = {de:''}
					}
					if (!contactInfo.fields.contactAddresses.de[0].fields.country) {
					contactInfo.fields.contactAddresses.de[0].fields["country"] = {}
					}
				}
				this.contactInfoAddress = contactInfo
				}
				}
		} else {
			sp.contactInfos = { de: [] }
		}

		 // We need a reference from internal data to the model data
			sp.contactInfos.de = [
			this.contactInfoPhone,
			this.contactInfoEmail,
			this.contactInfoWebsite,
			this.contactInfoAddress,
			]
		 
		 return sp.contactInfos
	},
	async checkBusinessNameExists() {
		try {
			// ATT: no ?? fallback because we may have an empty string
			let title = this.model.fields.title.de ? this.model.fields.title.de : this.model.fields.title[this.serviceLocale]
			title = encodeURIComponent(title.trim())
			const res = await this.$httpGet(`/check-businessname?title=${ title }&id=${this.model.sys.id}`)
			this.businessNameAllowed = res.businessNameAllowed
		}
		catch (e) {
			// nothing
		}
	},
  }
}
</script>

<style scoped>
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -27px; zoom: 0.8; }
</style>