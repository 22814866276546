<template>
	<div class="Table">
		<v-data-table fixed-header
			v-model="selected"
			:headers="headers"
			:items="modelValue"
			:items-per-page="limit"
			:mobile-breakpoint="700"
			:item-key="itemKey"
			class="elevation-0"
			:item-value="item => item"
			@update:sort-by="sort"
		>
			<template v-slot:item="{ item }">
				<tr>
<!--					<td>
						<v-checkbox v-model="item.selected" hide-details color="blue"></v-checkbox>
					</td>-->
					<td v-for="column of columns" :key="column.id ?? column">
						<template v-if="typeId && editable">
							<Field :typeName="typeId" :fieldName="column.id ?? column" v-model="item.fields[column.id ?? column]" />
						</template>
						<template v-else>
							{{ formatDate(item[column.key]) }}
						</template>
						{{ item[column] }}
					</td>
					<td>
						<slot name="itemActions" :item="item" />
						<!-- TODO: support 'delete' action -->
						<!-- TODO: optionally support 'edit' action -->
						<!-- TODO: when there are multiple actions: show dots with menu -->
					</td>
				</tr>
			</template>
			<template #bottom>
<!--				<TablePaginator v-model="offset" :limit="limit" :total="serviceProviderCount"
					@update:modelValue="getPeakServiceProviders()" :results="serviceProviders" />-->
				<button style="border: 2px dashed #bbb; padding: 5px; margin: 10px; border-radius: 5px; display: block;">
					<v-icon>mdi-plus</v-icon>
					<!-- TODO: translation 'Aufschlag' is bad. where do we actually want 'add' to mean that?
								havent actually found it anywhere in the code..
					-->
					{{ $t('text.add') }}
				</button>
			</template>
		</v-data-table>
		<div v-if="$attrs.onCreate">CREATE LISTENER IS DEFINED</div>
	</div>
</template>

<script>
import Field from '../../../components/fields/Field.vue'

export default {
	name: 'Table',
	components: { Field },
	props: {
		// array of strings (column ids) or objects (column definitions)
		// column definition: { id: String, width: String, sortable: Boolean }
		columns: Array,
		// if typeId is specified, each column will be rendered as <Field>
		typeId: String,
		modelValue: Array,
		itemKey: { type: String, default: 'id' },
		editable: Boolean,
	},
	data: () => ({
		visible: false,
		limit: 999,
		selected: [],
	}),
	computed: {
		headers() {
			const r = this.columns.map(column => {
				return {
					title: this.$t('text.' + (column.id ?? column)),
					key: column.id ?? column,
					width: column.width ?? '20%',
					sortable: true,
					cellClass: column.id ?? column,
				}
			})
			r.push({
				title: '',
				key: '',
				sortable: false,
			})
			return r
		},
	},
	methods: {
		sort() {
			// TODO
		},
	},
	mounted() {
	},
}
</script>

<style scoped>
.Table { border: 1px solid #ddd; border-radius: 5px; }
</style>

<style>
.Table label.title { display: none; }
</style>