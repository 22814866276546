<template>
	<v-select class="mys-tags" multiple
		:class="{
			selected: !!model,
			nolabel: !label,
		}"
		density="compact"
		variant="outlined"
		v-model="model"
		:hide-details="hideDetails"
		:label="label"
		:disabled="disabled"
		@update="$emit('update', $event)"
		@update:modelValue="$emit('update:modelValue', $event)"
		data-cy="dataCy"
		:items="items"
		:placeholder="$t('text.allLabel')"
	>
		<template #selection="{ item }">
			<v-chip size="small" v-if="typeof itemLabel == 'function'">{{ itemLabel(item.raw) }}</v-chip>
			<v-chip size="small" v-if="typeof itemLabel == 'string'">{{ item[itemLabel] }}</v-chip>
			<v-chip size="small" v-if="typeof itemLabel == 'undefined'">{{ item }}</v-chip>
		</template>
	</v-select>
</template>

<script>
export default {
	props: {
		modelValue: Array,
		options: Array,
		disabled: Boolean,
		label: String,
		hideDetails: { type: Boolean, default: true },
		dataCy: String,
		itemLabel: Function,
	},
	data() { return {
		model: false,
	}},
	computed: {
		items() {
			return this.options
		},
	},
	watch: {
		model() {
			this.$emit('update:modelValue', this.model)
		},
		modelValue(modelValue) {
			this.model = modelValue
		},
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style>
.mys-tags { margin: 0 !important;  }
.mys-tags .v-switch__track { background-color: #ccc; opacity: 1; }
.mys-tags.selected .v-switch__track { background-color: #00aeef; }
</style>