<template>
	<div class="PackageSalesChannels" v-if="salesChannels">
		<!-- TODO: tag display field: show each channel (should probably look different from the market place display in the SD -->
		<div @click="$refs.editDialog.open()">
			<v-chip v-for="scp of scps" :key="'sc-scp' + scp.salesChannel.id">
				{{ scp.salesChannel.name }} ({{ scp.products.length }} Products)
			</v-chip>
		</div>
		<!--
			@update:modelValue="editComplete"
			:validator="model => model.fields.validFromDate.de >= model.fields.validToDate.de ? $t('text.fromDateAfterToDateError') : null"
		-->
		<DataDialog ref="editDialog"
			v-model="model.fields.websites.de"
			:title="$t('text.salesChannels')"
			:useWrap="true"
		>
			<template #content="{ wrap }">
				<!-- TODO: https://app.zeplin.io/project/604748c2bca41a3e66787ef4/screen/659fed1da16c1ec72109ce93 -->
				<div v-for="(scp, s) of salesChannelProductsLookup" :key="'scp' + s"
					style="display: flex; gap: 10px; align-items: center;"
				>
					<v-checkbox v-model="wrap.model" hide-details :label="scp.salesChannel.name" :value="{ id: scp.salesChannel.id, name: scp.salesChannel.name }" />
					{{ scp.products.length }} Products
				</div>
			</template>
		</DataDialog>
	</div>
</template>

<script>
import DataDialog from '../../../components/common/DataDialog.vue'

export default {
	components: { DataDialog },
	props: {
		modelValue: Object,
		salesChannels: Array,
		products: Array,
	},
	data: () => ({
		model: null,
	}),
	watch: {
		modelValue: {
			deep: true,
			handler(n) {
				console.log('-----------------------', this.modelValue)
				this.model = n
				//this.validate()
			},
		},
		watch: {
			model: {
				deep: true,
				handler(n) {
					console.log('sales channels changed', n)
					this.$emit('update:modelValue', n)
				},
			},
		},
	},
	computed: {
		salesChannelProductsLookup() {
			const r = {}
			if (!this.salesChannels) return r
			for (const salesChannel of this.salesChannels) {
				const products = this.products.filter(p => p.product_websites.some(website => website.product_website_id === salesChannel.id))
				r[salesChannel.id] = { salesChannel, products }
			}
			return r
		},
		scps() {
			if (!this.products) return []
			else {
				const r = []
				for (const sc of this.modelValue.fields.websites.de) {
					const scp = this.salesChannelProductsLookup[sc.id]
					// TODO: should we maintain these wrong selections?
					if (!scp) continue
					r.push(scp)
				}
				return r
			}
		},
	},
	mounted() {
		this.model = this.modelValue
		console.log('websites model', this.model)
	},
}
</script>

<style scoped>
</style>