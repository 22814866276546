<template>
	<DataDialog ref="dialog" v-model="fields" @update:modelValue="() => null" :title="$t('text.personalisationFields')"
		:confirmLabel="$t('text.confirmSelection')" height="95vh" width="70vw" :fixIndexes="false">
		<template #content>
			<div style="width: 100%;">
				<div class="field left-border">
					<div class="pb-4">
						<p class="subTitle">{{ $t('text.fixedPersonalisationFields') }}</p>
						<p>{{ $t('text.fixedPersonalisationFieldsHelpText') }}</p>
					</div>
					<div v-for="field in fields" :key="field.value">
						<div class="row">
							<v-checkbox v-model="field.selected" :label="field.label.en" :disabled="field.disabled"
								hide-details color="blue" />
							<div class="flex-end pr-2">
								<mys-switch v-model="field.mandatory" :disabled="field.disabled"
									:label="$t('text.mandatory')" />
							</div>
						</div>
						<v-divider />
					</div>
				</div>
			</div>
		</template>
	</DataDialog>
</template>

<script>
import DataDialog from '@/components/common/DataDialog.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'PersonalisationFieldsDialog',
	components: { DataDialog },
	mixins: [Common],
	data() {
		return {
			fields: [
				{
					label: { en: 'Name' },
					selected: true,
					mandatory: true,
					disabled: false,
				},
				{
					label: { en: 'E-mail' },
					selected: true,
					mandatory: true,
					disabled: false,
				},
				{
					label: { en: 'Phone' },
					selected: true,
					mandatory: true,
					disabled: false,
				},
				{
					label: { en: 'Gender' },
					selected: true,
					mandatory: false,
					disabled: false,
				},
				{
					label: { en: 'Picture' },
					selected: false,
					mandatory: false,
					disabled: false,
				},
				{
					label: { en: 'Street' },
					selected: false,
					mandatory: false,
					disabled: false,
				},
				{
					label: { en: 'Street Number' },
					selected: false,
					mandatory: false,
					disabled: false,
				},
				{
					label: { en: 'Zip Code' },
					selected: false,
					mandatory: false,
					disabled: false,
				},
				{
					label: { en: 'City' },
					selected: false,
					mandatory: false,
					disabled: false,
				},
				{
					label: { en: 'Country' },
					selected: false,
					mandatory: false,
					disabled: false,
				},
			],
		}
	},
	methods: {
		open() {
			this.$refs.dialog.open()
		},
	},
}
</script>

<style scoped>
.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}
</style>