<template>
	<Application name="Media Gallery" :loading="loading" v-model:errorTitle="errorTitle" :errorDetail="errorDetail" v-model:successTitle="successTitle" :successDetail="successDetail" :isSideBarFixed="isSideBarFixed">
		<template #navbar>
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-btn v-if="!isView" elevation="0" @click="resetView()" style="background-color:transparent; min-width: 40px;"><v-icon>mdi-chevron-left</v-icon></v-btn>
				<h1 v-if="isUpload" class="pl-2">{{$t('text.uploadFile')}}</h1>
				<v-spacer/>
				<template v-if="isView">
					<v-btn-toggle mandatory v-model="viewType">
						<v-btn id="btnList" class='btnList' elevation="0"><v-icon>mdi-format-list-bulleted</v-icon></v-btn>
						<v-btn id="btnGrid" class="btnGrid  active-btn" elevation="0"><v-icon>mdi-view-grid</v-icon></v-btn>
					</v-btn-toggle>
				</template>
			</v-row>
		</template>

		<SideBar :actions="sidebarActions" :translations="translations" :navigation="sections" @action="handleAction($event)" />

    <div :style="showImpersonationInfo ? 'width:100%; padding-top: 24px !important;' : 'width;100%; padding-top:0px'">
      <!-- Content -->
      <div class="container-grid" v-if="isView">
        <Pagination v-if="viewType===1" :dataItems="providerMedia" :no-data-text="$t('text.noImages')">
          <template v-slot:item="{ item }">
            <MediaCard :media="item" :canEdit="true" :canSelect="true" :showTitle="true" @checkbox-change="setSelectedMedia" @show-media-update="showUpdate(item)" style="width: 230px; height: 230px;"/>
          </template>
        </Pagination>

        <v-card class="mb-14 tableCard" v-if="viewType===0">
          <v-data-table
            show-select
            v-model="selectedMedia"
            @update:modelValue="toggleSelectAll"
            :headers="headers"
            :items="providerMedia"
            fixed-header
            :item-value="item => item.sys.id"
            :item-key="item => item.sys.id"
            :no-data-text="$t('text.noImages')"
          >
            <template v-slot:item="{ item: { raw: item } }">
              <tr>
                <td><v-checkbox v-model="item.checked" style="margin-left: -8px;" /></td>
                <td>
                  <v-img width="60px" height="60px" :src="item?.fields?.media?.de?.fields?.file?.de?.url ? item.fields.media.de.fields.file.de.url : ''" />
                </td>
                <td>
                  <span v-if="item.fields.title">{{ item.fields.title[serviceLocale] }}</span>
                  <span v-if="!item.fields.title">{{ item.sys.id }}</span>
                </td>
                <td>
                  <span v-if="item.fields.description">{{ item.fields.description[serviceLocale] }}</span>
                </td>
                <td>
                  <span v-if="item.fields.media.de.fields.file.de.details?.image">
                    {{ item.fields.media.de.fields.file.de.details.image.width }}x{{ item.fields.media.de.fields.file.de.details.image.height }}
                  </span>
                </td>
                <td>{{ item.sys.createdAt.replace("T", " ") }}</td>
                <td>
                  <v-btn variant="text" icon @click="showUpdate(item)">
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <!-- Update Image -->
      <v-col class="col-lg-8 mb-16" v-if="isUpdate">
        <Disclosure class="disclosure" :title="$t('text.editImage')" :expanded="true">
          <div style="padding-bottom: 70px;">
            <v-row justify="center">
              <v-col>
                <div class="field left-border">
                  <p class="sidebarTitle">{{$t('text.copyRightInfo')}}</p>
                  <v-label>{{$t('text.copyRightInfoDesc')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                  <v-text-field variant="outlined" density="compact"
                                id="copyrightOwner"
                                autocomplete="off"
                                :error-messages="copyrightOwnerError"
                                counter="256" maxLength="256"
                                v-model="copyrightOwner"></v-text-field>

                  <v-checkbox
                      id="usageConsent"
                      :label="$t('text.usageConsent')"
                      :error-messages="copyrightError"
                      v-model="usageConsent"/>
                </div>
                <br/>

                <template v-if="selectedMedia">
                  <div class="field left-border">
                    <MediaItem ref="updateMediaItemRef" :mediaAsset="selectedMediaAsset" :key="renderKey" @remove-media-file="removeMediaFile"></MediaItem>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>
        </Disclosure>
      </v-col>

      <!-- Upload Image -->
      <v-col class="col-lg-8 mb-16" v-if="isUpload">
        <Disclosure class="disclosure" :title="$t('text.uploadNewFile')" :expanded="true">
          <div style="padding-bottom: 70px;">
            <v-row justify="center">
              <v-col>
                <v-row align="center">
                  <v-col>
                    <p v-html="$t('text.uploadFileDesc')"/>
                  </v-col>
                </v-row>

                  <div v-if="isComputerUpload" :class="isPoorQuality ? 'field left-border-warning' : 'field left-border'">
                    <p>{{$t('text.chooseFile')}} <span class="error-text">({{$t('text.required')}})</span></p>
                    <FileUpload :key="thumbnailHash" :options="dropOptions" ref="fileUpload" data-cy="fileUpload" @thumbnail-created="onThumbnailCreated" @file-removed="onFileRemoved"/>
                    <div v-if="isPoorQuality" >
                      <p class="warning-text">{{$t('text.poorQualityWarning')}}</p>
                    </div>
                  </div><br/>

                  <div v-if="isURLUpload" :class="isPoorQuality ? 'field left-border-warning' : 'field left-border'">
                    <p>{{$t('text.chooseURL')}} <span class="error-text">({{$t('text.required')}})</span></p>
                    <v-text-field variant="outlined" density="compact"
                        id="url"
                        data-cy="url"
                        autocomplete="off"
                        :disabled="protectURL"
                        :error-messages="urlError"
                        v-model="urlToLink">
                    </v-text-field>
                    <div v-if="isPoorQuality" >
                      <p class="warning-text">{{$t('text.poorQualityWarning')}}</p>
                    </div>

                  </div><br/>

                <div class="field left-border">
                  <p class="sidebarTitle">{{$t('text.copyRightInfo')}}</p>
                  <v-label>{{$t('text.copyRightInfoDesc')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                  <v-text-field variant="outlined" density="compact"
                                id="copyrightOwner"
                                data-cy="copyrightOwner"
                                autocomplete="off"
                                :error-messages="copyrightOwnerError"
                                counter="256" maxLength="256"
                                v-model="copyrightOwner"></v-text-field>

                  <v-checkbox
                      id="usageConsent"
                      data-cy="usageConsent"
                      :label="$t('text.usageConsent')"
                      :error-messages="copyrightError"
                      v-model="usageConsent"/>
                </div><br/>

                <div v-if="mediaAssets.length > 0">
                  <div v-for="mediaAsset of mediaAssets" :key="mediaAsset.renderKey" :class="mediaAsset.fields.isPoorQuality ? 'field left-border-warning' : 'field left-border'">
                    <MediaItem ref="mediaItem" :mediaAsset="mediaAsset" @remove-media-file="removeMediaFile"></MediaItem>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <Dialog ref="lowImageQualityDialog"
                  data-cy="lowImageQualityDialog"
                  :confirmLabel="$t('text.confirm')"
                  :cancelLabel="$t('text.cancel')"
                  :confirm-handler="confirmLowImageQuality"
                  :cancel-handler="cancelLowImageQuality"
                  :showClose="false"
                  :isDelete="true"
                  :title="$t('text.lowImageQualityTitle')"
                  :lowQualityFilesNames="lowQualityFilesNames"
                  :lowQualityFilesCount="lowQualityFilesCount"
                  :height="'360px'"
                  :width="'540px'"
                >
            <template #content>
              <v-row justify="center" align="center" style="padding: 10px">
                <v-col class="warning-icon-col">
                  <img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
                </v-col>
                <v-col class="text-col">
                  <span class="dialogText">{{$t('text.lowImageQualityText', {count: lowQualityFilesCount})}}</span>
                </v-col>
              </v-row>
            </template>
          </Dialog>

        </Disclosure>
      </v-col>
    </div>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'
import MediaCard from '@/components/media/MediaCard.vue'
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import MediaItem from './MediaItem.vue'
import Common from '@/mixins/Common.vue'
import eventBus from '@/utils/eventBus.js'
import SideBar from "@/components/common/SideBar.vue"
import Pagination from "@/components/common/Pagination.vue"
import FileUpload from "@/components/media/FileUpload.vue"
import Dialog from "@/components/common/Dialog.vue"
import Application from '../Application.vue'
import isEmpty from 'lodash/isEmpty'

export default {
	name: 'MediaGallery',
	components: { SideBar, FileUpload, Pagination, Loading, MediaCard, Alert, Disclosure, MediaItem, Dialog, Application },
	mixins: [ Common ],
  data() {
    return {
      loading: false,
      errorTitle: '',
      errorDetail: '',
      successTitle: '',
      successDetail: '',

      errorAlertVisible: false, //?
      successAlertVisible: false, //?

      deleteLabel: this.$t('text.deleteImages'),

      serviceProvider: {},
      media: [],
      selectedMediaAsset: {},
      uploadedFile: {},
      
      copyrightOwner: '',
      files: [],
      selectedFiles: [],
      usageConsent: false,
      urlToLink: '',
      renderKey: 1,

      providerMedia: [],

      viewType: 1,
      isView: true,
      isUpdate: false,
      isUpload: false,
      selectedMedia: [],
      copyrightError : '',
      copyrightOwnerError: '',

      mediaAssets: [],

      dropOptions: {
        acceptedFiles: '.jpg, .jpeg',
        addRemoveLinks: true,
        maxFilesize: 10
      },

      activeLink: '',
      isURLUpload: false,
      isComputerUpload: true,
      urlError: '',
      fileLoaded: false,
      protectURL: false,
      isPoorQuality: false,
      thumbnailHash: 0,
      isSingleImageUpload: false,
      lowQualityFilesCount: 0,
      lowQualityFilesNames: [],
      originalMediaAssets: [],
    }
  },

	computed: {
		headers() {
			return [
				{ title: '', key: "thumbnail", width:'60px' },
				{ title: this.$t('text.title'), key: "title", width: '30%', cellClass: 'truncate' },
				{ title: this.$t('text.description'), key: "description", width: '30%', cellClass: 'truncate', align: " d-none d-lg-table-cell" },
				{ title: this.$t('text.dimensions'), key: "dimensions", width:'10%', align: " d-none d-lg-table-cell" },
				{ title: this.$t('text.createdAt'), key: "created_at", width:'20%' },
				{ title: '', key: "edit", align: "center", width:'35px' },
			]
		},
		isDisabledUpload() {
			return this.mediaAssets.length === 0 && this.urlToLink === "";
		},
		errorMessages() {
			return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
		},
		successMessages() {
			return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
		},
		sidebarActions() {
			let actions = []

			if (this.isUpdate) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'updateMedia'})
			}

			if (this.isView) {
				actions.push({ icon: 'mdi-plus', style: 'default', dark: false, title: this.$t('text.uploadFile'), function: 'showUpload'})
			}
			
			if (this.isUpload && (this.isComputerUpload || (this.isURLUpload && this.fileLoaded))) {
				actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, disabled: this.isDisabledUpload || this.isPoorQuality, title: this.$t('text.uploadFile'), function: 'uploadMedia'})
			}

			if (this.isUpload && this.isURLUpload && !this.fileLoaded) {
				actions.push({ icon: 'mdi-arrow-down-circle', style: 'green', dark: true, disabled: this.isDisabledUpload, title: this.$t('text.loadFile'), function: 'loadFile'})
			}

			if (this.isView && this.selectedMedia.length>0) {
				actions.push({ icon: 'mdi-delete', style: 'red', dark: true, title: this.deleteLabel, function: 'deleteMedia'})
			}

			return actions
		},
		translations() {
			if (this.isView) return null
			if (this.isUpdate) return this.getTranslationStatus('media', this.selectedMediaAsset)
			if (!this.mediaAssets.length) return this.locales.map(locale => ({ locale, percentage: 100 }))

			return this.getTranslationStatus('media', this.mediaAssets)
		},
		sections() {
		if (!this.isUpload) return null
			return [
				{ id:'myComputer', state: 'valid', icon: 'mdi-monitor' },
				{ id:'viaURL', state: 'valid', icon: 'mdi-link' },
			]
		}
	},
	watch: {
		providerMedia: {
			deep: true,
			handler() {
				this.setSelectedMedia()
			},
		},
		errorAlertVisible() {
			if (this.errorAlertVisible) {
				setTimeout(this.closeAlert, 5000);
			}
		},
		successAlertVisible() {
			if (this.successAlertVisible) {
				setTimeout(this.closeAlert, 3000);
			}
		},
	},
  methods: {
	getDefaultField() {
		return (this.locales ?? []).reduce((acc, { code }) => {
			acc[code] = ''
			return acc
		}, {})
	},
    removeMediaFile(file) {
      if(this.isURLUpload) {
				this.resetURLRelatedData()
				this.mediaAssets = []
			} else {
				this.$refs.fileUpload.dropzone.removeFile(file)
			}
    },
    toggleSelectAll(selectedRowIds) {
      this.selectedMedia = selectedRowIds

      this.providerMedia.forEach(item => {
        item.checked = selectedRowIds.includes(item.sys.id)
      })
		},
    handleAction(event) {
			this[event.function](event.params)
		},
		setLocale(code) {
			this.serviceLocale = code
		},
    checkImageQuality(isRemoval) {
      this.isPoorQuality = false
        this.isSingleImageUpload = this.mediaAssets.length === 1
        this.mediaAssets.forEach(mediaAsset => {
          if (mediaAsset.fields.file.width < 450 || mediaAsset.fields.file.height < 450) {
            mediaAsset.fields.isPoorQuality = true
            this.isPoorQuality = true
          }
        })
      if (this.isPoorQuality && !isRemoval) {
        this.setLowImageQualityDialogMetaData()
        this.$refs.lowImageQualityDialog.show = true
      } else {
         // keep a copy of original media assets to be used for reset - this covers the case when the user adds good quality images and then tries to add poor quality images but cancels dialog
        this.originalMediaAssets = JSON.parse(JSON.stringify(this.mediaAssets))
      }
    },
    setLowImageQualityDialogMetaData() {
      const lowQualityFiles = this.mediaAssets.filter(mediaAsset => mediaAsset.fields.isPoorQuality)
        this.lowQualityFilesCount = lowQualityFiles.length
        this.lowQualityFilesNames = lowQualityFiles.map(mediaAsset => mediaAsset.fields.file.name)
    },
    setActive(key) {
      this.activeLink = key;
      this.mediaAssets = [];
      this.selectedMedia = [];
      this.copyrightOwner = ''
      this.usageConsent = false
     
      this.resetURLRelatedData()
      
      this.resetValidationErrors()

      if (key === 'myComputer') {
        this.isComputerUpload = true;
        this.isURLUpload = false;
      } else if (key === "viaURL") {
        this.isURLUpload = true;
        this.isComputerUpload = false;
      } else {
        this.isURLUpload = false;
        this.isComputerUpload = false;
      }
    },
    async loadFile() {
      this.loading = true
      this.protectURL = true
      await this.getMeta(`${this.urlToLink}`).then(img => {
        this.mediaAssets.push({
          sys: {
            id: this.urlToLink
          },
          fields:{
			title: this.getDefaultField(),
            altText: {},
            description: {},
            file: {
              dataURL: this.urlToLink,
              width: img.naturalWidth,
              height: img.naturalHeight,
              upload: {
                filename: 'Upload from URL',
              }
            },
            isURLUpload: true
          }
        })
        this.checkImageQuality(false)
        this.fileLoaded = true
      })
      .catch(err => {
      this.protectURL = false
      this.errorTitle = this.$t('text.ERROR')
      this.errorDetail = this.$t('text.invalidURL')
    });
    this.loading = false
    },

    onThumbnailCreated(file) {
      // check if file is already in the list
      const index = this.mediaAssets.findIndex(mediaAsset => mediaAsset.fields.file.upload.filename === file.upload.filename && mediaAsset.fields.file.upload.total === file.upload.total)
      if (index >= 0) {
        // MYS-3854: do not allow to upload this file because it already exists
        this.onFileRemoved(file)
      }
      this.mediaAssets.push({
        sys: {
          id: file.upload.uuid
        },
        fields:{
          title: this.getDefaultField(),
          altText: {},
          description: {},
          file: file
        }
      })
      this.checkImageQuality(false)
    },
    onFileRemoved(file) {
      const index = this.mediaAssets.findIndex(mediaAsset => mediaAsset.fields.file.upload.filename === file.upload.filename && mediaAsset.fields.file.upload.total === file.upload.total)
      if (index >= 0) {
        this.mediaAssets.splice(index, 1)
        // call this.dropzone.removeFile(file) in the child component (FileUpload) to remove the thumbnail from the dropzone
        this.$refs.fileUpload.dropzone.removeFile(file)
      }
      this.checkImageQuality(true)

    },
    resetView() {
      this.isView=true
      this.isUpload=false
      this.isUpdate=false
      this.copyrightOwner = ''
      this.usageConsent = false
      this.setActive("myComputer")
    },
    setSelectedMedia() {
      this.selectedMedia = []

      for (const media of this.providerMedia) {
        if (media.checked) {
          this.selectedMedia.push(media.sys.id)
        }
      }

      this.deleteLabel = this.$t('text.deleteImages').replace("%n%", this.selectedMedia.length)
    },
    closeAlert() {
      if (this.errorAlertVisible) {
        this.errorAlertVisible = false;
      } else if (this.successAlertVisible) {
        this.successAlertVisible = false;
      }
    },
    showUpload() {
      this.isView=false
      this.isUpload=true
      this.mediaAssets = []
      this.onlyJpegSupportedError = false
    },
    showUpdate(item) {
        this.isView = false
        this.isUpload = false
        this.isUpdate = true
        this.selectedMediaAsset = item
        this.selectedMediaAsset.fields["altText"] = this.selectedMediaAsset.fields.altText ?  this.selectedMediaAsset.fields.altText : {de: ''}
        this.selectedMediaAsset.fields["description"] = this.selectedMediaAsset.fields.description ?  this.selectedMediaAsset.fields.description : {de: ''}
        
        this.copyrightOwner = item.fields.copyright?.de || ''
        this.usageConsent = item.fields.usageConsent?.de || false
        
        this.copyrightError = ''
        this.copyrightOwnerError = ''
        this.urlError = ''
    },
    getMedia() {
      if (!this.$store.state.selectedServiceProvider) {
        this.errorAlertVisible = true
        this.errorTitle = 'ERROR'
        this.errorDetail = 'This app is only for service Providers!'
        return
      }

      this.loading = true;
      this.$httpGet(`/media/${this.$store.state.selectedServiceProvider.sys.id}`).then(res => {
        this.loading = false;

        if (res.media) {
          for (let media of res.media) {
            media["checked"] = false
          }
        }

        this.providerMedia = res.media

        this.resetView()

      }).catch(error => {
        this.loading = false;

        if (error.response && error.response.status === 401) {
          this.$emit("show-login")
        }

        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          this.errorDetail = error.response.data.error;
        } else {
          this.errorDetail = error;
        }
      });
    },
    uploadMedia() {
      const isValid = this.validate()

      if (isValid) {
        this.loading = true;

        let data = {
          mediaAssets: this.mediaAssets,
          url: this.urlToLink,
          serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
          serviceProviderName: this.$store.state.selectedServiceProvider.fields.title.de,
          title: this.title ? this.title : "MyServices Media Upload",
          copyrightOwner: this.copyrightOwner,
          usageConsent: this.usageConsent
        }

        this.$httpPost('/media', data).then(res => {
          this.urlToLink = ""
          this.title = ""
          this.copyrightOwner = ""
          this.usageConsent = false
          this.copyrightError = ""
          this.copyrightOwnerError = ''
          this.urlError = ''
          this.getMedia()
        }).catch(error => {
          this.loading = false;

          if (error.response && error.response.status === 401) {
            this.$emit("show-login")
          }

          this.errorTitle = this.$t('text.ERROR');
          this.errorDetail = this.$t('text.uploadError');
        })
      }
    },
    deleteMedia() {
      this.loading = true;

      this.axios.delete(`/media`, 
      {
         headers: this.requestHeaders(),
         data: {
           selectedMedia: this.selectedMedia,
           serviceProviderId: this.$store.state.selectedServiceProvider.sys.id
         } 
      }
      ).then(res => {
        this.loading = false;

        this.getMedia();
        this.selectedMedia = [];

      }).catch(error => {
        this.loading = false;

        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          if (error.response.data.error==='Linked to Entries Error') {
            this.errorDetail = this.$t('text.deleteMediaLinkedError');
          } else {
            this.errorDetail = error.response.data.error;
          }
        } else {
          this.errorDetail = error;
        }
      });
    },
    async updateMedia() {
      this.selectedMediaAsset.fields.title[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAsset.fields.title[this.serviceLocale]
      this.selectedMediaAsset.fields.altText[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAsset.fields.altText[this.serviceLocale]
      this.selectedMediaAsset.fields.description[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAsset.fields.description[this.serviceLocale]
      const isValid = this.validate(this.selectedMediaAsset)

      if (isValid) {
        this.loading = true
        var data = {
          mediaAsset: this.selectedMediaAsset,
          imageData: this.$refs.updateMediaItemRef.imgData,
          serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
          copyrightOwner: this.copyrightOwner,
          usageConsent: this.usageConsent
        }

        const res = await this.$httpPut('/media', data)
        this.getMedia()
      }
    },
    validate(selectedMediaAsset) {
      let isValid = true

      if (this.usageConsent === false || this.copyrightOwner.length === 0) {
          isValid = false
      }

      if (!selectedMediaAsset?.fields && this.mediaAssets.length === 0) {
        isValid = false
      }

      //Update Media Validation
      if (selectedMediaAsset) {
        if (selectedMediaAsset.fields.title[this.serviceLocale].length === 0) {
          this.renderKey = this.renderKey+1
          selectedMediaAsset.renderKey = this.renderKey
          selectedMediaAsset.titleError = this.$t('text.titleRequiredError')
          isValid = false
        }
      }

      //New Media Validation
      for (let mediaAsset of this.mediaAssets) {
        console.log('mediaAsset', mediaAsset)
        if (Object.values(mediaAsset.fields.title).some(value => !value) || isEmpty(mediaAsset.fields.title)) {
          this.renderKey = this.renderKey+1
          mediaAsset.renderKey = this.renderKey
          mediaAsset.titleError = this.$t('text.titleRequiredError')
          isValid = false
        }
      }

      this.copyrightError = this.usageConsent === false ? this.$t('text.copyrightError') : ''
      this.copyrightOwnerError = this.copyrightOwner.length === 0 ? ' ' : ''
      if (this.isURLUpload) {
        this.urlError = this.urlToLink.length === 0 ? this.$t('text.urlError') : ''
      }


      return isValid
    },
    showError(error) {
			this.loading = false
			const detail = error?.response?.data?.error ?? error
			
			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
    confirmLowImageQuality() {
      // MYS-3902: for now we will treat both cancel and confirm the same and will simply delete the image if it's a single upload (MYS-3852 should overwrite this behavior in the future)
      if (this.isSingleImageUpload) {
        this.cancelLowImageQuality()
      } else {
        // multi image upload: remove the low quality images from the mediaAssets array automatically and keep track of the number of the low quality images as well as their names so we can show them on the dialog text
        this.handleMultiImageUpload()
        this.$refs.lowImageQualityDialog.show = false
      }
      this.originalMediaAssets = JSON.parse(JSON.stringify(this.mediaAssets))
    },
    cancelLowImageQuality() {

      // find files that are in this.mediaAssets but not in this.originalMediaAssets and remove them
      let assetsToRemove = this.mediaAssets.filter(mediaAsset => !this.originalMediaAssets.find(originalMediaAsset => originalMediaAsset.fields.file.upload.filename === mediaAsset.fields.file.upload.filename))
      assetsToRemove.forEach(mediaAsset => {
        this.onFileRemoved(mediaAsset.fields.file)
      })

      // reset the mediaAssets array to the originalMediaAssets array
      this.mediaAssets = JSON.parse(JSON.stringify(this.originalMediaAssets))
      
      this.$refs.lowImageQualityDialog.show = false
      this.resetURLRelatedData()

    },
    handleMultiImageUpload() {
      const lowQualityFiles = this.mediaAssets.filter(mediaAsset => mediaAsset.fields.isPoorQuality)
       
      lowQualityFiles.forEach(mediaAsset => {
        this.onFileRemoved(mediaAsset.fields.file)
      })
    },
    async getMeta (url) {
      const img = new Image();
      img.src = url;
      await img.decode();  
      return img
    },
    resetURLRelatedData() {
      this.urlToLink = ''
      this.fileLoaded = false
      this.protectURL = false
      this.isPoorQuality = false
    },
    resetValidationErrors() {
      this.urlError = ''
      this.copyrightOwnerError = ''
      this.copyrightError = ''
      this.mediaAssets.forEach(mediaAsset => {
        mediaAsset.titleError = ''
      })
    }

  },
	mounted() {
		this.copyrightError = ''
		this.copyrightOwnerError = ''
		this.urlError = ''

		this.isView = true;

		this.getMedia()
		this.setActive("myComputer")
	},

}
</script>

<style scoped lang="scss">
.container-grid { padding: 20px; }
a { text-decoration: none;}

.dz-max-files-reached {
  pointer-events: none;
  cursor: default;
}

.tableCard span {
  @media (max-width: 600px) {
    white-space: initial;
  }
}
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }

#mediaNavlist {
  padding: 0;
  // margin: 0;
}
#mediaNavlist li {
  list-style-type: none;
  width:100%;
  height:64px;
  line-height:64px;
  padding-right:10px;

  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.25px;
  font-weight: 500;

  display: flex;
  align-items: middle;
  // border-bottom: 1px solid #dbdbdb !important;
}
#mediaNavlist a {
  color: #000000;
}
#mediaNavlist .active {
  border-top: 1px solid #00aeef;
  border-bottom: 1px solid #00aeef;
  background-color: #00aeef;
  color: #ffffff !important;
}

::v-deep .v-navigation-drawer__content {
  padding: 12px 0px 150px 0px !important;
}

.warning-text {
  padding-top:5px; 
  position:relative; 
  z-index: 1;
  color: #ffb400 !important;
  font-size: 12pt;
}

.field.left-border-warning {
  left: 0; 
  top: 15px; 
  bottom: 0px;  
  width: 100%;
  border-left: 3px solid #ffb400 !important;
}

.dialogText { 
  width: 268px;
  height: 110px;
  margin: 8px 0 0;
  font-family: Inter;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.42px;
  color: #9da0a5;
}

.warningDialogImage {
  width: 96px;
  height: 96px;
  // margin: 24px 48px 23px 0;
  object-fit: contain;
}

.warning-icon-col {
  flex: 1;
  text-align: center;
}

.text-col {
  flex: 2;
  text-align: center;
  
  width: 268px;
  height: 110px;
  margin: 8px 0 0;
  font-family: Inter;
  font-size: 17px;
  font-weight: normal;
  font-stretch:normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.42px;
  color: #9da0a5;

  text-align: left;
}
</style>