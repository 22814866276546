<template>
	<DialogV2 ref="dialog" :title="title"
		:currentStep="currentStep" :steps="steps"
		:confirmLabel="currentStep === 1 ? $t('text.confirmSelection') : $t('text.addTicketType')" :onConfirm="onClose" :onCancel="onClose"
		height="95vh" width="70vw" :fixIndexes="false">
		<template #sidebar>
			<LanguageSidebar v-if="currentStep === 2" :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
		</template>

		<template #content>
			<!-- Step 1 -->
			<div v-if="currentStep === 1">
				<div class="field left-border">
					<p class="section-text">{{ $t('text.ticketTypes') }}</p>
					<p>{{ $t('text.ticketTypesHelpText') }}</p>
				</div>
				<ActionTable :items="model" :actions="actions" :handleAdd="openCreateNewTicketType" />
			</div>
			<!-- Step 2 -->
			<div v-if="currentStep === 2">
				<!-- <Field typeName="Service" fieldName="title" dataCy="title" /> -->
				<div class="field left-border">
					<p class="section-text">{{ $t('text.generalSettings') }}</p>
					<p>{{ $t('text.additionalSettingsHelp') }}</p>
					<div class="pt-4">
						<p class="section-text">{{ $t('text.age') }}</p>
						<p>{{ $t('text.ageHelpText') }}</p>
						<v-row justify="space-between" class="pt-4" no-gutters>
							<!-- <Field typeName="Service" fieldName="ageGroupStart" dataCy="ageGroupStart" />
							<Field typeName="Service" fieldName="ageGroupEnd" dataCy="ageGroupEnd" /> -->
						</v-row>
					</div>
				</div>
			</div>
		</template>
	</DialogV2>
</template>

<script>
import Common from '@/mixins/Common.vue'
import DialogV2 from '@/components/common/DialogV2.vue'
import ActionTable from '@/components/common/tables/ActionTable.vue'
import Field from '@/components/fields/Field.vue'
import LanguageSidebar from '@/components/common/LanguageSidebar.vue'

export default {
	name: 'AddEditTicketTypeDialog',
	props: {
		modelValue: Object,
		typeName: String,
	},
	components: { DialogV2, ActionTable, Field, LanguageSidebar },
	mixins: [Common],
	emits: ["update:modelValue"],
	data() {
		return {
			model: null,
			currentStep: 1,
			isEdit: false,
		}
	},
	computed: {
		title() {
			if (this.currentStep === 1) return this.$t('text.ticketTypes')
			return this.isEdit ? this.$t('text.editTicketType') : this.$t('text.addTicketType')
		},
		steps() {
			return [
				{
					stepNr: 1,
					title: this.$t('text.types'),
					icon: 'mdi-format-list-bulleted',
					clickable: true,
				},
				{
					stepNr: 2,
					title: this.$t('text.configuration'),
					icon: 'mdi-cog',
					clickable: false,
				},
			]
		},
		actions() {
			return [
				{
					label: this.$t('text.edit'),
					icon: 'mdi-pencil',
					onClick: this.editTicketType,
				},
				{
					label: this.$t('text.delete'),
					icon: 'mdi-delete',
					onClick: () => null,
				},
			]
		},
	},
	watch: {
		modelValue(value) {
			this.model = value
		},
	},
	created() {
		this.model = this.modelValue
	},
	methods: {
		open() {
			return this.$refs.dialog.open()
		},
		close() {
			return this.$refs.dialog.close()
		},
		onClose() {
			this.currentStep = 1
			this.isEdit = false
		},
		editTicketType() {
			this.currentStep = 2
			this.isEdit = true
		},
		openCreateNewTicketType() {
			this.currentStep = 2
		},
		checkIfHasMissingTranslations(locale) {
			return false
		},
	},
}
</script>

<style lang="scss">
.left-border {
	p {
		color: black;
	}

	.section-text {
		font-size: 15px;
		font-weight: 600;
	}
}
</style>
