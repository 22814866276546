<template>
	<div v-if="dates.count > 0"
		class="ColorCalendar"
		:class="{ remove: paintingProfile?.isRemove }"
		style="display: flex; gap: 10px;"
		:style="{ '--marquee': paintingProfile?.color }"
	>
		<div style="width: 280px; border: 1px solid #eee; border-radius: 5px; padding: 20px;">
		<div class="nav" style="white-space: nowrap;">
			<button @click="go('today')">
				<v-icon>mdi-calendar-today</v-icon> Today
			</button>
			<button class="prev" @click="prev" :disabled="focusStart < dates.fromMonday">
				<v-icon>mdi-chevron-left</v-icon>
			</button>
			<span v-if="calendar.length">
				{{ monthLabel(calendar[14]) }} {{ calendar[14]?.year }}
			</span>
			<button class="next" @click="next" :disabled="focusEnd > dates.toSunday">
				<v-icon>mdi-chevron-right</v-icon>
			</button>
			<slot name="navigation"></slot>
		</div>
		<div style="display: flex; flex-wrap: wrap; font-size: smaller;">
			<div v-for="weekday of weekdays" :key="weekday"
				class="date weekdayHead"
			>
				{{ $t('text.' + weekday).substr(0, 1) }}
			</div>
			<div v-for="day of calendar" :key="'dt-' + day.date"
				class="date"
				:class="{
					open: day.open,
					notInRange: !day.inRange,
					monthBorder: day.date.substring(8, 10) < 8,
					today: dates.today == day.date,
					[ 'd' + day.date.substring(8, 10) ]: true,
					selected: day.selected,
					clicked: selectedDay?.date == day.date,
					fieldFocus: day.fieldFocus,
					error: !!day.error,
					notFocus: notFocus(day),
					marquee: marqueeDays?.[day.date],
				}"
				:style="{
					'--col': day.profile?.color,
				}"
				@click="clickDay(day)"
				@mousedown="mousedown($event, day)"
				@mousemove="mousemove($event, day)"
				@mouseup="mouseup($event)"
			>
				<span class="day">{{ Number(day.date.substring(8,10)) }}</span>
<!--
				<span v-if="day.date.substring(8,10) == '01' || day.weekday == 'monday'" class="monthLabel">
					{{ monthLabel(day) }}
					<span v-if="day.date.substring(8,10) == '01'">{{ day.year }}</span>
				</span>
-->
<!--
				<v-icon class="dots hover" v-if="selectedDay?.date != day.date">mdi-dots-horizontal</v-icon>
				<v-menu v-if="selectedDay?.date == day.date"
					v-model="dayMenu"
					location="top center"
					:close-on-content-click="false"
				>
					<template #activator="{ props }">
						<v-icon v-bind="props" class="dots" v-if="!dayMenu">mdi-dots-horizontal</v-icon>
						<v-icon v-bind="props" class="dots" v-if="dayMenu">mdi-dots-horizontal-circle</v-icon>
					</template>
					<div class="DataCalendarMenu">
						<div style="font-weight: bold; font-size: smaller;">{{ day.date.substring(8,10) }} {{ monthLabel(day) }} {{ day.year }}</div>
						<slot name="dayMenu" :day="day"></slot>
					</div>
				</v-menu>
				<slot name="day" :day="day" :selectedDay="selectedDay"></slot>
-->
			</div>
		</div>
		</div>
	</div>
</template>

<script lang="ts">
import moment from 'moment'

// TODO: BUG: when clicking 2nd view tab and back, this messes up the calendar (cannot navigate, ..)
// TODO: do we really need the weekdays?
//       we can live without them on the DataCalendars also..

// fieldFocus: the integrator may set day.fieldFocus = true to highlight a day with a dark blur circle
// focus: true between focusStart and focusEnd. however as this is a month calendar, we may show more days of the start and end week, on those days focus is false
//        a day may also have an explicit .focus property for this for advanced cases
// inRange: true if the day is between dates.min and dates.max (controlled by the integrator)

export default {
	props: {
		dates: Object,
		calendar: Array,
		profiles: Array,
		paintingProfile: Object,
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		editingDay: null,
		selectedDay: null,
		dayMenu: false,
		jumpMenu: false,
		jumpSearch: '',
		focusStart: moment().startOf('month'),
		focusEnd: moment().endOf('month'),
		mousedownDay: null,
		mouseoverDay: null,
	}),
	computed: {
		marqueeDays() {
			if (!this.mousedownDay) return null
			const x1 = Math.min(this.mousedownDay.x, this.mouseoverDay.x)
			const x2 = Math.max(this.mousedownDay.x, this.mouseoverDay.x)
			const y1 = Math.min(this.mousedownDay.y, this.mouseoverDay.y)
			const y2 = Math.max(this.mousedownDay.y, this.mouseoverDay.y)
			const r = {}
			for (const day of this.calendar) {
				if (day.x < x1 || day.x > x2 || day.y < y1 || day.y > y2) continue
				r[day.date] = true
			}
			return r
		},
	},
	methods: {
		monthLabel(day) {
			if (!day) return ''
			const locale = this.$vuetify.locale.current
			return Intl.DateTimeFormat(locale, { month: 'short' }).format(day.dateObject)
		},
		go(day) {
			if (!day) return
			if (day == 'today') day = this.dates.today
			if (typeof day == 'string') day = moment(day).startOf('day')
			this.focusStart = day.clone().startOf('month')
			this.focusEnd = this.focusStart.clone().endOf('month')
			this.$emit('focusRangeChanged', { focusStart: this.focusStart, focusEnd: this.focusEnd })
			const target = this.focusStart.clone().startOf('isoWeek')
		},
		prev() {
			if (this.focusStart) return this.go(this.focusStart.clone().add(-1, 'month'))
		},
		next() {
			if (this.focusStart) return this.go(this.focusStart.clone().add(+1, 'month'))
		},
		clickDay(day) {
			this.selectedDay = day
			this.selectedTime = null
			this.dayMenu = true
			this.addTime = null
			// TODO: event?
//			day.profile = this.paintingProfile
		},
		notFocus(day) {
			if (day.focus === false) return true
			if (day.focus === true) return false
			if (!this.focusStart) return true
			if (day.date < this.focusStart.format('YYYY-MM-DD')) return true
			if (day.date > this.focusEnd.format('YYYY-MM-DD')) return true
			return false
		},
		// marquee painting
		mousedown(e, day) {
			if (!this.paintingProfile) return
			e.preventDefault()
			this.$forceUpdate()
			this.mousedownDay = day
			window.addEventListener('mouseup', this.mouseup)
		},
		mousemove(e, day) {
			this.mouseoverDay = day
			if (!this.mousedownDay) return
			this.$forceUpdate()
		},
		mouseup(e) {
			const days = []
			for (const day of this.calendar) {
				if (!this.marqueeDays[day.date]) continue
				if (this.notFocus(day)) continue
				days.push(day)
			}
			this.$emit('painted', { days, profile: this.paintingProfile })
			window.removeEventListener('mouseup', this.mouseup)
			this.mousedownDay = null
		},
	},
	mounted() {
		this.go('today')
	},
}
</script>

<style scoped>
.ColorCalendar { --sel: lightskyblue; --field-focus: #295dc7; }

.nav { display: flex; gap: 10px; align-items: center; position: sticky; top: 64px; background: white; z-index: 100; padding-top: 15px; padding-bottom: 10px; margin-top: -15px; }
.nav button { padding: 5px; border: 1px solid #999; border-radius: 5px; background-color: #fff; }
.nav button[disabled] { opacity: 0.5; cursor: default; }
.nav button.prev,
.nav button.next { padding: 0; border: none; }

.weekdayHead { width: 100%; text-align: center; font-weight: 600; margin-bottom: 5px; min-height: initial !important; }
.monthLabel { font-weight: bold; color: #98926e; }

.date { width: calc(100% / 7); box-sizing: border-box; padding: 3px 2px; cursor: pointer; position: relative; }
.date.open { background-color: #fff6c6; }
.date.error { background-color: #fbd5d5; }
.date.notInRange { opacity: 0.5; }
.date.notFocus { filter: saturate(0); opacity: 0.3; }
.date.selected { outline: 2px solid var(--sel); outline-offset: -2px; z-index: 10; border-radius: 20px; }
.date.marquee { outline: 4px solid var(--marquee); outline-offset: -3px; z-index: 10; border-radius: 20px; }
.remove .date.marquee { outline: 2.5px dotted red; }

.date .day { display: inline-block; width: 100%; text-align: center; background: #e7e1e1; /*e8f5dc;*/ border-radius: 20px; padding: 5px; }
.date.today .day { outline: 2px solid rgb(235, 125, 125); outline-offset: 2px; }
.date .day { background: var(--col); }
.date.fieldFocus .day { outline: 2px solid var(--field-focus); outline-offset: -2px; }
.date.clicked .day { outline: /*3px dotted #333*/ 2px solid var(--field-focus); outline-offset: -2px; }

.dots { float: right; }
.date > .dots.hover { display: none; }
.date:hover > .dots.hover { display: block; }
</style>

<style>
.DataCalendarMenu { padding: 5px 0; background: white; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.2); }
.DataCalendarMenu > div { white-space: nowrap; cursor: pointer; padding: 5px 10px; }
.DataCalendarMenu > .item:hover { background: #eee; }
</style>