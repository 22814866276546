<template>
	<div v-if="dates.count > 0" class="DataCalendar">
		<!-- TODO: also have this navigator at the bottom -->
		<div class="nav" style="white-space: nowrap;">
			<button @click="$emit('update:offset', dates.offsetToday)" :disabled="offset == dates.offsetToday">
				<v-icon>mdi-calendar-today</v-icon> Today
			</button>
			<button @click="jumpMenu = true;" style="display: flex;">
				<v-icon>mdi-magnify</v-icon>
				<v-text-field variant="outlined" autofocus hide-details class="jumpSearch"
					v-if="jumpMenu"
					v-model="jumpSearch"
					@keydown.enter="jump(jumpSearch)"
					@blur="jumpMenu = false"
					:placeholder="$t('text.dateJumpPlaceholder')"
				/>
			</button>
			<button @click="$emit('update:offset', offset - limit)" :disabled="offset == 0">
				<v-icon>mdi-chevron-up</v-icon>
			</button>
			<button @click="$emit('update:offset', offset + limit)" :disabled="offset >= dates.count - limit">
				<v-icon>mdi-chevron-down</v-icon>
			</button>
			<!--
			<span>
				{{ 1 + offset/limit }}
				/ {{ Math.floor((dates.count - 1)/limit) + 1 }}
				-
			</span>
			-->
			<span v-if="calendar.length">
				{{ monthLabel(calendar[0]) }} {{ calendar[0].year }}
				- {{ monthLabel(calendar[calendar.length-1]) }} {{ calendar[calendar.length-1].year }}
			</span>
			<slot name="navigation"></slot>
		</div>
		<div style="display: flex; flex-wrap: wrap; font-size: smaller;">
			<div v-for="weekday of weekdays" :key="weekday"
				class="date weekdayHead"
			>
				{{ $t('text.' + weekday) }}
			</div>
			<div v-for="day of calendar" :key="'dt-' + day.date"
				class="date"
				:class="{
					open: day.open,
					notInRange: !day.inRange,
					monthBorder: day.date.substring(8, 10) < 8,
					today: dates.today == day.date,
					[ 'd' + day.date.substring(8, 10) ]: true,
					selected: selectedDay?.date == day.date,
					error: !!day.error,
				}"
				@click="selectedDay = day; selectedTime = null; dayMenu = true; addTime = null;"
			>
				<span class="day">{{ day.date.substring(8,10) }}</span>
				<span v-if="day.date.substring(8,10) == '01' || day.weekday == 'monday'" class="monthLabel">
					{{ monthLabel(day) }}
					<span v-if="day.date.substring(8,10) == '01'">{{ day.year }}</span>
				</span>

				<v-icon class="dots hover" v-if="selectedDay?.date != day.date">mdi-dots-horizontal</v-icon>
				<v-menu v-if="selectedDay?.date == day.date"
					v-model="dayMenu"
					location="top center"
					:close-on-content-click="false"
				>
					<template #activator="{ props }">
						<v-icon v-bind="props" class="dots" v-if="!dayMenu">mdi-dots-horizontal</v-icon>
						<v-icon v-bind="props" class="dots" v-if="dayMenu">mdi-dots-horizontal-circle</v-icon>
					</template>
					<div class="DataCalendarMenu">
						<div style="font-weight: bold; font-size: smaller;">{{ day.date.substring(8,10) }} {{ monthLabel(day) }} {{ day.year }}</div>
						<slot name="dayMenu" :day="day"></slot>
					</div>
				</v-menu>
				<slot name="day" :day="day" :selectedDay="selectedDay"></slot>
			</div>
			<div v-if="dates.count > 0">
				<button v-if="!extended" @click="$emit('update:extended', true)"><v-icon>mdi-arrow-expand-down</v-icon> {{ $t('text.showMore') }}</button>
				<button v-if="extended" @click="$emit('update:extended', false)"><v-icon>mdi-arrow-collapse-up</v-icon> {{ $t('text.showLess') }}</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Sugar from '../../lib/Sugar/Sugar'
import moment from 'moment'

export default {
	props: {
		dates: Object,
		calendar: Array,
		limit: { type: Number, default: 7 * 8 }, // X weeks page size
		offset: { type: Number, default: 0 }, // TODO: set the page containing today (if there is one)
		extended: { type: Boolean, default: false },
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		editingDay: null,
		selectedDay: null,
		dayMenu: false,
		jumpMenu: false,
		jumpSearch: '',
	}),
	methods: {
		jump(input) {
			// TODO: time zones..
			Sugar.Date.setLocale(this.$store.state.selectedLocale)
			let date = Sugar.Date.create(input)
			console.log('date', date)
			// for past dates we add a year
			if (date < Sugar.Date.create('1 month ago')) date = moment(date).add(1, 'year').toDate()
			const originalDate = moment(date).format('YYYY-MM-DD')
			// respect bounds
			if (date < this.dates.fromDate.toDate()) date = this.dates.fromDate.toDate()
			if (date > this.dates.toDate.toDate()) date = this.dates.toDate.toDate()
			console.log('date', date)

			if (isNaN(date.getTime())) return

			const min = this.dates.fromMonday.valueOf()
			let offset = Math.floor((new Date(date).getTime() - min) / (1000 * 60 * 60 * 24))
			offset = offset - (offset % this.limit)
			this.$emit('update:offset', offset)

			const boundedDate = moment(date).format('YYYY-MM-DD')
			console.log('originalDate', originalDate, 'boundedDate', boundedDate)
			if (originalDate == boundedDate) {
				this.$nextTick(() => {
					this.selectedDay = this.calendar.find(d => d.date == originalDate)
					this.dayMenu = true
				})
			}

			this.jumpMenu = false
			this.jumpSearch = ''
		},
		monthLabel(day) {
			const locale = this.$vuetify.locale.current
			return Intl.DateTimeFormat(locale, { month: 'short' }).format(day.dateObject)
		}
	},
	mounted() {
		this.$emit('update:offset', this.dates.offsetToday)
	},
}
</script>

<style scoped>
.nav { display: flex; gap: 10px; align-items: center; position: sticky; top: 64px; background: white; z-index: 100; padding-top: 15px; padding-bottom: 10px; margin-top: -15px; }
.nav button { padding: 5px; border: 1px solid #999; border-radius: 5px; background-color: #fff; }
.nav button[disabled] { opacity: 0.5; cursor: default; }

.weekdayHead { width: 100%; text-align: center; font-weight: 600; margin-bottom: 5px; min-height: initial !important; }
.monthLabel { font-weight: bold; color: #98926e; }

.date { width: calc(100% / 7); box-sizing: border-box; padding: 3px 10px 3px 5px; border-top: 3px solid #fff; cursor: pointer; position: relative; overflow: hidden; min-height: 60px; }
.date.monthBorder { border-top: 3px solid #98926e; }
.date.open { background-color: #fff6c6; }
.date.error { background-color: #fbd5d5; }
.date.notInRange { opacity: 0.3; }
.date.d01 { border-top-left-radius: 10px; }
.date.selected { outline: 3px dotted #333; z-index: 10; border-radius: 10px; }

.date .day { margin-right: 4px; }
.date.today .day { display: inline-block; background-color: rgb(222, 45, 45); color: white; font-weight: bold; padding: 2px 4px; margin-left: -4px; border-radius: 15px; }

.dots { float: right; }
.date > .dots.hover { display: none; }
.date:hover > .dots.hover { display: block; }

.jumpSearch { width: 100px; }
.jumpSearch >>> .v-field { height: 24px; }
.jumpSearch >>> .v-field__input { padding: 3px 5px; min-height: 100%; font-size: smaller; }
</style>

<style>
.DataCalendarMenu { padding: 5px 0; background: white; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.2); }
.DataCalendarMenu > div { white-space: nowrap; cursor: pointer; padding: 5px 10px; }
.DataCalendarMenu > .item:hover { background: #eee; }
</style>