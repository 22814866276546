<template>
	<div class="FieldSet"
		:class="{
			error: !!childError,
			'with-border': leftBorder,
		}"
	>
		<h2 v-if="id || label">
			{{ id ? $t('text.' + id) : label }}
			<span v-if="required">({{ $t('text.required') }})</span>
		</h2>
		<p v-if="id && $t('text.' + id + 'Info') != 'text.' + id + 'Info' || infoText" class="helpText">
			{{ id ? $t('text.' + id + 'Info') : infoText }}
		</p>
		<slot></slot>
	</div>
</template>

<script>
import ChildErrorReceiver from './ChildErrorReceiver.vue'

export default {
	name: 'FieldSet',
	mixins: [ ChildErrorReceiver ],
	props: {
		id: String,
		label: String,
		required: Boolean,
		infoText: String,
		leftBorder: {
			type: Boolean,
			default: true,
		},
	},
	mounted() {
		console.log('FieldSet mounted', this.leftBorder)
	}
}
</script>

<style scoped>
.FieldSet {
	padding-left: 1em; 
	--col-title: #67728a;
	--col-line: #cfd9e0;
}
.FieldSet.with-border { border-left: 3px solid var(--col-line); }
.FieldSet.error { border-left: 3px solid var(--col-error); }
h2 { font-size: 14px; margin: 0; padding: 0; line-height: initial; }
</style>