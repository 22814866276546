<template>
	<div class="customizableOptions">
		<!-- TODO: show warning when deselecting something that has autoShow=true -->
		<FieldSet id="customizableOptions">
			<v-expansion-panels multiple>
				<v-expansion-panel v-for="item in items"
					:key="`${item.id}.${item.items?.map(e => `${e.id}.${modelValue[e.id]}`).join('.')}`" elevation="0">
					<v-expansion-panel-title class="flex-header" expand-icon="mdi-menu-down" collapse-icon="mdi-menu-up"
						:readonly="!item?.items?.length" :hide-actions="!item?.items?.length"
						:style="`cursor: ${item?.items?.length ? 'pointer' : 'default'};`">
						<v-checkbox v-model="modelValue[item.id]" @update:modelValue="toggleSelectChildrenItems(item.id)"
							:indeterminate="getInderminateState(item.id)" @click.stop hide-details density="compact"
							color="blue">
							<template #label>
								<div class="field-header-text pl-2">
									<p>{{ $t(`text.${item.id}`) }}</p>
									<p class="field-subtitle">{{ $t(`text.${item.id}Info`) }}</p>
								</div>
							</template>
						</v-checkbox>
					</v-expansion-panel-title>
					<v-expansion-panel-text v-if="item?.items?.length">
						<v-checkbox v-for="id in item.items" :key="id" v-model="modelValue[id]" @click.stop
							@update:modelValue="updateParentItem(item.id)" hide-details density="compact" color="blue"
							class="child-checkbox ml-4 pb-3 mb-3">
							<template #label>
								<div class="field-header-text pl-2">
									<p>{{ $t(`text.${id}`) }}</p>
									<p class="field-subtitle">{{ $t(`text.${id}Info`) }}</p>
								</div>
							</template>
						</v-checkbox>
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
		</FieldSet>
	</div>
</template>

<script lang="ts">
import Common from "@/mixins/Common.vue"
import FieldSet from "@/views/applications/packageDesigner/FieldSet.vue"

// TODO: actually it is requested to have a dialog that allows cancel without immediate binding
//       detach with a datadialog?
//       this is potentially more complex than using datadialog since we have watchers on deep attributes..
export default {
	name: "OptionalSettings",
	components: { FieldSet },
	mixins: [Common],
	emits: ['update:modelValue'],
	props: {
		modelValue: Object,
		items: Array,
	},
	methods: {
		toggleSelectChildrenItems(id) {
			const isSelected = this.modelValue[id]
			const newModelValue = { ...this.modelValue }
			const childrenItems = this.items.find(e => e.id === id)?.items

			childrenItems?.forEach(id => newModelValue[id] = isSelected)

			this.$emit('update:modelValue', newModelValue)
		},
		getInderminateState(id) {
			const childrenItems = this.items.find(e => e.id === id)?.items

			if (childrenItems?.every(childId => this.modelValue[childId])) return false

			return childrenItems?.some(childId => this.modelValue[childId])
		},
		updateParentItem(id) {
			const childrenItems = this.items.find(e => e.id === id)?.items

			if (!childrenItems?.every(childId => this.modelValue[childId])) return

			this.$emit('update:modelValue', { ...this.modelValue, [id]: true })
		}
	},
}
</script>

<style scoped lang="scss">
.flex-header, .child-checkbox {
	border-bottom: 1px solid #DDD;
}

.field-header-text {
	p {
		font-weight: normal;
		color: #000;
		font-size: 15px;
		line-height: 20px;
	}

	.field-subtitle {
		font-size: 12px;
		line-height: 16px;
		color: #999999;
	}
}
</style>

<style>
.customizableOptions {
	.v-expansion-panel-text__wrapper {
		padding-right: 0;
	}

	.v-input__control {
		width: fit-content;
	}
}
</style>
