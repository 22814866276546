<template>
	<div>
		<DialogV2
			ref="addTicketDialog"
			:title="$t('text.upsertTicket')"
			:confirmLabel="$t('text.confirmSelection')"
			:onConfirm="confirmDialog"
		>
			<template #content>
				<FieldWrap :title="$t('text.ticketSettings')">
					<p>{{ $t('text.ticketSettingsHelp') }}</p>
				</FieldWrap>

				<FieldWrap :title="$t('text.reduction')">
					<RadioSelect
						v-model="model.title"
						return-object
						:options="reductionOptions"
						@update:modelValue="model.fields.title = $event.title"
						:label="`${$t('text.reductionLabel')} (${$t(
							'text.required'
						)})`"
						:helpText="$t('text.reductionHelpText')"
						:placeholder="`${$t(
							'text.chooseReductionCategory'
						)}...`"
						dataCy="reduction"
					>
						<template #append-option>
							<v-list-item
								:title="$t('text.editOptions')"
								prepend-icon="mdi-pencil"
								@click="editReduction"
								density="compact"
								slim
								class="pl-6"
							/>
						</template>
					</RadioSelect>
				</FieldWrap>

				<FieldWrap :title="$t('text.price')">
					<div>
						<v-label
							>{{ $t('text.price') }} ({{
								$t('text.required')
							}})</v-label
						>
						<p>{{ $t('text.priceHelpText') }}</p>
						<v-text-field
							type="number"
							:rules="priceRules"
							min="1"
							variant="outlined"
							density="compact"
							data-cy="price"
							required
							v-model="model.fields.price.de"
							:suffix="currency"
							:placeholder="`0,00 ${currency}`"
							style="width: 112px"
						/>
					</div>
				</FieldWrap>

				<FieldWrap :title="$t('text.duration')">
					<div>
						<v-label>{{ $t('text.duration') }} ({{ $t('text.required') }})</v-label>
						<p>{{ $t('text.durationHelpText') }}</p>
						<div style="display: flex;">
							<div style="flex-grow: 1;">
								<v-text-field
									variant="outlined"
									density="compact"
									data-cy="duration"
									required
									v-model="model.fields.duration.de.value"
									placeholder="1"
								/>
							</div>
							<div>
								<v-select
									v-model="model.fields.duration.de.unit"
									:items="durationItems"
									variant="outlined"
									density="compact"
								></v-select>
							</div>
						</div>
					</div>
				</FieldWrap>
			</template>
		</DialogV2>
	</div>
</template>

<script>
import DialogV2 from '@/components/common/DialogV2.vue'
import RadioSelect from '@/components/common/RadioSelect.vue'
import PersonalisationFieldsDialog from '@/components/packageDesigner/PersonalisationFieldsDialog.vue'
import AddEditTicketTypeDialog from '@/components/packageDesigner/AddEditTicketTypeDialog.vue'
import AddEditTicketCategoriesDialog from '@/components/packageDesigner/AddEditTicketCategoriesDialog.vue'
import Common from '@/mixins/Common.vue'
import FieldWrap from '../common/FieldWrap.vue'

export default {
	name: 'AddTicketDialog',
	components: {
		DialogV2,
		RadioSelect,
		PersonalisationFieldsDialog,
		AddEditTicketTypeDialog,
		AddEditTicketCategoriesDialog,
		FieldWrap,
	},
	mixins: [Common],
	props: {
		modelValue: Object,
	},
	emits: ['update:modelValue'],
	data() {
		return {
			model: null,
			ticket: {
				reduction: null,
				duration: null,
				price: null,
				specificPersonalisation: null,
			},
			enableSpecificPersonalisation: false,
			priceRules: [(v) => (v && v >= 1) || 'Price cannot be 0'],
			//TODO: get from API and analyse model
			reductionOptions: [
				{
					title: {
						de: 'Senior (> 65)',
						en: 'Senior (> 65)',
					},
				},
				{
					title: {
						de: 'Adult (> 18)',
						en: 'Adult (> 18)',
					},
				},
				{
					title: {
						de: 'Teen (15 - 17)',
						en: 'Teen (15 - 17)',
					},
				},
				{
					title: {
						de: 'Junior (7 - 14)',
						en: 'Junior (7 - 14)',
					},
				},
				{
					title: {
						de: 'Toddler & Child (0 - 6)',
						en: 'Toddler & Child (0 - 6)',
					},
				},
				{
					title: {
					de: 'Child (7 - 17)',
					en: 'Child (7 - 17)',
					},
				}
			],
		}
	},
	computed: {
		currency() {
			return this.$store.state.selectedClient?.fields?.currency?.de || '€'
		},
		durationItems() {
			return [
				{
					title: this.$t('text.year'),
					value: 'year',
				},
				{
					title: this.$t('text.season'),
					value: 'season',
				},
				{
					title: this.$t('text.month'),
					value: 'month',
				},
				{
					title: this.$t('text.day'),
					value: 'day',
				},
			]
		},
	},
	watch: {
		modelValue(value) {
			console.log('watcher', this.model)
			this.model = value
		},
		model: {
			handler(value) {
				console.log('child model changed', value)
			},
			deep: true,
		},
	},
	methods: {
		open() {
			this.$refs.addTicketDialog.open()
		},
		editReduction() {
			this.$refs.addEditTicketTypeDialog.open()
		},
		editDuration() {
			this.$refs.addEditTicketCategoriesDialog.open()
		},
		confirmDialog() {
			console.log('confirmDialog', this.model)
			this.$emit('update:modelValue', this.model)
		},
	},
	mounted() {
		this.model = this.modelValue ? this.modelValue : this.ticket
		console.log('mounted model', this.model)
	},
}
</script>

<style scoped>
.form-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.row-spaced {
	display: flex;
	justify-content: space-between;
}
</style>
