<template>
	<v-app id="app">
		<v-main fluid>
			<NavBar ref="navbar"
				:class="{
					navbar: $route.path === '/',
					internalApp: !$route.path.startsWith('/app'),
				}"
				style="position: fixed; inset: 0; bottom: auto; height: 64px; background: transparent; z-index: 900;"
				:active-link="activeLink"
				@show-login="showLogin"
				@show-dashboard="showDashboard"
				@show-component="showComponent"
				@show-messages="showMessages"
			/>
			<router-view
				@view-component="viewComponent"
				@show-login="showLogin"
				@show-dashboard="showDashboard"
				v-slot="{ Component }"
			>
				<component ref="view" :is="Component" />
			</router-view>
		</v-main>
	</v-app>
</template>

<script>
import 'vue-loading-overlay/dist/css/index.css'
import NavBar from './components/common/NavBar.vue'
//import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Common from '@/mixins/Common.vue'
import TokenAware from '@/mixins/TokenAware.vue'
import EntryCache from './stores/EntryCache.ts'

export default {
	name: 'MyServices',
	components: { NavBar },
	mixins: [ Common, TokenAware ],
	provide: {
		activePage: null,
		entryCache: new EntryCache(),
	},
	data() { return {
		selectedComponent: {},
		haveComponent: false,
		activeLink: null,
	}},
	computed: {
		magicLink() {
			return ['/businessHours'].includes(this.$route.path)
		},
	},
	watch: {
		async tokenHasExpired(v) {
			if (v) {
				if (this.$route.path.startsWith('/auth')) return
				console.log('tokenHasExpired', v)
				await this.showLogin()
			}
		},
	},
	methods: {
		// TEMP: used to toggle audit log visibility in Service Designer and Business Profile to allow testing on the trunk branch
		async getAuditLogFlag() {
			if (!this.$store.state.showAuditLog?.length) {
				const res = await this.$httpGet(`/show-audit-logs`)
				await this.$store.commit('setAuditLogFlag', res.showAuditLogs)
			}
		},
		viewComponent() {
			if (this.$store.state.selectedComponent?.fields?.urlLink?.de?.length) {
				window.open(this.$store.state.selectedComponent.fields.urlLink.de)
			}
			else {
				this.$refs.navbar.selectedComponent = this.$store.state.selectedComponent;
				this.selectedComponent = this.$store.state.selectedComponent;
				this.$router.push({path: this.$store.state.selectedComponent.fields.link.de})
			}
		},
		showMessages() {
			this.$router.push('/messages')
		},
		showComponent() {
			if (this.$store.state.selectedComponent.link) {
				this.$router.push({path: this.$store.state.selectedComponent.link})
			}
		},
		async showDashboard(event) {
			// Validate Token
			if (this.$store.state.loggedInUser?.kc_token) {
				if (event === 'exitImpersonation') {
					this.loadDashboard()
				}
				else {
					if (this.$refs.view?.goback) {
						this.$refs.view.goback(true)
					} else {
						this.loadDashboard()
					}
				}
			}
		},
		async loadDashboard() {
			const path = window.location.pathname
			this.resetView('/dashboard')

			if (path.startsWith('/dashboard')) {
				this.$router.go()
			}
			else {
				this.$router.push('/dashboard')
			}

			this.$refs.navbar.haveUser = Object.keys(this.$store.state.loggedInUser).length > 0

			if (!this.$store.state.coreConfig) {
				await this.loadCoreConfig()
			}
		},
		async resetView(path = undefined) {
			if (path === undefined) {
				path = this.$route.path

				// some routes are different from the app comp they belong to.
				// we set that info in the router.
				if (this.$route.meta?.root)
					path = this.$route.meta.root
			}

			// ATT: this comes into effect when the user reloads a page OR navigates to a path directly
			//      there are some apps that are double-registered on the same paths (Service Manager and Service Designer).
			//      so if we have an app in storage that fits the path, we'll use that.
			//      we should clean this up at some point (use separate paths for these apps) - the acrobatics necessary to maintain this are just silly.
			let app
			if ([this.$store.state.selectedComponent?.fields?.link?.de, this.$store.state.selectedComponent?.fields?.link?.de + '/'].includes(path)) {
				app = this.$store.state.selectedComponent
			}
			else {
				// we find the selectedComponent from the path
				app = this.$store.state.allApplications?.find?.(app => [app.fields?.link?.de, app.fields?.link?.de + '/'].includes(path))
			}
			this.selectedComponent = app ?? {}

			this.haveComponent = false
			if (this.$refs.navbar) {
				this.$refs.navbar.haveComponent = false
				this.$refs.navbar.selectedComponent = this.selectedComponent
			}

			await this.$store.commit('setSelectedComponent', this.selectedComponent)
			await this.$store.commit('setOffset', 0)
			await this.$store.commit('setSearchString', '')
			await this.$store.commit('setAttributeSets', null)
		},
		async showLogin(initialUrl) {
			//FreshdeskWidget.identify('', '')
			
			localStorage.clear()
			/* 
			Check if the initial url is the base MYS url, or a deeplink for a MYS plugin
			If it is a deeplink, store the url and redirect to it after login
			*/
			await Promise.all([
				this.$store.commit('setInitialUrl', initialUrl ?? window.location.pathname.startsWith('/app') ? window.location.pathname : ''),
				this.$store.commit('clear'),
				this.$store.commit('setLoggedInUser', {}),
				this.$store.commit('setSelectedComponent', {}),
				this.$store.commit('setIsImpersonation', false),
				this.$store.commit('setAttributeSets', null),
			])
			
			this.haveUser = false
			this.haveComponent = false
			this.selectedComponent = {}

			if (this.$refs.navbar) {
				this.$refs.navbar.haveUser = false
				this.$refs.navbar.haveComponent = false
				this.$refs.navbar.selectedComponent = {}
			}

			if (this.$route.path !== '/' && this.$route.path !== '/auth') {
				this.$router.push('/')
			}
		},
		async getFieldModels() {
			// TODO: for now this is disabled - in production we will probably want to enable it
			//if (this.$store.state.fieldModels?.length) return

			const fieldModels = await this.$httpGet('/model')
			await this.$store.commit('setFieldModels', fieldModels)
		},
		async getLocales() {
			if (!this.$store.state.locales?.length) {
				this.locales = await this.$httpGet(`/locales`)
				this.defaultLocale = this.locales[0]
				this.serviceLocale = this.defaultLocale.code
				await this.$store.commit('setLocales', this.locales)
			}
		},
		async load() {
			await Promise.all([
				this.getLocales(),
				this.getFieldModels(),
				this.getSchema(),
				this.getAuditLogFlag(),
			])
		},
	},
	created() {
		//FreshdeskWidget.install(this.$store)
		if (!this.$store.state.loggedInUser && !this.magicLink) {
			this.showLogin()
		}
	},
	async beforeMount() {
		try {
			// we allow setting the home client id manually, this is mostly useful for testing on the generic url
			if (location.toString().indexOf('?clientId=') > -1) {
				await this.$store.commit('setClientId', location.toString().split('?clientId=')[1])
			}

			this.haveUser = false
			this.haveComponent = false
			this.resetView()

			var browserLocale = navigator.language || navigator.userLanguage

			if (browserLocale.split("-")[0] !== "en" && browserLocale.split("-")[0] !== "de") {
				browserLocale = 'en'
			}

			if (!this.$store.state.selectedLocale) {
				this.$root.$i18n.locale = browserLocale.split("-")[0]
				this.$vuetify.locale.current = browserLocale.split("-")[0]
				await this.$store.commit('setSelectedLocale', browserLocale.split("-")[0])
				await this.$store.commit('setUserSpecificGerman', 'de')

				if (browserLocale.split("-")[0] === 'de') {
					if (browserLocale.split("-").length > 1) {
						await this.$store.commit('setUserSpecificGerman', browserLocale.split("-")[1].toLowerCase())
					}
					else {
						await this.$store.commit('setUserSpecificGerman', browserLocale.toLowerCase())
					}
				}
			}
			const authNeeded = this.$route.meta?.requiresAuth !== false
			if (!this.$store.state.loggedInUser?.fields && authNeeded) {
				this.showLogin()
			} 
			await this.load()
		}
		catch (error) {
			console.error(error)
		}
	},
	async mounted() {
		this.$root.app = this
	},
}
</script>

<style>
body {
	--status-pending-color: #ff9e21;
	--status-reapprove-color: #ffb400;
	--status-saved-color: #589ae6;
	--status-active-color: #66bb6a;
	--status-active_saved-color: #589ae6;
	--status-deactivated-color: #f34545;
	--status-declined-color: #f34545;
	--status-approved-color: #64c823;
	--status-expired-color: #737373;
	--status-unknown-color: #cccccc;
	--error-color: #f34545;
	--delete-color: #f34545;
	background: #f2f2f2;

	/* new var style */
	--col-error: #f34545;
}
@font-face {
  font-family: 'Inter', sans-serif;
  src: url("assets/fonts/Inter-VariableFont.ttf");
}
#app {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black !important;
  font-size: 12pt;
  background-color: #f2f2f2;
}
.main {
	padding-top: 100px;
	background-image: url('/myservices_background.jpg');
	background-size: cover !important;
}
#card {
  max-width: 840px;
  padding: 32px 48px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.2) !important;
}
#panel {
  width: 840px;
  padding: 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.2) !important;
}
body {
  font-family: 'Inter', sans-serif;
}
.subTitle {
  font-family: 'Inter', sans-serif;
  color: #666;
  font-size: 13pt;
  font-style: normal;
  font-weight: normal;
}
.fieldCaption {
  font-size: 12pt !important;
  color: #666;
}
.v-label {
  color: #666 !important;
  font-size: 12pt !important;
}
.v-main {
  background-color: #f2f2f2 !important;
  padding: 0 !important
}
.activeButton {
  background: #01c900 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  font-size: 14pt !important;
  color: #ffffff !important;
}
.vti__input {
  font-size: 14pt !important;
  margin-top: 5px;
}
.main {
  background-color: #f2f2f2;
  background-size: cover;
  height: 100%;
  width: 100%;
  color: black;
}
h1 {
  font-family: 'Inter', sans-serif;
  font-size: 20pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 36pt;
  letter-spacing: normal;
  color: #353535;
}
h2 {
  color: #666;
  font-size: 14pt;
  line-height: 50px;
}
h3 {
  color: black;
  font-size: 14pt;
  line-height: 40px;
}
p {
  color: #666;
  font-size: 10pt;
  margin-bottom: 0 !important;
}
a { text-decoration: none; }
.headline {
  color: #666;
  font-weight: bold;
  font-size: 18pt !important;
}
.v-application .error--text {
  font-size: 11pt;
}
.theme--light.v-divider {
  border-right-width: 3px;
  border-color: #5d5d5d !important
}
.theme--light.v-data-table {
  border-radius: 5px;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background-color: #808080 !important;
  color: #ffffff !important;
  font-size: 14pt;
  font-weight: normal;
}
.v-data-table-header th.sortable {
  color: #ffffff !important;
}
.v-data-table tr {
  cursor: pointer;
}
.v-data-table td {
  font-size: 14pt !important;
}
.v-chip.v-size--default {
  font-size: 12pt !important;
  font-weight: bold;
}
.theme--light.v-datatable .v-datatable__actions {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.v-simple-checkbox {
  margin-top:-5px;
}
/* .v-input__slot {
  font-size: 14pt !important;
  border: 1px solid rgba(0,0,0,0.1) !important;

}  maybe need to put this back*/
/*.v-text-field input {
  font-size: 14pt !important;
  height: 37px !important;
}*/
.v-toolbar__content {
  padding: 0 !important;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active::before {
    opacity: .03;
}

.sidebarTitle {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.01px;
  color: #666;
}
#navlist {
	padding: 0;
}
#navlist li:hover {
	background-color:#f4f4f4;
}
#navlist li {
	list-style-type: none;
	width: 100%;
	padding: 10px 10px 10px 20px;
	align-items: middle;
	font-family: 'Inter', sans-serif;
	font-size: 17px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: -0.25px;
	font-weight: 500;
}
#navlist a {
	color: #000000;
}
#navlist .active { 
	border-top: 1px solid #ededed;
	border-bottom: 1px solid #ededed; 
	background-color: #ffffff;
	color: #00aeef !important;
}
.v-list-item__title {
  font-size: 12pt !important;
}
.v-overlay__scrim {
  background-color: transparent !important;
}

.v-btn-group { height: 45px !important; }

.firstButton,
.middleButton,
.lastButton,
.firstButton_active,
.middleButton_active,
.lastButton_active {
	background: white !important;
	border: 1px solid #c2c2c2 !important;
	font-size: 12pt !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
	height: 45px !important;
}
.firstButton_active,
.middleButton_active,
.lastButton_active {
	background: #efefef !important;
}
.firstButton {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0 !important;
}
.middleButton {
  border-radius: 0 !important;
  border-right-width: 0 !important;
}
.lastButton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.firstButton_active {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0 !important;
}
.middleButton_active {
  border-radius: 0 !important;
  border-right-width: 0 !important;
}
.lastButton_active {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.notification {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  width: 100%;
  z-index: 999;
  white-space: pre-line;
  line-height: 20px !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.active {
  background-color:#66BB6A;
  color: #ffffff !important;
}
ul {
  color: black;
  font-size: 14pt;
}
.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 5px !important;
}
.row {
  margin: 0 !important;
}
.roundButton {
  border-radius: 30px !important;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: none !important;
  background: #f2f2f2 !important;
  font-size: 14pt !important;
  color: rgba(0,0,0,.38) !important;
  height: 32px !important;
  margin: 5px;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.weekdayCard {
  background-color:#f2f2f2 !important;
  padding-left:10px !important;
  height:48px !important;
  width:100% !important;
  font-size: 14pt !important;
  line-height: 48px !important;
}
.v-badge {
  padding-left: 220px !important;
}
.v-badge__badge {
  border-radius: 20px !important;
  color: #fff;
  font-size: 14px;
  height: 36px !important;
  min-width: 36px !important;
  letter-spacing: 0;
  padding: 4px 6px;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  -webkit-transition: .3s cubic-bezier(.25,.8,.5,1);
  transition: .3s cubic-bezier(.25,.8,.5,1);
  white-space: nowrap;
  z-index: 998;
}
.v-badge, .v-badge__badge {
  display: inline-block;
  line-height: 1.5 !important;
  font-size: 14pt !important;
}
.v-badge--bordered .v-badge__badge::after {
  border-width: 3px !important
}

.greenButton,
.blueButton,
.redButton,
.defaultButton,
.clearButton,
.defaultButton,
.gradientButton,
.orangeButton {
	height: 40px !important;
	border: 0 !important;
	color: white !important;
	display: flex !important;
	align-items: center !important;
	border-radius: 5px !important;
	font-family: 'Inter', sans-serif;
	font-size: 12pt !important;
	font-weight: normal !important;
	letter-spacing: 0 !important;
	text-transform: none !important;
}
.clearButton,
.formatButton,
.defaultButton,
.gradientButton { background: white !important; border: 1px solid #666 !important; color: #666 !important; }
.greenButton { background: #80c63e !important; }
.blueButton { background: #088ec0 !important; }
.redButton { background: #fb3640 !important; }
.orangeButton { background: #f27826 !important; }

.blueButtonMin {
  background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%) !important;
  border: thin #088ec0 solid !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.v-alert { color: #ffffff !important }
input[type=checkbox] { width: 40px; height: 40px; }
.clientAssignmentChip {
	--color: gray;
	display: inline-block;
	margin: 0 6px 0 0;
	padding: 3px 8px;
	color: var(--color);
	border: 2px var(--color) solid !important;
	border-radius: 15px;
	cursor: pointer;
	white-space: nowrap;
	line-height: initial;
	font-size: 11pt;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.clientAssignmentChip.pending { --color: var(--status-pending-color); }
.clientAssignmentChip.reapprove { --color: var(--status-reapprove-color); }
.clientAssignmentChip.saved { --color: var(--status-saved-color) !important; }
.clientAssignmentChip.active { --color: var(--status-active-color) !important; background: white !important; color: var(--color) !important; /* TODO: the .active class is used in another place blankly. we should fix that and then remove the black here. */ }
.clientAssignmentChip.active_saved { --color: var(--status-active_saved-color); }
.clientAssignmentChip.deactivated { --color: var(--status-deactivated-color); }
.clientAssignmentChip.declined { --color: var(--status-declined-color); }
.clientAssignmentChip.approved { --color: var(--status-approved-color); }
.clientAssignmentChip.expired { --color: var(--status-expired-color); }

.v-ripple__container { opacity: 0 !important; }
.v-list-item--link::before {background-color: #ffffff !important;}
.v-list-item--link {cursor:default !important}

.tableCard { border-radius: 5px; overflow: hidden; margin-top: 64px; }
.tableCard span { white-space: nowrap; text-overflow: ellipsis; }
.tableFooter { box-shadow: none !important; background-color:#f4f4f4 !important; }
.truncate { max-width: 1px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

div.field { position: relative; margin-bottom: 10px; margin-top: 10px; padding-top: 0px; padding-left: 15px; }
div.field:first-child { margin-top: 0; }
div.field:last-child { margin-bottom: 0; }
div.field .v-input { margin-top: 12px; }
div.field .v-label { display: block; height: fit-content; }
.field.left-border:before { content: ""; position: absolute; left: 0; top: 5px; bottom: 0px; width: 10px; border-left: 3px solid #ddd; pointer-events: none; }

.v-messages__message { font-size: 12pt !important; line-height: normal !important; }
.v-text-field__details { padding: 0 !important; margin: 0 !important }
.helpText { padding-top:5px; position:relative; z-index: 1;}

.gradient-button-align {
  min-height: 48px !important;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  margin-top: 12px !important;
}

.error-text { font-size: 12pt !important; color: #f34545 !important}
.theme--light.v-app-bar.v-toolbar.v-sheet { background-color: transparent !important; }

.system-bar { position: fixed; display: flex; margin-top: 64px; z-index: 900; width:100%;  height:28px !important; }
.v-chip .v-chip__content { font-family: 'Inter', sans-serif; }
.flag { position: absolute; z-index: 10; margin-top: 30px; margin-left: -26px; zoom: 0.8; }

.v-btn-group .v-btn { height: 45px !important; border-right-width: 0 !important; }
.v-btn-group .v-btn:first-child { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; }
.v-btn-group .v-btn:not(:first-child):not(:last-child) { border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important; border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; }
.v-btn-group .v-btn:last-child { border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important; border-right-width: 1px !important; }
.v-btn-group .v-btn.active { background: #eee !important; }

.internalApp { border-bottom: 1px solid #eee; box-shadow: 0 0 20px rgba(0,0,0,0.1); }
</style>

<style lang="scss">
//Button Style
%button-base {
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 14pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
  margin-top: 10px;
}

.blue {
  @extend %button-base;
  background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%) !important;
  border: thin #088ec0 solid !important;
}

.green {
  @extend %button-base;
  background: linear-gradient(0deg, #62c522 0%, #62c522 100%) !important;
  border: thin #58b01e solid !important;
}

.red {
  @extend %button-base;
  background: linear-gradient(0deg, #ff4848 0%, #f34545 100%) !important;
  border: thin #e74d4c solid !important;
}

.default {
  @extend %button-base;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
}

.outline {
  @extend %button-base;
  background: transparent !important;
  border: thin #000 solid !important;
}

.none {
  @extend %button-base;
  background: transparent !important;
  border: transparent !important;
}

//Notification Styles
%notification {
  display: block;
  margin: 5px;
	padding: 10px;
	color: #000;
	border: thin #dddddd solid !important;
	border-radius: 5px;
	font-size: 11pt;
  background-color: #ffffff !important;
}

.warn { @extend %notification; border-left: 5px solid #ff9e21 !important; }
.warning-text { font-size: 13pt; color: #ff9e21; }

.information { @extend %notification; border-left: 5px solid #088ec0 !important; }
.info-text { font-size: 13pt; color: #088ec0; }

.mainContainer { margin-top: 64px }

.v-expansion-panel { margin-bottom: 10px; }
.v-expansion-panel-title { font-weight: bold; }
.v-expansion-panel-title__overlay { background: transparent !important; }

.vuetify-pro-tiptap .v-input { border: 1px solid #ddd; border-radius: 4px; }
.vuetify-pro-tiptap-editor__toolbar { border-bottom: 1px solid #ddd; }
.vuetify-pro-tiptap .v-toolbar:last-of-type { border-top: 1px solid #ddd; }
.vuetify-pro-tiptap .v-toolbar:last-of-type .v-toolbar__content { height: 30px !important; }
.vuetify-pro-tiptap p { color: #333; font-size: 12pt; }
.vuetify-pro-tiptap ::selection { background: #c3dfff !important; color: inherit !important; }

.v-text-field .v-input__details { padding-inline-start: 0 !important; }
.removeRow { position: absolute; top: 0; right: 0; border-radius: 0 5px 0 5px; background-color: var(--delete-color); border: none; height: 30px; padding: 2px 15px; z-index: 999; font-size: smaller; }

//Vuetify 3
// v-date-picker
.v-picker-title, .v-picker__header {
  display: none !important;
}
.title {
	font-size: 1.5rem;
	font-weight: 500;
}
</style>