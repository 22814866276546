<template>
	<CommonTable :headers="headers" :items="items" :sortBy="sortBy" @update:sortBy="sortBy = $event" :loading="loading"
		show-expand v-model:expanded="expanded" show-select v-model:selected="selected"
		@update:selected="$emit('update:selected', $event)" :total="total" :limit="limit" :offset="offset"
		@update:limit="$emit('update:limit', $event)" @update:offset="$emit('update:offset', $event)"
		:noDataText="$t('text.noProductsAvailable')">
		<template #[`item.product_name`]="{ item }">
			<div class="service">
				<p class="sp-name text-truncate">{{ item.product_service_provider_name }}</p>
				<p class="p-name text-truncate">{{ getProductName(item.product_name) }}</p>
			</div>
		</template>

		<template #[`item.product_sku`]="{ item }">
			<p class="sku">{{ item.product_sku }}</p>
		</template>

		<template #[`item.activities`]="{ item }">
			<div>
				<div class="main-category" v-if="item.activities?.length">
					<div class="category-chip" v-for="{ activity_id, activity_reference, activity_name } of item.activities"
						:key="`${activity_id}_${activity_reference}`">
						<p>{{ activity_name }}</p>
					</div>
				</div>
			</div>
		</template>

		<template #[`item.product_price`]="{ item }">
			<p class="price" v-if="item.product_price !== null">€ {{ item.product_price?.toFixed(2) }}</p>
		</template>

		<template #[`item.product_websites`]="{ item }">
			<div class="sales-channels">
				<div v-if="item.product_websites?.length">
					<p>
						<span>
							{{ item.product_websites.slice(0, 2).map(({ product_website_name }) =>
								product_website_name).join(', ') }}
						</span>
						<span v-if="item.product_websites.slice(2).length">
							, +{{ item.product_websites.slice(2).length }} {{ $t('text.more') }}
						</span>
					</p>
				</div>
			</div>
		</template>

		<template #[`item.product_status`]="{ item }">
			<v-tooltip location="bottom">
				<template #activator="{ props }">
					<v-icon v-if="item.product_status === 'activated'" color="#64c823"
						v-bind="props">mdi-check-circle</v-icon>
					<v-icon v-if="item.product_status === 'deactivated'" color="red" v-bind="props">mdi-close</v-icon>
				</template>
				<span class="tooltip">{{ $t(`text.${item.product_status}`) }}</span>
			</v-tooltip>
		</template>

		<!-- Expanded Row -->
		<template #expanded-row="{ item }">
			<!-- General -->
			<p class="title mb-2">{{ $t('text.packageDesignerGeneral') }}:</p>
			<div class="expanded-row">
				<div class="general-row">
					<!-- SKU -->
					<div class="row-item">
						<p>{{ $t('text.articleNumber') }}/ SKU:</p>
						<p>{{ item.product_sku }}</p>
					</div>
					<!-- Title -->
					<div class="row-item">
						<p>{{ $t('text.title') }}:</p>
						<p>{{ getProductName(item.product_name) }}</p>
					</div>
					<!-- Service Provider -->
					<div class="row-item">
						<p>{{ $t('text.serviceProvider') }}:</p>
						<p>{{ item.product_service_provider_name }}</p>
					</div>
					<!-- Type -->
					<div class="row-item">
						<p>{{ $t('text.type') }}:</p>
						<p>{{ item.product_type.charAt(0).toUpperCase() + item.product_type.slice(1) }}</p>
					</div>
				</div>

				<div class="flex-2">
					<div class="general-row-second">
						<!-- Created -->
						<div class="row-item">
							<p>{{ $t('text.created') }}:</p>
							<p>{{ formatDate(item.product_created_at) }}</p>
						</div>
						<!-- Last Updated -->
						<div class="row-item">
							<p>{{ $t('text.lastUpdated') }}:</p>
							<p>{{ formatDate(item.product_updated_at) }}</p>
						</div>
					</div>
				</div>
			</div>
			<!-- TODO Categories inluding subcategories will be added soon -->
			<!-- Categories -->
			<!-- <div class="expanded-row mt-6">
				<div class="categories-row">
					<p class="title mb-4">{{ $t('text.categories') }}:</p>
					<CategoriesTable :items="categories" />
				</div>
				<v-spacer class="flex-2" />
			</div> -->
		</template>
	</CommonTable>
</template>

<script>
import Common from '@/mixins/Common.vue'
import CommonTable from '@/components/common/tables/Table.vue'
import ApprovalStatus from '@/components/common/ApprovalStatus.vue'
import Status from '@/components/common/tables/Status.vue'
import IncludedProductsTable from '@/views/applications/packageDesigner/tables/IncludedProductsTable.vue'
import CategoriesTable from '@/views/applications/packageDesigner/tables/CategoriesTable.vue'

export default {
	name: 'ProductsTable',
	components: { CommonTable, ApprovalStatus, Status, IncludedProductsTable, CategoriesTable },
	emits: ['update:selected'],
	mixins: [Common],
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		loading: Boolean,
		total: Number,
		limit: Number,
		offset: Number,
	},
	data() {
		return {
			sortBy: [],
			expanded: [],
			selected: [],
		}
	},
	computed: {
		headers() {
			return [
				{
					title: this.$t('text.service'),
					key: 'product_name',
					value: 'product_name',
				},
				{
					title: `${this.$t('text.articleNumber')} / SKU`,
					key: 'product_sku',
					value: 'product_sku',
				},
				{
					title: this.$t('text.productCategory'),
					key: 'activities',
					value: 'activities',
					sortable: false,
				},
				{
					title: this.$t('text.price'),
					key: 'product_price',
					value: 'product_price',
				},
				{
					title: this.$t('text.salesChannels'),
					key: 'product_websites',
					value: 'product_websites',
					sortable: false,
				},
				{
					title: this.$t('text.status'),
					key: 'product_status',
					value: 'product_status',
					align: 'center',
				},
			]
		},
		categories() {
			const result = []

			this.items.forEach(({ id, mainCategory, secondaryCategory }) => {

				mainCategory?.forEach(category => {
					result.push({
						id,
						mainCategory: category,
						subCategory: secondaryCategory?.[category],
					})
				})

			})

			return result
		},
	},
	methods: {
		getProductName(name) {
			return name.find(({ locale_code }) => locale_code?.indexOf(this.serviceLocale) > -1 || locale_code?.indexOf('de') > -1)?.value
		},
	},
}
</script>

<style scoped lang="scss">
.service {
	max-width: 250px;

	.p-name {
		font-size: 15px;
		color: #000;
	}

	.sp-name {
		font-size: 12px;
		color: #999;
	}
}

.sku,
.price {
	font-size: 15px;
	color: #000;
}

.main-category {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;

	.category-chip {
		background-color: #DADADA;
		padding: 2px 5px;
		border-radius: 5px;

		p {
			font-size: 13px;
			color: #000;
			white-space: nowrap;
		}
	}
}

.sales-channels {
	p {
		font-size: 15px;
		color: #000;
	}
}

.expanded-row {
	display: flex;
	gap: 100px;

	.general-row {
		display: flex;
		gap: 5px;
		flex-direction: column;
		flex: 1;
	}

	.general-row-second {
		display: flex;
		gap: 5px;
		flex-direction: column;
		flex: 1;
		width: 50%;
	}

	.marketplace-row {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.product-row {
		display: flex;
		flex-direction: column;
		flex: 2;
	}

	.categories-row {
		flex: 1;
	}

	.sales-channels-headers {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid grey;
	}

	.row-item {
		display: flex;
		justify-content: space-between;
	}

	.flex-2 {
		flex: 2;
	}
}
</style>