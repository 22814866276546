<template>
	<Step id="content" icon="mdi-fountain-pen-tip">
		<div class="todo">https://overflow.io/s/CDRGOW7X/?node=d8fbbfba</div>
		<Section id="publicInfo" open>
			<Field typeName="Package" fieldName="longDescription" v-model="modelValue.fields.longDescription" />
			<Field typeName="Package" fieldName="shortDescription" v-model="modelValue.fields.shortDescription" />
			<Field typeName="Package" fieldName="medias" v-model="modelValue.fields.medias.de" />
		</Section>
		<Section id="categories" open>
			<div class="field left-border">
				<v-label>{{ $t('text.productCategories') }}</v-label>
				<p class="helpText">{{ $t('text.productCategoriesHelpText') }}</p>
				<v-select :placeholder="`${$t('text.chooseProductCategories')}...`" variant="outlined" density="compact" :modelValue="modelValue.fields.productCategories"
					:items="productCategoryOptions" multiple chips hide-details>
					<template #selection="{ item }">
						<v-chip :color="item.color" dark>{{ $t('text.' + item.id) }}</v-chip>
					</template>
				</v-select>
			</div>
		</Section>
	</Step>
</template>

<script lang="ts">
import Step from './Step.vue'
import Section from './Section.vue'
import FieldSet from './FieldSet.vue'
import Field from '../../../components/fields/Field.vue'
import Common from '@/mixins/Common.vue'

export default {
	components: { Section, FieldSet, Field, Step },
	mixins: [Common],
	props: {
		modelValue: Object,
		salesChannels: Array,
		products: Array,
	},
	data() {
		return {
			loading: false,
			productCategoryOptions: [],
		}
	},
	methods: {
		async getProductCategoryOptions() {
			try {
				this.loading = true
				const { productCategories } = await this.$httpGet(`/packageTravel/productCategories?clientId=${this.$store.state.selectedClient.sys.id}`)
				this.productCategoryOptions = productCategories.map(({ category_id, category_name }) => ({ title: category_name, value: category_name, id: category_id }))
			} catch (error: any) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.loading = false
			}
		},
	},
	mounted() {
		this.getProductCategoryOptions()
	},
}
</script>
