<template>
	<Dialog ref="dialogRef" :confirmLabel="confirmLabel" :cancelLabel="$t('text.cancel')"
		:confirm-handler="confirmHandler" :cancel-handler="onServiceTypeCancelled" :showClose="false"
		:isBeta="false" :height="'75%'" :width="'1220px'" :title="title">
		<template #content>
			<div class="dialog-container">
				<!-- Sidebar -->
				<!-- <div class="sidebar" style="width: 250px">
					<ul class="nav-list">
						<li v-for="{ label, value, icon } in sidebarListItems" :key="value"
							:class="{ 'active': filter === value }" @click="filter = value">
							<v-icon :color="filter === value ? '#ffffff' : '#000000'" class="mr-2">{{ icon }}</v-icon>
							<span>{{ label }}</span>
						</li>
					</ul>
				</div> -->
				<!-- Content -->
				<div class="content-wrapper">
					<p class="card-title pb-6">{{ isPackage ? $t("text.newPackageHelp") : $t('text.newServiceHelp') }}</p>
					<div class="card-wrapper">
						<v-layout style="display: flex" align="center">
							<div v-for="(serviceType, index) in filteredServiceTypes" :key="serviceType.sys.id"
								style="height: 375px; width: 300px">
								<ServiceTypeCard :serviceType="serviceType" :data-cy="'serviceType_' + index" />
							</div>
						</v-layout>
					</div>
				</div>
			</div>
		</template>
	</Dialog>
</template>

<script lang="ts">
import ServiceTypeCard from '@/components/serviceDesigner/ServiceTypeCard.vue'
import Dialog from '@/components/common/Dialog.vue'
import Common from '@/mixins/Common.vue'
import uniqBy from 'lodash/uniqBy'

export default {
	name: "AddNewTypeDialog",
	props: {
		showLoader: Function,
		type: {
			type: String,
			required: true,
			validator: (value) => ['product', 'package'].includes(value),
		},
		onConfirm: Function,
	},
	mixins: [Common],
	components: { Dialog, ServiceTypeCard },
	data: () => ({
		filter: '',
		serviceTypes: [],
		sidebarListItems: [
			{
				label: 'All',
				value: '',
				icon: 'mdi-shape',
			},
			{
				label: 'Events',
				value: 'event',
				icon: 'mdi-calendar-star',
			},
			{
				label: 'Ski School',
				value: 'skiSchool',
				icon: 'mdi-ski',
			},
			{
				label: 'Ski Rental',
				value: 'skiRental',
				icon: 'mdi-ski',
			},
			{
				label: 'Packages',
				value: 'package',
				icon: 'mdi-layers-triple-outline',
			},
			{
				label: 'Guest Cards',
				value: 'guestcard',
				icon: 'mdi-wallet-membership',
			},
		],
	}),
	computed: {
		isPackage() {
			return this.type === 'package'
		},
		title() {
			return this.isPackage ? this.$t('text.newPackage') : this.$t('text.newService')
		},
		confirmLabel() {
			return this.isPackage ? this.$t('text.createPackage') : this.$t('text.createService')
		},
		filteredServiceTypes() {
			if (!this.filter) return this.serviceTypes
			return this.serviceTypes.filter(serviceType => serviceType.sys.type === this.filter)
		},
		routeOnConfirm() {
			return this.isPackage ? '/packages/1234' : '/service-designer'
		},
	},
	methods: {
		open() {
			this.$refs.dialogRef.show = true;
		},
		close() {
			this.$refs.dialogRef.show = false;
		},
		showServiceTypeSelector() {
			//Check if feature is enabled for client
			const client = this.$store.state.selectedClient;

			if (client.fields.features?.de?.length > 0) {
				const serviceDesignerTemplateFeature = client.fields.features.de.find(
					x => x.id === "service-designer-templates"
				);

				if (serviceDesignerTemplateFeature) {
					this.open();
				} else {
					this.$router.push(this.routeOnConfirm)
				}
			} else {
				this.$router.push(this.routeOnConfirm)
			}
		},
		async getServiceTypes() {
			this.showLoader(true);
			this.serviceTypes = [];
			try {
				const providerServiceTypes = this.$store.state.selectedServiceProvider.fields.userAccount?.de?.fields?.serviceTypes?.de ?? [];
				let resServiceTypes = [];

				if (providerServiceTypes.length) {
					const res = await this.$httpGet("/all-service-types");
					resServiceTypes = res.serviceTypes?.filter(type => providerServiceTypes.includes(type.sys.id)) ?? [];
				}

				if (!this.isPackage) {
					const res = await this.$httpGet(`/product-service-types/${this.$store.state.selectedClient.sys.id}`);
					resServiceTypes = uniqBy([...resServiceTypes, ...res.serviceTypes], "sys.id");
					resServiceTypes = resServiceTypes.filter(type => !type.fields.ticketType.de.includes('package'));
				} else {
					resServiceTypes = resServiceTypes.filter(type => type.fields.ticketType.de.includes('package'));
				}

				this.serviceTypes = resServiceTypes;
				const defaultServiceType = this.serviceTypes.find(x => x.fields.default === true) || this.serviceTypes[0];

				await Promise.all([
					this.$store.commit("setSelectedServiceType", defaultServiceType),
					this.$store.commit("setSelectedProduct", {}),
				]);

				this.showServiceTypeSelector();
			} catch (error: any) {
				this.errorTitle = this.$t("text.ERROR");
				this.errorDetail = error.response?.error ?? error;
			} finally {
				this.showLoader();
			}
		},
		async onServiceTypeCancelled() {
			await this.$store.commit("setSelectedServiceType", {})
			this.close();
		},
		async onServiceTypeConfirmed() {
			await this.$store.commit("setSelectedProduct", {})
			this.$router.push(this.routeOnConfirm)
		},
		async createEmptyPackage() {
			try {
				this.showLoader(true)

				const { packageId } = await this.$httpPost(`/packageTravel/package`, { clientId: this.$store.state.selectedClient.sys.id })

				this.$router.push(`/packages/${packageId}`)
			} catch (error: any) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.showLoader(false)
			}
		},
		async confirmHandler() {
			if (this.isPackage) {
				await this.createEmptyPackage()
			} else {
				await this.onServiceTypeConfirmed()
			}

			this.close()
		},
	},
};
</script>

<style lang="scss" scoped>
.content-wrapper {
	padding: 20px;
}

.card-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}

.card-title {
	font-size: 17px;
	font-weight: bold;
	color: #000;
}

.dialog-container {
	display: flex;
	position: absolute;
	inset: 0;
}

.sidebar {
	background-color: #f4f4f4;
	border-right: 1px solid #f4f4f4;
	height: 100% !important;
	padding: 0px;
	background-color: #f4f4f4 !important;
	border-right: 1px solid #dbdbdb !important;
}

.nav-list {
	padding: 0;

	li {
		list-style-type: none;
		width: 100%;
		height: 64px;
		padding-left: 20px;
		padding-right: 20px;

		font-size: 17px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: -0.25px;
		font-weight: 500;

		display: flex;
		align-items: center;
		border-bottom: 1px solid #dbdbdb;
		color: #000000;
		cursor: pointer;
	}

	.active {
		padding-left: 40px !important;
		border-top: 1px solid #00aeef;
		border-bottom: 1px solid #00aeef;
		background-color: #00aeef;
		color: #ffffff;
	}
}
</style>
