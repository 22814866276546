<template>
	<div v-if="show" :class="{backdrop}" @click="backdropClick">
		<div class="dialog-div" :style="{ height, width }">
			<div v-if="isBeta" class="beta-top">
				<div class="corner"></div>
				<div class="beta">beta</div>
			</div>
			<v-toolbar class="dialog-header">
				<div style="position: absolute; inset: 0; display: flex; gap: 10px; align-items: center; padding: 10px 20px; border-bottom: 1px solid #ddd;">
					<div style="flex-grow: 1; display: flex; flex-direction: column;">
						<v-toolbar-title style="">
							<span v-html="title" />
						</v-toolbar-title>
						<p v-if="lowQualityFilesNames && lowQualityFilesNames.length > 0" style="margin-left:20px;" v-html="lowQualityFilesNamesComputed"></p>
					</div>
					<v-btn v-if="showClose" class="none" style="position: relative; right: -20px; top: -3px;"
						@click="closeDialog()"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-toolbar>

			<v-layout row wrap class="scrollable dialog-content">
				<v-col class="content d-flex overflow-x-auto" style="padding: 20px;">
					<slot name="content"/>
				</v-col>
			</v-layout>

			<v-toolbar class="dialog-footer">
				<div style="position: absolute; inset: 0; display: flex; gap: 10px; align-items: center; padding: 10px 20px; border-top: 1px solid #ddd;">
					<v-spacer />
					<slot name="buttons" />
					<v-btn class="defaultButton gradientButton" theme="dark" elevation="0" v-if="cancelLabel" data-cy="cancel"
						@click="cancel()"
					>
						{{ cancelLabel }}
					</v-btn>
					<v-btn class="redButton" theme="dark" elevation="0" v-if="deleteLabel" data-cy="delete"
						@click="remove()"
					>
						<v-icon size="24px" color="white">mdi-delete</v-icon>
						{{ deleteLabel }}
					</v-btn>
					<v-btn :class="isDelete ? 'redButton' : 'greenButton'" theme="dark" elevation="0" v-if="confirmLabel" data-cy="confirm"
						@click="confirm()"
						:disabled="!isValid"
					>
						{{ confirmLabel }}
					</v-btn>
				</div>
			</v-toolbar>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Dialog',
	props: {
		title: { type: String },
		header: { type: Boolean, default: true },
		Footer: { type: Boolean, default: true },
		confirmLabel: { type: String },
		cancelLabel: { type: String },
		deleteLabel: { type: String },
		confirmHandler: { type: Function },
		cancelHandler: { type: Function },
		closeHandler: { type: Function },
		deleteHandler: { type: Function },
		showBackdrop: { type: Boolean, default: true },
		closeOnOuterClick: { type: Boolean, default: false },
		showClose: { type: Boolean, default: true },
		height: String,
		width: { type: String, default: '1100px' },
		isBeta: { type: Boolean, default: false },
		isValid: { type: Boolean, default: true },
		fixIndexes: { type: Boolean, default: true },
		isDelete: { type: Boolean, default: false },
// TODO: why is this here? this is not a concern of Dialog, should be in component that uses Dialog
		lowQualityFilesNames: { type: Array, required: false },
		lowQualityFilesCount: { type: Number, required: false },
	},
	data() { return {
		show: false,
		backdrop: this.showBackdrop,
	}},
	watch: {
		show(n) {
			if (this.fixIndexes) this.fixZindexes(n)
		}
	},
  computed: {
    lowQualityFilesNamesComputed() {
      // if lowQualityFilesNames length is higher than 2, show the first two names and a +X (for example +3 if there are 3 more files)
      if (this.lowQualityFilesNames.length > 2) {
        return `${this.lowQualityFilesNames[0]}, ${this.lowQualityFilesNames[1]} +${this.lowQualityFilesCount - 2}`
      }
      return this.lowQualityFilesNames.join(', ')
    }
  },
  methods: {
    confirm() {
      let close = true
      if (this.confirmHandler && typeof this.confirmHandler === 'function') {
        close = this.confirmHandler();
      }
      if (close) {
        this.closeDialog();
      }
    },

    cancel() {
      if (this.cancelHandler && typeof this.cancelHandler === 'function') {
        this.cancelHandler();
      }
      this.closeDialog();
    },

    remove() {
      if (this.deleteHandler && typeof this.deleteHandler === 'function') {
        this.deleteHandler();
      }
      this.closeDialog();
    },

    closeDialog() {
      if (this.closeHandler && typeof this.closeHandler === 'function') {
        this.closeHandler();
      }
      this.show = false;
    },

    backdropClick() {
      if (this.closeOnOuterClick) {
        this.closeDialog();
      }
    },
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el?.style) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style?.['z-index-backedup'])
						el.$el.style['z-index'] = 1 //el.$el.style['z-index-backup']
				}
			}
		}
	},
	beforeUnmount () {
		if (this.fixIndexes) this.fixZindexes(false)
	},
}
</script>

<style scoped lang="scss">
.backdrop { position: fixed; height: 100vh; width: 100%; background: rgba(0, 0, 0, 0.5); z-index: 999; top: 0; left: 0; }

.dialog-div {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 1100px;
	height: min(700px, calc(100vh - 20px));
	max-width: calc(100vw - 20px);

	background-color: #ffffff;
	border-radius: 5px !important;
	box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
	z-index: 999 !important;
	overflow: hidden;

	i { margin-right: 5px; }
	.dialog-header { max-height: 72px; }

	.dialog-footer {
		.gradientButton { color: #000; max-width: 190px; }
		.greenButton { max-width: 190px; }
		.redButton { max-width: 190px; }
	}
}

.dialogDivPrint {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  height: 100%;

  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.scrollable { overflow-y: auto; overflow-x: hidden; }

.beta-top .corner {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 0px;
    height: 0px;
    border-top: 75px solid #ff7f29;
    border-left: 75px solid transparent;
    z-index: 9;
}

.beta-top .beta {
    position: absolute;
    right: 5px;
    top: 15px;
    text-transform: uppercase;
    color: white;
    font-size: 12pt;
    font-weight: bold;
    z-index: 10;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); 
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -sand-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
