<template>
	<TableWrapper>
		<v-card class="tableCard" :variant="isPrimary ? 'elevated' : 'flat'">
			<v-data-table :items-per-page="limit" hide-default-footer :multi-sort="multiSort" :loading="loading"
				:headers="headers" :items="displayedItems" :no-data-text="noDataText" :sort-by="sortBy"
				:item-value="itemValue" :expanded="expanded" :modelValue="selected" :show-select="showSelect"
				:class="`common-table ${isPrimary ? ' primary' : 'secondary'}`"
				@update:sortBy="$emit('update:sortBy', $event)" @update:expanded="$emit('update:expanded', $event)"
				@update:modelValue="toggleSelectAll" @click:row="clickRow" sort-asc-icon="mdi-menu-up"
				sort-desc-icon="mdi-menu-down">
				<template #item="{ item, isExpanded, toggleExpand }">
					<slot :name="'item'" :item="item" :headers="headers" :loading="loading">
						<tr :class="{
							'common-table-row': true,
							'expanded': isExpanded({ value: item.id }),
							'no-data': hasNoData
						}" :data-cy="`tableItem_${getItemValue(item)}`" :id="item.id" @mouseover="displayExpandButton(item.id)">
							<td v-if="!hasNoData && showSelect">
								<Teleport
									v-if="showExpand && (isExpanded({ value: item.id }) || expandButtonId === item.id)"
									to="#table-wrapper">
									<v-btn :class="`table-row-expand-btn${isPrimary ? '' : ' secondary-expand'}`"
										variant="text" data-cy="expandRowBtn" :icon="getIcon(item, isExpanded)"
										@click.stop="toggleExpand({ value: item.id })"
										:style="`top: ${getRowLocation(item.id)?.top}; left: ${getRowLocation(item.id)?.left};`" />
								</Teleport>
								<v-checkbox :modelValue="selected.indexOf(item.id) > -1" @click="selectRow(item)" :disabled="item.disableSelectRow || false" hide-details />
							</td>

							<td v-for="(header, index) in headers" :key="header.value">
								<Teleport
									v-if="!hasNoData && showExpand && !showSelect && index === 0 && (isExpanded({ value: item.id }) || expandButtonId === item.id)"
									to="#table-wrapper">
									<v-btn :class="`table-row-expand-btn${isPrimary ? '' : ' secondary-expand'}`"
										variant="text" data-cy="expandRowBtn" :icon="getIcon(item, isExpanded)"
										@click.stop="() => toggleExpand({ value: item.id })"
										:style="`top: ${getRowLocation(item.id)?.top}; left: ${getRowLocation(item.id)?.left};`" />
								</Teleport>
								<slot v-if="!hasNoData" :name="'item.' + header.value" :item="item" :header="header"
									:loading="loading">
									{{ getObjectValueByPath(item, header.value) }}
								</slot>

								<template v-else>
									{{ getObjectValueByPath(item, header.value) }}
								</template>
							</td>
						</tr>
					</slot>
				</template>

				<template #expanded-row="{ item }">
					<tr>
						<td class="common-table-expanded-row" :colspan="showSelect ? headers.length + 1 : headers.length">
							<slot name="expanded-row" :item="item" :headers="headers" :loading="loading">
								{{ item }}
							</slot>
						</td>
					</tr>
				</template>

			</v-data-table>
			<TableFooter v-if="!hideFooter" :offset="offset" :limit="limit" :results="items" :total="total"
				@update:offset="$emit('update:offset', $event)" @update:limit="$emit('update:limit', $event)" />
		</v-card>
	</TableWrapper>
</template>

<script>
import TableWrapper from './TableWrapper.vue'
import TableFooter from './TableFooter.vue'

export default {
	components: { TableWrapper, TableFooter },
	emits: ["click:row", "update:expanded", "update:selected", "update:sortBy", "update:offset", "update:limit"],
	props: {
		items: {
			type: Array,
			default() {
				return []
			}
		},
		headers: {
			type: Array,
			required: true
		},
		loading: {
			type: Boolean,
			default: false
		},
		total: {
			type: Number,
			default: 0
		},
		offset: {
			type: Number,
			default: 0
		},
		limit: {
			type: Number,
			default: 10
		},
		multiSort: {
			type: Boolean,
			default: true
		},
		itemValue: {
			type: String,
			default: 'id'
		},
		sortBy: Array,
		noDataText: String,
		showExpand: Boolean,
		showSelect: Boolean,
		expanded: Array,
		selected: Array,
		hideFooter: Boolean,
		variant: { type: String, default: 'primary' },
	},
	data() {
		return {
			expandButtonId: null,
		}
	},
	computed: {
		isPrimary() {
			return this.variant === 'primary'
		},
		displayedItems() {
			let items = this.items;
			if (this.hasNoData) {
				//If there are no items, show no data text and render ten table rows 
				//for showcase
				items = Array.apply(null, Array(10)).map((item, index) => {
					item = { id: index };

					if (index === 0) {
						const firstHeader = this.headers?.[0]?.value;
						item[firstHeader] = this.noDataText;
					}
					return item;
				});
			}
			return items;
		},
		hasNoData() {
			return this.items.length === 0;
		},
		currentPage() {
			return Math.ceil(this.offset / this.limit) ?? 1
		},
	},
	methods: {
		displayExpandButton(rowId) {
			if (this.expandButtonId === rowId) return

			this.expandButtonId = rowId
		},
		getRowLocation(rowId) {
			const row = document.getElementById(rowId)

			if (!row) return

			const { top: rowTop, left: rowLeft, height: rowHeight } = row.getBoundingClientRect()

			return {
				top: `${row.offsetTop + (rowHeight / 2) - 12}px`,
				left: `${row.offsetLeft - 12}px`,
			}
		},
		getIcon(item, isExpanded) {
			if (this.isPrimary) {
				return isExpanded({ value: item.id }) ? 'mdi-chevron-up' : 'mdi-chevron-down'
			}
			return isExpanded({ value: item.id }) ? 'mdi-menu-down' : 'mdi-menu-right'
		},
		clickRow(event, { item }) {
			const itemData = item
			this.$emit("click:row", itemData)
		},
		toggleSelectAll(allRowsSelected) {
			this.$emit('update:selected', allRowsSelected)
		},
		selectRow(rowId) {
			const newSelected = new Set(this.selected)

			if (newSelected.has(rowId)) {
				newSelected.delete(rowId)
			} else {
				newSelected.add(rowId)
			}

			this.$emit('update:selected', Array.from(newSelected.values()))
		},
		getItemValue(item) {
			return item?.[this.itemValue]
		},
		getObjectValueByPath(obj, path) {
			if (path === null || path === undefined) return undefined
			return path.split('.').reduce((prev, curr) => {
				if (curr.includes('+')) {
					curr = curr.replace('+', '.')
				}
				return prev?.[curr]
			}, obj)
		},
	},
}
</script>

<style lang="scss">
.tableCard .common-table {
	border-radius: 0;
}

.tableCard .common-table .v-data-table-footer {
	display: none;
}

.common-table.v-table>.v-table__wrapper>table>tbody>tr>td,
.common-table.v-table>.v-table__wrapper>table>thead>tr>th {
	padding: 0 32px !important;
}

.common-table.v-table>.v-table__wrapper>table>tbody>tr.no-data {
	color: #9da0a5
}

.primary.common-table.v-table>.v-table__wrapper>table>thead>tr>th {
	color: #fff !important;
	background-color: #737373 !important;
}

.secondary.common-table.v-table>.v-table__wrapper>table>thead>tr>th {
	color: black !important;
	background-color: white !important;
}


.common-table.v-table>.v-table__wrapper {
	position: relative;
}


.common-table.v-table>.v-table__wrapper>table>tbody>tr>td {
	position: relative;
}

.table-row-expand-btn {
	position: absolute;

	&.v-btn--icon {
		position: absolute;
		left: -12px;
		top: 16px;
		height: 24px !important;
		width: 24px !important;
		padding: 0 !important;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
		border: solid 1px rgba(0, 0, 0, 0.2);
		background-color: #fff;
	}
}

.secondary-expand {
	&.v-btn--icon {
		top: 12px;
		left: 0;
		border: none;
		color: grey;
	}
}

.common-table.v-table>.v-table__wrapper>table>tbody>tr>td.common-table-expanded-row {
	cursor: default;
	padding: 32px !important;
	background-color: rgba(244, 244, 244, 0.5);
}

.common-table.v-table>.v-table__wrapper>table>tbody>tr>td.common-table-expanded-row * {
	opacity: 1;
}

.v-data-table-header__sort-badge {
	display: none;
}
</style>
