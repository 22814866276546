<template>
	<v-container>
		<loading v-model:active="loading" :is-full-page="true" color="#4caf50" />
   		<v-row no-gutters>
			<p class="helpText" v-html="$t('text.changeHomebaseHelp')"/>
        <v-radio-group
            v-model="selectedClient"
        >
          <v-radio
              v-for="client of clients"
              :key="client.sys.id"
              :label="client.fields.title.de"
              :value="client"
          >
          </v-radio>
        </v-radio-group>
		</v-row>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'

export default {
	name: 'HomebaseSelector',
  emits: ['client-selected'],
	components: {Loading},
	mixins: [ Common ],
	data() {
		return {
		loading: false,
		clients: [],
		selectedClient: null
	}},
	methods: {
		async loadClients() {
			if (this.userIsHomeOperator) {
				this.clients = await this.getMarketplaceClientsForClient(this.$store.state.selectedClient)
			}
		},
		async getMarketplaceClientsForClient(originalClient) {
			let clients = []
			this.loading = true
			let marketplaces = await this.$httpGet('/marketplace?clientSysId=' + originalClient.sys.id)
			this.loading = false

			for (let client of marketplaces?.[0]?.fields?.externalClients?.de ?? []) {
				if (client.sys.id !== originalClient.sys.id) {
					clients.push(client)
				}
			}
			clients.sort((a, b) => { return a?.fields?.title?.[this.selectedLocale].localeCompare(b?.fields?.title?.[this.selectedLocale], {ignorePunctuation: true}) })
	
			return clients
		},
	},
	mounted() {
		this.loadClients()
	},
  watch: {
    selectedClient(selectedClient) {
      this.$emit('client-selected', selectedClient)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-radio::v-deep {
  .v-label {
    color: #000000 !important;
  }
}
</style>