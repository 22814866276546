<template>
	<div class="MediasField" :class="{ error }">
		<ImagesControl v-model="model" v-if="model" />
	</div>
</template>

<script lang="ts">
// ATT: the imported class is not a real field, but a legacy control
import ImagesControl from '../common/ImagesField.vue'
import { field } from './FieldMixin.js'

// widgetId = mediaGallery

export default {
	components: { ImagesControl },
	mixins: [ field ],
	inject: [ 'entryCache' ],
	props: {
		modelValue: [ Array ],
		type: String,
		field: Object,
		disabled: Boolean,
		dataCy: String,
	},
	data: () => ({
		autoModel: false,
	}),
	watch: {
		async modelValue(n) {
			const valueIds = this.modelValue?.map?.(m => m.sys.id)?.join?.(',')
			const modelIds = n?.map?.(m => m.sys.id)?.join?.(',')
			if (valueIds == modelIds) return

			this.model = await this.valueToModel(n)
			this.validate()
		},
		model: {
			deep: true,
			async handler(n) {
				const valueIds = this.modelValue?.map?.(m => m.sys.id)?.join?.(',')
				const modelIds = n?.map?.(m => m.sys.id)?.join?.(',')
				if (valueIds == modelIds) return

				this.$emit('update:modelValue', await this.modelToValue(n))
				this.$nextTick(() => { this.validate() })
			},
		},
	},
	methods: {
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
			])
		},
		// convert to the links that the API needs
		async valueToModel(value) {
			// TODO: the entry cache also needs to be filled in the Package Designer after we load the package
			const r = []
			for (const link of value ?? []) {
				const media = await this.entryCache.get(link.sys.id)
				if (!media) continue
				r.push(media)
			}
			return r
		},
		// convert to the resolved model that ImagesControl expects
		async modelToValue(model) {
			const r = []
			for (const media of model ?? []) {
				// TODO: are these really Media entries or are they assets?
				r.push({ sys: { type: 'Link', linkType: 'Entry', id: media.sys.id } })
			}
			return r
		},
	},
	async mounted() {
		// TODO: load the media details for the given medias, so we can show them
		// TODO: maintain a separate model for the ImagesControl
		this.model = await this.valueToModel(this.modelValue)
		this.validate()
	},
}
</script>
