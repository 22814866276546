<template>
	<v-row align="center" justify="center" class="StepBar">
		<ul v-if="steps?.length" class="steps">
			<li class="step" v-for="(step, index) of steps" :key="step.id"
				@click="show(step)"
				:data-cy="step.datacy"
				:class="{
					complete: step.status == 'complete',
					error: step.status == 'error',
					current: step.id == currentStepId,
				}"
			>
				<span class="divider" v-if="showDividers === true && index < steps.length-1"></span>
				<span class="icon">
					<v-icon v-if="step.icon" size="14" color="white">{{ step.icon }}</v-icon>
				</span>
				<span class="label">{{ $t('text.' + step.id) }}</span>
			</li>
		</ul>
	</v-row>
</template>

<script>
import eventBus from '@/utils/eventBus.js'

export default {
	props: {
		showDividers: { type: Boolean, default: true },
	},
	data: () => ({
		steps: [],
		currentStepId: null,
		// TODO: on change of modelValue, call show?
		modelValue: null,
	}),
	methods: {
		register(step) {
			// on double-registration we clear the steps - this is probably a hot-reload
			if (this.steps.find(s => s.id == step.id)) this.steps.splice(0, this.steps.length)

			this.steps.push(step)

			// show the first step
			if (this.steps.length == 1) this.show(step)
		},
		show(step) {
			if (this.currentStepId == step.id) return
			this.$emit('update:modelValue', step.id)
			this.$emit('step', step.id)
			eventBus.$emit('step-show', step.id)
			this.currentStepId = step.id
		},
	},
	mounted() {
		eventBus.$on('step-register', this.register)
		eventBus.$emit('step-cfr', this)
	},
	unmounted() {
		eventBus.$off('step-register', this.register)
	},
}
</script>

<style scoped>
.StepBar { height: 60px; width: auto; padding-left: 320px; border-bottom: 1px solid #ddd; z-index: 99; }
@media screen and (max-width: 950px) { .StepBar { padding-left: 0; } }

ul { text-align: justify; position: relative; overflow: hidden; margin: 0; padding: 0; display: flex; gap: 10px; }
li { position: relative; width: 20%; text-align: center; padding-bottom: 20px; min-width: 120px; cursor: default; display: flex; flex-direction: column; gap: 5px;  }

.step { --col: #737373; color: var(--col); flex: 1 1 0; margin-top: 4px; position: relative; cursor: pointer; }
.step .icon { background: var(--col); width: 20px; height: 20px; border-radius: 10px; display: flex; justify-content: center; align-items: center; margin: auto; }
.step .label { font-size: 12px; white-space: nowrap; line-height: -15px; position: absolute; left: 0; right: 0; bottom: 0; }
.divider { border-top: 3px solid var(--col); position: absolute; top: 9px; width: 100%; left: 50% }

.current { font-weight: bold; }
.current .icon { outline: 2px solid var(--col); outline-offset: 1px; }
.complete { --col: #7BC043; }
.error { --col: #ff0022; }
.v-icon { position: relative; }
</style>